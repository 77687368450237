import { constants } from '../../constants'

const initialState = {
  ideaList: [],
  ideaType: null
}
const searchState = {
  keyword: '',

  rhymesData: null,
  dictionaryData: null,
  thesaurusData: null
}

const lookupState = {
  boolean: false
}

const ideaReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.IDEA_LISTS: {
      return {
        ideaList: action.payload.data,
        ideaType: action.payload.ideaType
      }
    }

    default:
      return state
  }
}

const lookUpReducer = (state = lookupState, action) => {
  switch (action.type) {
    case constants.LOOK_UP_TOGGLE: {
      return {
        boolean: action.payload.boolean
      }
    }

    default:
      return state
  }
}
const ideaSearchDataReducer = (state = searchState, action) => {
  switch (action.type) {
    case constants.IDEA_SEARCH_LISTS: {
      return {
        keyword: action.payload.keyword,
        rhymesData: action.payload.data.rhymesData,
        dictionaryData: action.payload.data.dictionaryData,
        thesaurusData: action.payload.data.thesaurusData
      }
    }

    default:
      return state
  }
}

export { ideaReducer, ideaSearchDataReducer, lookUpReducer }
