import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from './components/Toast/Toast'
import { loaderAction } from './services/actions'

const options = {
  error: '',
  success: ''
}
const Failed = props => {
  const { loader } = useSelector(state => state.loader)
  const params = new URLSearchParams(window.location.search)
  const session_id = params.get('session_id')
  const type = params.get('type')
  const [msg, setMsg] = useState(options)


  const dispatch = useDispatch()

    useEffect(() => { 
      dispatch(loaderAction(true))

        toast.error('Subscription has been failed')
        setMsg({success:'',error:'Subscription has been failed'})
        setTimeout(() => {
          window?.close()
          if (window?.opener?.progressWindow) {
            window?.opener?.progressWindow.close()
          }
          window?.close()
        }, 2000)
     
      dispatch(loaderAction(false))
    }
  , [])

  return (
    <div className="vh-100">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <p className={` ${msg?.error ? 'text-danger' : 'text-success'}`}>
          {msg?.error || msg?.success || ''}
        </p>
        <div>
          <br />
          {!loader  && (
           
              <p>Please close this window.</p>
           
          )}

          {!loader && type && <Button variant="secondary">Press Back</Button>}
        </div>
      </div>
    </div>
  )
}

export default Failed
