import { ACTION_TYPES } from './customReducerActionTypes'

const initialState = {
  loader: false,
  apiData: []
}

// loader or api data
const postReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_START:
      return {
        loading: true,
        apiData: []
      }

    case ACTION_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        apiData: action.payload.data
      }
    case ACTION_TYPES.FETCH_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export { postReducer, initialState }
