import { chordConstants } from "../../constants"

const initialState = {
  firstChord: ""
}

const secondChordState = {
 secondChord:""
}

const firstChordReducer = (state = initialState, action) => {
  switch (action.type) {
    case chordConstants.CHORD_ONE: {
      return {
        firstChord: action.payload
      }
    }
   
    default:
      return state
  }
}
const secondChordReducer = (state = secondChordState, action) => {
  switch (action.type) {
    case chordConstants.CHORD_TWO: {
      return {
        secondChord: action.payload
      }
    }
  
    default:
      return state
  }
}



export {firstChordReducer, secondChordReducer}