import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Api } from '../../helper/ApiList'
import { getMethod } from '../../helper/ApiMethods'
import { loaderAction } from '../../services/actions/loader.action'
import {
  allSongAction,
  recentSongAction
} from '../../services/actions/song.action'
import { addFolderToggleAction } from '../../services/actions/toggle.action'
import FolderModal from '../Modal/FolderModal'
import { FolderComponents } from './common/Song'
import { constants } from '../../services/constants'
import { AddIcon } from '../../helper/icons'
import { hideTabs } from '../../utils/commonOptions'

const SongFolders = () => {
  const { folderId } = useSelector(state => state.songs)
  const { token } = useSelector(state => state.auth)
  const { disabled } = useSelector(state => state.loader)
  const { type } = useSelector(state => state.songs)

  const dispatch = useDispatch()
  const handleRefresh = async () => {
    dispatch(loaderAction(true))
    const allSongs = await getMethod(token, Api.getSongs)
    console.log("~~~~~~ song api call");

    dispatch({ type: constants.SINGLE_SONG, payload: { song: null } })

    const recentSongs = await getMethod(token, Api.recentSongs)
    dispatch(recentSongAction(recentSongs?.data))
    dispatch(allSongAction(allSongs?.data))

    dispatch(loaderAction(false))
  }
  return (
    <div>
      <FolderModal />
      <div className="mt-2">
        <div className={`${disabled ? 'disable' : ''} Folders`}>
          <AddIcon
            className={`pr-2 add-icon ${disabled ? 'disable' : ''}`}
            clickFun={() => !disabled && dispatch(addFolderToggleAction('Add'))}
          />

          <span>Folders</span>
        </div>
      </div>
      {/* allsong-new all_folder_active */}
      <div className="song">
        <div
          // className={`${
          //   folderId
          //     ? 'recent-song-inactive'
          //     : hideTabs.includes(type)
          //     ? 'recent-song-inactive'
          //     : 'allsong-new all_folder_active'
          // } `}
          className='recent-song-inactive'
        >
          <div className="" onClick={() => handleRefresh()}>
            <img
              className={`pr-2 ${disabled ? 'disable' : ''} all-song-img`}
              src={
                hideTabs.includes(type)
                  ? 'assets/images/folder_open_line.svg'
                  : folderId
                  ? 'assets/images/folder_open_line.svg'
                  : 'assets/images/all-song-icon-new.svg'
              }
              alt=""
            />
            <span>{'All Songs'}</span>
          </div>
        </div>
      </div>

      {/* Folder Listing component */}
      <div className="folder_wrapper">
        <FolderComponents img={'assets/images/folder_line.svg'} />
      </div>

      {/* <div className="songFolders">
        <div className="innerSongFolders">
          <FolderComponents img={'assets/images/folder_line.svg'} />
        </div>
      </div> */}
    </div>
  )
}

export default SongFolders
