import { cartTabConstants } from '../../constants'

const initialState = {
  favTags: []
}

const getFavoriteTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartTabConstants.ALL_FAV_TAGS: {
      return {
        ...state,
        favTags: action?.payload?.data
      }
    }

    default:
      return state
  }
}

export { getFavoriteTagsReducer }
