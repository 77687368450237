import { playerConstants } from '../../constants'

const initialState = {
  currentSongs: [],
  currentIndex: 0,
  isActive: false,
  isPlaying: false,
  activeSong: {},
  genreListId: '',
  playerVolume: 0.3
}

const allOnlineSongState = {
  liveSongList: []
}
const songBlockRecordingState = {
  isBlockRecording: false,
  activeId: null
}

const playPauseReducer = (state = initialState, action) => {
  switch (action.type) {
    case playerConstants.PLAY_PAUSE: {
      return {
        ...state,
        isPlaying: action.payload.isPlaying,
        activeSong: action.payload.activeSong
      }
    }
    case playerConstants.PLAYER_VOLUME: {
      return {
        ...state,
        playerVolume: action.payload.playerVolume
      }
    }
    default:
      return state
  }
}
const blockRecordingReducer = (state = songBlockRecordingState, action) => {
  switch (action.type) {
    case playerConstants.SONG_BLOCK_RECORDING_START: {
      return {
        ...state,
        isBlockRecording: action.payload.isBlockRecording,
        activeId: action.payload.activeId
      }
    }
    default:
      return state
  }
}
// GET ONLINE RECORDINGS

const getRecordingReducer = (state = allOnlineSongState, action) => {
  switch (action.type) {
    case playerConstants.SINGLE_SONG_RECORDING_DATA: {
      return {
        ...state,
        liveSongList: action.payload
      }
    }
    default:
      return state
  }
}

export { playPauseReducer, getRecordingReducer, blockRecordingReducer }
