import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  backTrackFooterAction,
  backTrackPlayPauseAction,
  backTrackSelectAction
} from '../../services/actions/mediaPlayerActions/backTrack.action'
import { DrumIcon } from '../../helper/icons'
import { useAlert } from 'react-alert'
import { playerConstants } from '../../services/constants'
import { toast } from '../Toast/Toast'

const ITEM_HEIGHT = 48

export default function BackTrackList({ boxClass, isFooter }) {
  const { allBackTracks } = useSelector(state => state.allBackTracks)
  const { isBlockRecording } = useSelector(state => state.isBlockRecording)
  const {
    backTrackActiveSong,
    isBlockBackTrack,
    isFooterBackTrack
  } = useSelector(state => state.backTrackState)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [selectedTrack, setSelectedTrack] = React.useState(null)
  const [blockSelectedTrack, setBlockSelectedTrack] = React.useState(
    ' + backing track'
  )

  const alert = useAlert()
  const dispatch = useDispatch()
  const handleClick = event => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClickBlock = event => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePlayPause = track => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }
    if (boxClass) {
      setBlockSelectedTrack(track?.title)
      dispatch({
        type: playerConstants.SONG_BLOCK_SELECTED_BACKTRACK,
        payload: { boolean: true, isFooter: false }
      })
      handleResetTrack()
      setTimeout(() => {
        handleSongBlockTrack(track)
      }, 200)
    } else {
      dispatch({
        type: playerConstants.SONG_BLOCK_SELECTED_BACKTRACK,
        payload: { boolean: false, isFooter: true }
      })
      handleResetTrack()
      setTimeout(() => {
        handleSongBlockTrack(track)
      }, 200)
    }
  }

  const handleSongBlockTrack = track => {
    if (!track) {
      handleResetTrack()
    } else {
      dispatch(backTrackPlayPauseAction(false, track))
      dispatch(backTrackFooterAction(true, track))

      // dispatch(backTrackSelectAction(true, track)) // if track select is true then back track is automatically play
    }
  }

  const handleResetTrack = () => {
    dispatch(backTrackPlayPauseAction(false, null))
    dispatch(backTrackSelectAction(false, null))
  }

  React.useEffect(() => {
    if (isBlockBackTrack) {
      setSelectedTrack(' + backing track')
      return false
    }

    // if(isFooterBackTrack){

    // }
    setBlockSelectedTrack(' + backing track')
    if (backTrackActiveSong?.title) {
      isFooterBackTrack && setSelectedTrack(backTrackActiveSong?.title)
    } else {
      setSelectedTrack(' + backing track')
    }
  }, [backTrackActiveSong, isBlockBackTrack])

  return (
    <div>
      {boxClass ? (
        <>
          <div className="backTrack_select">
            <img
              src="assets/images/drum-black.svg"
              alt=""
              className="pause-block"
            />
            <span
              className="backing-track-sc"
              aria-label="more"
              id="long-button2"
              aria-controls={boxClass && open ? 'long-menu2' : undefined}
              aria-expanded={boxClass && open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickBlock}
            >
              {blockSelectedTrack}
            </span>
          </div>
          <Menu
            id="long-menu2"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '30ch'
              }
            }}
          >
            <MenuItem
              onClick={() => {
                handlePlayPause()
                handleClose()
              }}
            >
              none
            </MenuItem>
            {allBackTracks?.map(option => (
              <MenuItem
                key={option?._id}
                onClick={() => {
                  handlePlayPause(option)
                  handleClose()
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <>
          <div className="backTrack_select_footer">
            <span>
              {/* <img className="" src="assets/images/drum.svg" alt="" width={22} /> */}
              <DrumIcon
                className={`${
                  selectedTrack === ' + backing track' ||
                  selectedTrack === 'none'
                    ? 'footer-drum-icon active'
                    : 'footer-drum-icon active'
                }`}
              />
            </span>
            <span
              className={`${
                selectedTrack === ' + backing track' || selectedTrack === 'none'
                  ? 'backing-text-list'
                  : 'backing-text-list'
              }`}
              aria-label="more"
              id="long-button"
              aria-controls={!boxClass && open ? 'long-menu' : undefined}
              aria-expanded={!boxClass && open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {selectedTrack}
            </span>
          </div>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '30ch'
              }
            }}
          >
            <MenuItem
              onClick={() => {
                handlePlayPause()
                handleClose()
              }}
            >
              none
            </MenuItem>
            {allBackTracks?.map(option => (
              <MenuItem
                key={option?._id}
                onClick={() => {
                  handlePlayPause(option)
                  handleClose()
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  )
}
