import { playerConstants } from '../../constants'

const playPauseAction = (Boolean, activeSong) => async dispatch => {
  return dispatch({
    type: playerConstants.PLAY_PAUSE,
    payload: { isPlaying: Boolean, activeSong }
  })
}
const blockRecordingAction = (Boolean, id) => async dispatch => {
  return dispatch({
    type: playerConstants.SONG_BLOCK_RECORDING_START,
    payload: { isBlockRecording: Boolean, activeId: id }
  })
}

// GET ONLINE SONG RECORDINGS
const getRecordingAction = data => async dispatch => {
  return dispatch({
    type: playerConstants.SINGLE_SONG_RECORDING_DATA,
    payload: data ? data?.recordingList : []
  })
}

export { playPauseAction, getRecordingAction, blockRecordingAction }
