import axios from 'axios'

const getRhymes = async word => {
  if (!word) return
  try {
    const { data } = await axios.get(
      `https://www.datacloudapi.com/cgi-bin/rhymebrain?function=getRhymes&word=${word}&maxResult=50`
    )

    return data
  } catch (error) {
    return { error: error }
  }
}

const getThesaurus = async word => {
  if (!word) return
  try {
    let url = `${process.env.REACT_APP_THESAURUS}${word}/relatedWords?relationshipTypes=synonym&api_key=${process.env.REACT_APP_DICTIONARY_API_KEY}&limitPerRelationshipType=5&useCanonical=false`

    return await axios.get(url)
  } catch (error) {
    return error
  }
}
const getDictionary = async word => {
  if (!word) return
  try {
    let url = `${process.env.REACT_APP_DICTIONARY}${word}/definitions?api_key=${process.env.REACT_APP_DICTIONARY_API_KEY}&includeRelated=false&includeTags=false&limit=5&useCanonical=false`

    return await axios.get(url)
  } catch (error) {
    return error
  }
}

export { getRhymes, getDictionary, getThesaurus }
