import React from 'react'

function useQuery() {
  return React.useMemo(() => {
    let url = new URL(window.location.href)
    
    return new URLSearchParams(url.search)
  }, [])
}

export { useQuery }
