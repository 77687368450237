import { toast } from '../components/Toast/Toast'
import { Api } from '../helper/ApiList'
import { postMethod } from '../helper/ApiMethods'
import { assistantFooterToggleAction } from '../services/actions/toggle.action'
import { LeftRightPaneFolderTypes, aiAssistant } from './commonOptions'

const aiOnChange = (e, values, setValues) => {
  const { name, value, checked } = e.target

  if (value === 'on') {
    setValues({ ...values, [name]: checked })
  } else {
    setValues({ ...values, [name]: value })
  }
}

const aiHandleClear = (setValues, dispatch, setAiContent) => {
  setValues(aiAssistant)
  dispatch(assistantFooterToggleAction(true, null))
  setAiContent('')
}

const aiHandleGenerate = async (
  type,
  values,
  setBtnLoader,
  dispatch,
  token,
  setCounts,
  setAiContent
) => {
  const searchParams = new URLSearchParams(window.location.search)
  // const newIdeas = searchParams.get('new_ideas')
  const newIdeas = sessionStorage.getItem('new_ideas')

  let body = null
  try {
    if (type === LeftRightPaneFolderTypes.NOTES || newIdeas) {
      if (!values?.theme || !values?.genre) {
        toast.info('Please fill required fields.')
        return
      }
    } else {
      if (!values?.theme || !values?.style || !values?.genre) {
        toast.info('Please fill required fields.')
        return
      }
    }

    if (type === LeftRightPaneFolderTypes.NOTES || newIdeas) {
      body = {
        genre: values.genre,
        theme: values.theme,
        typeOfIdeas: values?.typeOfIdeas || 'Song Titles',
        lang: values.lang,
        isTitle: true
      }
    } else {
      body = values
    }
    setBtnLoader(true)
    let url = Api.generate
    const { data, error } = await postMethod(token, url, body)
    dispatch(assistantFooterToggleAction(true, data))

    if (error) {
      toast.error(error)
      setBtnLoader(false)
      return
    }
    setCounts(data)

    setAiContent(data?.content)
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message)
  }
  setBtnLoader(false)
}

export { aiOnChange, aiHandleClear, aiHandleGenerate }
