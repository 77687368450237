import React from 'react'
import { RhymesSearchInput } from '../../commonComponent/tabs/TabSearchInput'

const Rhymes = () => {
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12 text-center">
          <div className="Generator-content ">
            {/* <p className="mb-0">Rhyme Types</p>
            <span className="Generator-tab active">Perfect</span>
            <span>|</span>
            <span className="Generator-tab">Slant</span>
            <span>|</span>
            <span className="Generator-tab">End</span> */}
            <div className="search-bar-right-bar">
              <RhymesSearchInput
                type="rhymes"
                initial="Words that rhyme with amazing"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12"></div>
      </div>
    </>
  )
}

export default Rhymes
