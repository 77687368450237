import { constants } from '../constants'

const initialState = {
  singleSong: null,
  singleNotebook: null,
  songBlocks: [],
  reOrderSongBlocks: []
}

const recentState = {
  recentSongs: []
}
const songState = {
  songs: [],
  title: 'All Songs',
  folderId: null,
  type: ''
}

const singleSongReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SINGLE_SONG_DATA: {
      return {
        ...state,
        singleSong: action.payload.song,
        songBlocks: action.payload.songBlocks,
        reOrderSongBlocks: action.payload.songBlocksReOrder
      }
    }
    case constants.SINGLE_SONG: {
      return {
        ...state,
        singleSong: action.payload.song,
        singleNotebook: action.payload.singleNotebook
      }
    }

    case constants.RESET_SINGLE_SONG_DATA: {
      return initialState
    }
    default:
      return state
  }
}
const recentSongReducer = (state = recentState, action) => {
  switch (action.type) {
    case constants.RECENT_SONGS: {
      return {
        recentSongs: action.payload.song
      }
    }
    default:
      return state
  }
}
const allSongReducer = (state = songState, action) => {
  switch (action.type) {
    case constants.ALL_SONGS: {
      return {
        songs: action.payload.song,
        title: action.payload.title,
        folderId: action.payload.folderId,
        type: action.payload.type
      }
    }
    default:
      return state
  }
}

export { singleSongReducer, allSongReducer, recentSongReducer }
