const ACTION_TYPES = {
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR'
}

const RECENT_ACTION_TYPES = {
  RECENT_START: 'RECENT_START',
  RECENT_SUCCESS: 'RECENT_SUCCESS'
}

export { ACTION_TYPES, RECENT_ACTION_TYPES }
