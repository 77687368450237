import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import NotebookModal from '../Modal/NotebookModal'
import SongModal from '../Modal/SongModal'
import { loaderAction } from '../../services/actions/loader.action'
import { getMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { allSongAction } from '../../services/actions/song.action'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { constants } from '../../services/constants'
import { AddIcon } from '../../helper/icons'
import { AIGeneratedIcon, NotebookIcon } from '../../icons'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  assistantFooterToggleAction,
  hideRightSidebarToggleAction
} from '../../services/actions/toggle.action'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))

const UseAlert = ({
  isOpen,
  setIsOpen,
  confirmDelete,
  message,
  fullMessage,
  cancelBtnText,
  confirmBtnText,
  loading
}) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    setIsOpen(false)
    dispatch(loaderAction(false))
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="custom_modal">
          {fullMessage ||
            `Are you sure you want to delete this ${message ? message : ''}?`}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} className="cancelBtn">
            {cancelBtnText || 'Cancel'}
          </Button>
          {/* <Button onClick={confirmDelete} className="submitBtn confirm">
           
          </Button> */}
          <LoadingButton
            loading={loading}
            className={loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={confirmDelete}
          >
            {confirmBtnText || 'Confirm'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const LeftASideAddComponent = ({ title, handleClick }) => {
  const { disabled } = useSelector(state => state.loader)
  const { songToggle } = useSelector(state => state.songToggle)

  return (
    <>
      {songToggle && <SongModal />}

      <div className="logo">
        <img className="logoImg" src="assets/images/logo-new.svg" alt="" />
      </div>
      <div className="left-sidebar-new">
        <ul>
          <li>
            <AddIcon
              className={`pr-2 add-icon ${disabled ? 'disable' : ''}`}
              clickFun={() => !disabled && handleClick()}
            />

            <span>{title}</span>
          </li>
        </ul>
      </div>
    </>
  )
}

const NotebookLeftSidebar = ({ title, handleClick, left }) => {
  const dispatch = useDispatch()
  const { disabled } = useSelector(state => state.loader)
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)
  const { notebookToggle } = useSelector(state => state.notebook)

  const getAllNotebooks = async isNew => {
    !isNew && dispatch(loaderAction(true))
    const { data } = await getMethod(token, Api.getNotebooks)

    dispatch(
      allSongAction(data, 'Notebook', '', LeftRightPaneFolderTypes.NOTES)
    )
    !isNew && dispatch({ type: constants.RESET_SINGLE_SONG_DATA })

    dispatch(loaderAction(false))
  }

  return (
    <>
      {notebookToggle && <NotebookModal getAllNotebooks={getAllNotebooks} />}
      <div className="line-white"></div>
      <div className="mt-2">
        <div className={`${disabled ? 'disable' : ''} Add-note`}>
          <AddIcon
            className={`pr-2 add-icon ${disabled ? 'disable' : ''}`}
            clickFun={() => !disabled && handleClick()}
          />
          <span>{title}</span>
        </div>
      </div>

      <div className="mt-1">
        <div
          className={`${disabled ? 'disable' : ''} Add-note notebooks ${
            type === LeftRightPaneFolderTypes.NOTES && 'active'
          }`}
          onClick={() => getAllNotebooks()}
        >
          <NotebookIcon className={`pr-2 add-icon `} />
          <span>Notebook</span>
        </div>
      </div>
      <div className="line-white"></div>
    </>
  )
}

const AIAssistLeftSidebar = () => {
  const dispatch = useDispatch()
  const { disabled } = useSelector(state => state.loader)
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)

  const handleAIFolderLyrics = async () => {
    if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) return

    dispatch(loaderAction(true))
    const { data } = await getMethod(token, Api.getAllAILyricsWithOutFilter)

    dispatch(
      allSongAction(
        data?.lyrics,
        'AI Generated',
        '',
        LeftRightPaneFolderTypes.AI_ASSISTANT
      )
    )
    dispatch({ type: constants.RESET_SINGLE_SONG_DATA })

    dispatch(loaderAction(false))
  }

  const handleFolderLyrics = async () => {
    dispatch(loaderAction(true))
    dispatch(assistantFooterToggleAction(false, null))
    dispatch({ type: constants.RESET_SINGLE_SONG_DATA })
    await handleAIFolderLyrics()
    dispatch(loaderAction(false))
  }

  return (
    <div>
      <div
        className="mt-2"
        onClick={() => {
          sessionStorage.setItem('new_ideas', '')
          handleFolderLyrics()
        }}
      >
        <div className={`${disabled ? 'disable' : ''} ai-assist cursor`}>
          <AddIcon className={`pr-2 add-icon ${disabled ? 'disable' : ''}`} />
          <span>AI Lyrics</span>
        </div>
      </div>

      <div
        className="mt-2"
        onClick={async e => {
          sessionStorage.setItem('new_ideas', true)
          await handleFolderLyrics()
          setTimeout(() => {
            sessionStorage.setItem('new_ideas', true)
          }, 200)
        }}
      >
        <div className={`${disabled ? 'disable' : ''} ai-assist cursor`}>
          <AddIcon className={`pr-2 add-icon ${disabled ? 'disable' : ''}`} />
          <span>AI Ideas</span>
        </div>
      </div>

      <div
        className={`${disabled ? 'disable' : ''} AI-generated cursor ${
          type === LeftRightPaneFolderTypes.AI_ASSISTANT ? 'active' : ''
        }`}
        onClick={() => {
          sessionStorage.setItem('new_ideas', '')
          handleFolderLyrics()
        }}
      >
        <AIGeneratedIcon />

        <span>AI Generated</span>
      </div>
      <div className="line-white"></div>
    </div>
  )
}

export {
  UseAlert,
  BootstrapInput,
  LeftASideAddComponent,
  AIAssistLeftSidebar,
  NotebookLeftSidebar
}
