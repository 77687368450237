import { constants } from '../constants'

const initialState = {
  loader: false,
  disabled: false, //we are disable edit creation and editable mode

  countDown: false
}

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOADER_START:
      return {
        ...state,
        loader: true
      }
    case constants.LOADER_STOP:
      return {
        ...state,
        loader: false
      }
    case constants.DISABLE_ACCESS:
      return {
        ...state,
        disabled: action.payload.disabled
      }
    case constants.COUNT_DOWN:
      return {
        ...state,
        countDown: action.payload.countDown
      }

    default:
      return state
  }
}
