import { constants } from '../constants';

const initialState = {
  token: null,
  user: null,
  isAuthenticated: false,
  plan: "free",
};

export const authReducer = (state = initialState, action) => {


  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
        isAuthenticated: true,
        plan: action.payload.plan,
        disabled: action.payload.disabled
      };
    case constants.REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token
      };
   

    default:
      return state;
  }
};
