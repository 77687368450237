import React from 'react'
import EditorSongHeading from './EditorSongHeading'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { getMethod } from '../../helper/ApiMethods'
import { loaderAction } from '../../services/actions/loader.action'
import { Api } from '../../helper/ApiList'
import { useEffect } from 'react'
import { useState } from 'react'
import { NotebookSelectMenu } from '../commonComponent/SelectMenu'
// import NotebookModal from '../Modal/NotebookModal'
import { constants } from '../../services/constants'
import NotebookModal from '../Modal/NotebookModal'

const NotebookHeader = () => {
  const { type } = useSelector(state => state.songs)
  const { token } = useSelector(state => state.auth)
  const [notebookList, setNotebookList] = useState([])
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()

  const hideNote = useMemo(() => {
    return type !== LeftRightPaneFolderTypes.NOTES
  }, [type])

  useEffect(() => {
    const handleFolderLyrics = async () => {
      // dispatch(loaderAction(true))
      try {
        const { data } = await getMethod(token, Api.getNotebooks)

        setNotebookList(data)
      } catch (error) {}
      dispatch(loaderAction(false))
    }
    if (type !== LeftRightPaneFolderTypes.NOTES) {
      return handleFolderLyrics()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useMemo(() => {
    if (singleSong?.isNotebook) {
      let temp = [...notebookList]
      const index = temp?.findIndex(item => item?._id === singleNotebook?._id)
      temp[index]['notebookContent'] = singleNotebook?.notebookContent
      setNotebookList(temp)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSong, singleNotebook])

  const handleSubmit = data => {
    setNotebookList(pre => [...pre, data])
    data['isNotebook'] = true
    dispatch({
      type: constants.SINGLE_SONG,
      payload: { song: singleSong, singleNotebook: data }
    })
    setShowModal(false)
  }

  return (
    <>
      {type !== LeftRightPaneFolderTypes.NOTES ? (
        <div className="row">
          <div className='col-6'>
          <div className="French-Dreams-text-new">
              <h5 className="m-0">
                {singleSong?.songHeading ||
                  singleNotebook?.notebookHeading ||
                  ''}
              </h5>
            </div>
          </div>
          <div className='col-6'>
          <div className="balance-reobon ai_btn">
              <div className="new-note pe-3">
                <p>
                  {/* New Note */}
                  <button
                    className="add-block-btn-new"
                    onClick={() => setShowModal(true)}
                  >
                    <img src="assets/images/add-song-icon-new.svg" alt="" />
                    New Note
                  </button>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-12 ai-header-wrapper note">
            
          
          </div> */}
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="tab-heading-text">
              <EditorSongHeading />
            </div>
          </div>
        </div>
      )}
      {hideNote && (
        <div className="row">
          <div className="col-7">
            <div className="note-p-tag">
              <p>Access previously saved notes and ideas</p>
            </div>
          </div>
          <div className="col-5">
            <div className="select-menu-note pe-3">
              <p>
                <NotebookSelectMenu notebookList={notebookList} />
              </p>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <NotebookModal
          showModal={showModal}
          handleSubmitNote={handleSubmit}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}

export default NotebookHeader
