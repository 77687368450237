import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'
import { addFolderToggleAction } from '../../services/actions/toggle.action'
import LoadingButton from '@mui/lab/LoadingButton'
import { postMethod, putMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { useAlert } from 'react-alert'
import { allFolderAction } from '../../services/actions/folder.action'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import CloseIcon from '@mui/icons-material/Close'
import { ideaAction } from '../../services/actions/idea/idea.action'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { withStyles } from '@material-ui/core/styles'
import { camalize } from '../../utils/commonFunctions'

import {
  singleSongAction,
  songAction
} from '../../services/actions/song.action'
import { toast } from '../Toast/Toast'

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
})

export default function IdaFavTagModal({ openModal, setOpenModal }) {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { token } = useSelector(state => state.auth)
  const { singleSong } = useSelector(state => state.singleSong)

  const alert = useAlert()
  const dispatch = useDispatch()

  const [tagValue, setTagValue] = React.useState('')
  const [tagName, setTagName] = React.useState('words')

  const handleSubmit = async () => {
    if (!tagValue || !tagName) {
      toast.error('All fields are required')
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    const body = {
      title: camalize(tagName),
      label: tagValue,
      songId: singleSong?._id
    }
    const { data, error } = await postMethod(token, Api.addFavorites, body)

    if (!error) {
      console.log(data, 'Data saved')
      dispatch(singleSongAction(data))
    }

    if (!error) {
      //   toast.success(data?.message)
      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data }
      })
      closeModal()
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  const closeModal = () => {
    setTagValue('')
    setTagName('')
    setOpenModal(false)
  }

  let tagList = ['words', 'phrases']

  return (
    <div className="folder_modal">
      <Dialog open={openModal}>
        <DialogTitle className="custom_modal">Add favorite tag</DialogTitle>

        {/* <CloseIcon className="icon_close_btn" onClick={() => closeModal()} /> */}

        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => closeModal()}
        />
        <DialogContent className="dialog_content">
          <FormControl fullWidth className="mt-2">
            <InputLabel shrink={true} htmlFor="hey" className="select_menu">
              Select type
            </InputLabel>
            <Select
              inputProps={{
                id: 'hey'
              }}
              value={tagName}
              onChange={e => setTagName(e.target.value)}
              required
            >
              {tagList?.map((tag, i) => (
                <MenuItem value={tag} key={i}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className="folder_input mt-1.5"
            autoFocus
            margin="dense"
            id="name"
            required
            label={`Enter tag name`}
            type="name"
            fullWidth
            value={'' || tagValue}
            variant="standard"
            onChange={e => setTagValue(e.target.value)}
            InputLabelProps={{ className: 'text_label' }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => closeModal()}
            className={state?.loading ? '' : 'cancelBtn'}
            disabled={state?.loading ? true : false}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={state?.loading}
            className={state?.loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
