import { useRef } from 'react'
import fixWebmDuration from 'webm-duration-fix'

const useAudioBacktrackRecorder = () => {
  const audioContextRef = useRef(null)
  const mediaStreamRef = useRef(null)
  const mediaStreamSourceRef = useRef(null)
  const microphoneGainNodeRef = useRef(null)
  const musicGainNodeRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const recordedChunksRef = useRef([])

  const startAudioSession = async (backgroundMusicUrl, countDown, dispatch) => {
    try {
      // Create an AudioContext
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)()

      // Get user media (microphone access)
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false
        }
      })

      // Create a MediaStreamAudioSourceNode from the stream
      mediaStreamSourceRef.current = audioContextRef.current.createMediaStreamSource(
        mediaStreamRef.current
      )

      // Create a gain node to control the microphone volume (for recording)
      microphoneGainNodeRef.current = audioContextRef.current.createGain()
      microphoneGainNodeRef.current.gain.value = 5 // Set microphone gain value (volume) to 200%

      // Connect the input to the microphone gain node
      mediaStreamSourceRef.current.connect(microphoneGainNodeRef.current)

      // Load and decode background music
      const response = await fetch(backgroundMusicUrl)
      console.log('start timer', new Date().getSeconds())

      await countDown(dispatch)
      console.log('start timer finish', new Date().getSeconds())

      const arrayBuffer = await response.arrayBuffer()
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      )
      const backgroundMusicSource = audioContextRef.current.createBufferSource()
      backgroundMusicSource.buffer = audioBuffer
      backgroundMusicSource.loop = true

      // Create a gain node for background music
      musicGainNodeRef.current = audioContextRef.current.createGain()
      musicGainNodeRef.current.gain.value = 0.5 // Set background music volume to 50%

      // Connect background music to its gain node
      backgroundMusicSource.connect(musicGainNodeRef.current)
      // Connect background music gain node to the audio context destination (speakers)
      musicGainNodeRef.current.connect(audioContextRef.current.destination)

      // Create a destination node for recording
      const destinationNode = audioContextRef.current.createMediaStreamDestination()

      // Connect gain nodes to the destination node
      microphoneGainNodeRef.current.connect(destinationNode)
      musicGainNodeRef.current.connect(destinationNode)

      // Create a MediaRecorder to record the mixed audio
      mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)
      mediaRecorderRef.current.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data)
        }
      }

      // Start recording
      mediaRecorderRef.current.start()
      backgroundMusicSource.start()

      console.log('Audio session started for play and record.')
    } catch (error) {
      console.error('Failed to set up audio session:', error.message)
    }
  }

  const stopAudioSession = async (uploadRecording, fileName) => {
    if (audioContextRef.current && mediaRecorderRef.current) {
      // Stop recording
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.onstop = async () => {
        // Create a Blob from the recorded chunks
        const fileBlob = new Blob(recordedChunksRef.current, {
          type: 'audio/webm'
        })
        // Create a URL for the Blob
        const audioUrl = URL.createObjectURL(fileBlob)
        // setAudioUrl(audioUrl)
        const audioBlob = await fixWebmDuration(fileBlob, {
          mimetype: `audio/x-wav`
        })

        console.log('Recorded audio Blob:', audioBlob)
        console.log('Recorded audio URL:', audioUrl)
        await uploadRecording(audioBlob, fileName)
        // return { mixUrl: audioUrl, mixBlob: audioBlob }

        // Reset recordedChunks for the next recording session
        recordedChunksRef.current = []
      }

      // Disconnect the nodes
      if (mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect()
      }
      if (microphoneGainNodeRef.current) {
        microphoneGainNodeRef.current.disconnect()
      }
      if (musicGainNodeRef.current) {
        musicGainNodeRef.current.disconnect()
      }

      // Stop all tracks in the media stream
      mediaStreamRef.current.getTracks().forEach(track => track.stop())

      // Close the audio context
      audioContextRef.current.close()

      console.log('Audio session stopped.')
    } else {
      console.warn('Audio session is not active.')
    }
  }

  const loadAudio = async (url, audioContext) => {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer()
    return await audioContext.decodeAudioData(arrayBuffer)
  }

  const mixAudio = async (url1, url2) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    const audioBuffer1 = await loadAudio(url1, audioContext)
    const audioBuffer2 = await loadAudio(url2, audioContext)

    const length = audioBuffer2.length // Set the length to the length of audioBuffer2
    const sampleRate = audioBuffer1.sampleRate

    const mixedBuffer = audioContext.createBuffer(2, length, sampleRate)

    for (let channel = 0; channel < mixedBuffer.numberOfChannels; channel++) {
      const outputData = mixedBuffer.getChannelData(channel)
      const inputData1 = audioBuffer1.getChannelData(
        channel % audioBuffer1.numberOfChannels
      )
      const inputData2 = audioBuffer2.getChannelData(
        channel % audioBuffer2.numberOfChannels
      )

      for (let i = 0; i < length; i++) {
        outputData[i] = (inputData1[i] * 0.3 || 0) + (inputData2[i] || 0)
      }
    }

    return mixedBuffer
  }

  const exportAudio = async buffer => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    const offlineContext = new OfflineAudioContext(
      buffer.numberOfChannels,
      buffer.length,
      buffer.sampleRate
    )

    const bufferSource = offlineContext.createBufferSource()
    bufferSource.buffer = buffer
    bufferSource.connect(offlineContext.destination)
    bufferSource.start()

    const renderedBuffer = await offlineContext.startRendering()
    const wavData = audioBufferToWav(renderedBuffer)
    const mixBlob = new Blob([new DataView(wavData)], { type: 'audio/wav' })
    const mixUrl = URL.createObjectURL(mixBlob)
    return { mixUrl, mixBlob }
  }

  const audioBufferToWav = buffer => {
    const numOfChan = buffer.numberOfChannels
    const length = buffer.length * numOfChan * 2 + 44
    const bufferArray = new ArrayBuffer(length)
    const view = new DataView(bufferArray)

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    let offset = 0
    writeString(view, offset, 'RIFF')
    offset += 4
    view.setUint32(offset, 36 + buffer.length * numOfChan * 2, true)
    offset += 4
    writeString(view, offset, 'WAVE')
    offset += 4
    writeString(view, offset, 'fmt ')
    offset += 4
    view.setUint32(offset, 16, true)
    offset += 4
    view.setUint16(offset, 1, true)
    offset += 2
    view.setUint16(offset, numOfChan, true)
    offset += 2
    view.setUint32(offset, buffer.sampleRate, true)
    offset += 4
    view.setUint32(offset, buffer.sampleRate * numOfChan * 2, true)
    offset += 4
    view.setUint16(offset, numOfChan * 2, true)
    offset += 2
    view.setUint16(offset, 16, true)
    offset += 2
    writeString(view, offset, 'data')
    offset += 4
    view.setUint32(offset, buffer.length * numOfChan * 2, true)
    offset += 4

    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = buffer.getChannelData(channel)[i]
        sample = Math.max(-1, Math.min(1, sample))
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        )
        offset += 2
      }
    }

    return bufferArray
  }

  const startMixing = async (backTrackUrl, recordUrl) => {
    const mixedBuffer = await mixAudio(backTrackUrl, recordUrl)
    return await exportAudio(mixedBuffer)
  }

  return {
    startMixing,
    startAudioSession,
    stopAudioSession
  }
}

export default useAudioBacktrackRecorder
