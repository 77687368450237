import React, { useState } from 'react'
import IdaFavTagModal from '../../Modal/IdeaFavTagModal'
import { AddIcon } from '../../../helper/icons'

const Favs = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12 text-center">
          <div className="add-btn-right-bar" onClick={() => setOpenModal(true)}>
            <span>My Favorites </span>
            {/* <img
              className="add-btn-right-bar"
              src="assets/images/add_icon.svg"
              alt=""
            /> */}

            <AddIcon
              className={`pr-2 add-icon`}
              // clickFun={() => !disabled && handleClick()}
            />
          </div>
        </div>
      </div>

      <IdaFavTagModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

export default Favs
