import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { deleteMethod } from '../helper/ApiMethods'
import { useDispatch, useSelector } from 'react-redux'
import { Api } from '../helper/ApiList'
import { useAlert } from 'react-alert'
import { songAction } from '../services/actions/song.action'
import { loaderAction } from '../services/actions/loader.action'
import copy from 'copy-to-clipboard'
import { UseAlert } from '../components/commonComponent/commonComponent'
import { toast } from '../components/Toast/Toast'
const options = ['Copy', 'Delete']

const ITEM_HEIGHT = 48

const SongBlockThreeDots = ({ block, editor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { token } = useSelector(state => state.auth)
  const { singleSong, songBlocks } = useSelector(state => state.singleSong)
  const [isOpen, setIsOpen] = React.useState(false)

  const dispatch = useDispatch()
  const alert = useAlert()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = async () => {
    dispatch(loaderAction(true))
    const { data, error } = await deleteMethod(
      token,
      Api.deleteSongBlock + '/' + block?._id
    )
    if (!error) {
      toast.success(data?.message)
      let temp = [...songBlocks]

      let result = temp.filter(item => item?._id !== block._id)
      dispatch(songAction(singleSong, result))
    }
    error && toast.error(error)
    dispatch(loaderAction(false))
    handleClose()
    setIsOpen(false)
  }

  const handleCopy = () => {
    // navigator.clipboard.write(editor)
    copy(editor, {
      debug: true,
      message: 'Press #{key} to copy',
      format: 'text/html'
    })
    if (window?.electron) {
      function htmlToPlainTextWithNewLines(html) {
        // Replace closing paragraph tags with a newline
        const textWithNewLines = html
          .replace(/<\/p>/gi, '\n') // Replace closing </p> tags with new lines
          .replace(/<p>/gi, '') // Remove opening <p> tags
          .replace(/<\/div>/gi, '\n') // Replace closing </div> tags with new lines
          .replace(/<div>/gi, '') // Remove opening <div> tags
          .replace(/<\/li>/gi, '\n') // Replace closing </li> tags with new lines
          .replace(/<li>/gi, '') // Remove opening <li> tags
          .replace(/<\/[^>]+>/g, '') // Remove any remaining HTML tags
          .replace(/\n{2,}/g, '\n') // Remove extra new lines
          .trim() // Trim leading and trailing new lines

        return textWithNewLines
      }
      const text = htmlToPlainTextWithNewLines(editor)
      console.log({ text, editor })
      window.electron.copyToClipboard(text)
    }

    handleClose()
  }

  return (
    <div>
      {/* <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton> */}
      <div className="menu-new">
        <img
          className=""
          src="assets/images/menu-icon-new.svg"
          alt=""
          width={15}
          onClick={handleClick}
        />
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            selected={option === 'Pyxis'}
            onClick={() =>
              option === 'Delete' ? setIsOpen(true) : handleCopy()
            }
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <UseAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmDelete={handleDelete}
      />
    </div>
  )
}

export { SongBlockThreeDots }
