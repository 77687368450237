import React, { useState, useEffect, useRef } from 'react'

import AudioRecorder from './demo/AudioTest16'

const AudioMixer = () => {
  const url1 =
    'https://test-swp-api.appsmaventech.com/api/v1/file-data/668b88dd8cd44fab910632aa'
  const [isMixing, setIsMixing] = useState(false)
  const [mixedAudioUrl, setMixedAudioUrl] = useState(null)

  const [isRecording, setIsRecording] = useState(false)
  const [audioUrl, setAudioUrl] = useState(null)
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])

  const loadAudio = async (url, audioContext) => {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer()
    return await audioContext.decodeAudioData(arrayBuffer)
  }

  const mixAudio = async (url1, url2) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    const audioBuffer1 = await loadAudio(url1, audioContext)
    const audioBuffer2 = await loadAudio(url2, audioContext)

    const length = Math.max(audioBuffer1.length, audioBuffer2.length)
    const sampleRate = audioBuffer1.sampleRate

    const mixedBuffer = audioContext.createBuffer(2, length, sampleRate)

    for (let channel = 0; channel < mixedBuffer.numberOfChannels; channel++) {
      const outputData = mixedBuffer.getChannelData(channel)
      const inputData1 = audioBuffer1.getChannelData(
        channel % audioBuffer1.numberOfChannels
      )
      const inputData2 = audioBuffer2.getChannelData(
        channel % audioBuffer2.numberOfChannels
      )

      for (let i = 0; i < length; i++) {
        outputData[i] = (inputData1[i] || 0) + (inputData2[i] || 0)
      }
    }

    return mixedBuffer
  }

  const exportAudio = async buffer => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    const offlineContext = new OfflineAudioContext(
      buffer.numberOfChannels,
      buffer.length,
      buffer.sampleRate
    )

    const bufferSource = offlineContext.createBufferSource()
    bufferSource.buffer = buffer
    bufferSource.connect(offlineContext.destination)
    bufferSource.start()

    const renderedBuffer = await offlineContext.startRendering()
    const wavData = audioBufferToWav(renderedBuffer)
    const blob = new Blob([new DataView(wavData)], { type: 'audio/wav' })
    const url = URL.createObjectURL(blob)
    return url
  }

  const audioBufferToWav = buffer => {
    const numOfChan = buffer.numberOfChannels
    const length = buffer.length * numOfChan * 2 + 44
    const bufferArray = new ArrayBuffer(length)
    const view = new DataView(bufferArray)

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    let offset = 0
    writeString(view, offset, 'RIFF')
    offset += 4
    view.setUint32(offset, 36 + buffer.length * numOfChan * 2, true)
    offset += 4
    writeString(view, offset, 'WAVE')
    offset += 4
    writeString(view, offset, 'fmt ')
    offset += 4
    view.setUint32(offset, 16, true)
    offset += 4
    view.setUint16(offset, 1, true)
    offset += 2
    view.setUint16(offset, numOfChan, true)
    offset += 2
    view.setUint32(offset, buffer.sampleRate, true)
    offset += 4
    view.setUint32(offset, buffer.sampleRate * numOfChan * 2, true)
    offset += 4
    view.setUint16(offset, numOfChan * 2, true)
    offset += 2
    view.setUint16(offset, 16, true)
    offset += 2
    writeString(view, offset, 'data')
    offset += 4
    view.setUint32(offset, buffer.length * numOfChan * 2, true)
    offset += 4

    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = buffer.getChannelData(channel)[i]
        sample = Math.max(-1, Math.min(1, sample))
        view.setInt16(
          offset,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        )
        offset += 2
      }
    }

    return bufferArray
  }

  const startMixing = async recordUrl => {
    setIsMixing(true)
    // const url1 = 'path_to_first_audio_file'
    // const url2 = 'path_to_second_audio_file'
    const mixedBuffer = await mixAudio(url1, recordUrl)
    const audioUrl = await exportAudio(mixedBuffer)
    setMixedAudioUrl(audioUrl)
    setIsMixing(false)
  }

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const mediaRecorder = new MediaRecorder(stream)

    mediaRecorder.ondataavailable = event => {
      audioChunksRef.current.push(event.data)
    }

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' })
      const audioUrl = URL.createObjectURL(audioBlob)
      setAudioUrl(audioUrl)
      startMixing(audioUrl)
      audioChunksRef.current = []
    }

    mediaRecorderRef.current = mediaRecorder
    mediaRecorder.start()
    setIsRecording(true)
  }

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      // await startMixing()
      setIsRecording(false)
    }
  }

  // new 16 July 2024

  const [audioURL, setAudioURL] = useState('')
  const audioContextRef = useRef(null)
  const destinationRef = useRef(null)
  const backgroundMusicRef = useRef(null)

  useEffect(() => {
    backgroundMusicRef.current = new Audio(url1)
    backgroundMusicRef.current.crossOrigin = 'anonymous'
  }, [])

  const startRecording1 = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)()

    // Create media stream source
    const source = audioContextRef.current.createMediaStreamSource(stream)
    const microphoneGain = audioContextRef.current.createGain()
    microphoneGain.gain.value = 1.0 // Increase microphone volume

    // Create background music source
    const audioTrack = audioContextRef.current.createMediaElementSource(
      backgroundMusicRef.current
    )
    const musicGain = audioContextRef.current.createGain()
    musicGain.gain.value = 0.5 // Reduce background music volume

    // Create destination
    destinationRef.current = audioContextRef.current.createMediaStreamDestination()

    // Connect sources to destination
    source.connect(microphoneGain).connect(destinationRef.current)
    audioTrack.connect(musicGain).connect(destinationRef.current)
    audioTrack.connect(audioContextRef.current.destination)

    // Play background music
    backgroundMusicRef.current.play()

    // Create MediaRecorder
    mediaRecorderRef.current = new MediaRecorder(
      destinationRef.current.stream,
      { mimeType: 'audio/webm' }
    )
    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data)
    }
    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' })
      const audioUrl = URL.createObjectURL(audioBlob)
      setAudioURL(audioUrl)

      // Reset chunks
      audioChunksRef.current = []
    }

    mediaRecorderRef.current.start(100) // Request data every 100ms to avoid buffer issues
    setIsRecording(true)
  }

  const stopRecording1 = () => {
    mediaRecorderRef.current.stop()
    setIsRecording(false)
    backgroundMusicRef.current.pause()
    backgroundMusicRef.current.currentTime = 0 // Reset background music
  }
  return (
    <div>
      <label htmlFor="">Background music</label>
      <audio controls src={url1}></audio>

      <button onClick={startMixing} disabled={isMixing}>
        {isMixing ? 'Mixing...' : 'Start Mixing'}
      </button>
      {mixedAudioUrl && (
        <div>
          <a href={mixedAudioUrl} download="mixedAudio.wav">
            Download Mixed Audio
          </a>
          <audio controls src={mixedAudioUrl}></audio>
        </div>
      )}

      <div>
        <button onClick={startRecording} disabled={isRecording}>
          {isRecording ? 'Recording...' : 'Start Recording'}
        </button>
        <button onClick={stopRecording} disabled={!isRecording}>
          Stop Recording
        </button>
        {audioUrl && (
          <div>
            <audio controls src={audioUrl}></audio>
          </div>
        )}
      </div>

      <hr />
      <h3>16 July 2024:</h3>
      <div>
        <AudioRecorder />
      </div>
    </div>
  )
}

export default AudioMixer
