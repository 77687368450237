import { AppStoreIcon } from '../../icons'
import './MacPrice.css'

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
const MacPrice = () => {
  const openStore = () => {
    if (window.opener) {
      window.opener.postMessage('call-parent-function', window.location.origin)
    }
  }
  return (
    <section className="plan-new p-4 isMac">
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="logo-header text-left">
              <img
                src="assets/images/logo_new.svg"
                className="img-fluid"
                width="250"
                alt="logo"
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-12 text-left">
            <div className="">
              <h5 className="heading">
                To upgrade to Pro, sign in to our mobile app <br />
                on your iPhone or iPad.
              </h5>
              <div className="download-app-icon" onClick={() => openStore()}>
                <AppStoreIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 padding-left-right-50">
          <div className="col-lg-12 text-center">
            <div className="">
              <h5>Next level your songwriting today!</h5>
            </div>
          </div>
        </div>
        <div className="tick-option padding-left-right-50">
          <div className="row mt-2">
            <div className="col-12">
              <div className="tick-with-text">
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Advanced AI Song Lyric Generator
                </p>
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Lyric and Notes editor
                </p>

                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Mood Idea Generator
                </p>
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Rhymes, Dictionary, and Thesaurus
                </p>
              </div>

              <div className="tick-with-text">
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Audio recorder with unlimited recordings
                </p>
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Auto-Sync + Folder & Song management{' '}
                </p>
                <p>
                  <img
                    src="assets/images/tick-mark.svg"
                    className="mr-2"
                    width="22"
                    alt="tick"
                  />{' '}
                  Use on all your mobile & desktop devices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MacPrice
