/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import packageJson from '../../package.json'
import jwt_decode from 'jwt-decode'
import { getMethod } from '../helper/ApiMethods'
import { constants } from '../services/constants'
import { Api } from '../helper/ApiList'
import DeleteAccount from '../components/Modal/DeleteAccount'

const planNames = {
  free: 'Free Limited',
  premium: 'Premium',
  expired: 'Expired',
  pro: "Songwriter's Pad Pro",
  cancel: 'Cancel'
}
function Profile() {
  const { token } = useSelector(state => state.auth)
  const [plan, setPlan] = useState('Free Limited')
  const [userData, setUserData] = useState(null)
  const decoded = jwt_decode(token)

  useEffect(() => {
    let planName = decoded?._doc?.pro?.planName || decoded?.pro?.planName
    setPlan(
      planName === 'free'
        ? 'Free Limited'
        : planName === 'expired'
        ? 'Expired'
        : planName === 'Paid'
        ? `Legacy Pro`
        : planName === "Songwriter's Pad Pro"
        ? `Songwriter's Pad Pro`
        : `Premium Subscription - ${planName}`
    )
  }, [token, planNames, decoded])

  const openEmail = async () => {
    const subject = `Songwriter's Pad 1.0(version) Support`
    const body = ``
    let url = `mailto:support@scriptively.com?subject=${subject}&body=${body}`
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    // console.log(a);

    a.click()
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if (token) {
      const userInfo = async () => {
        const { data } = await getMethod(token, Api.userInfo)

        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: data?.accessToken || token,
            email: data.email,
            plan: data.planType,
            user: data
          }
        })
        setUserData(data)
      }

      userInfo()
    }
  }, [])

  return (
    <>
      <div id="content">
        <div className="">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading-title mt-2 position-relative">
                <h5>
                  <b className="pl-2">Profile</b>
                </h5>
                <span className="user_name">{userData?.username || 'N/A'}</span>
              </div>
            </div>
          </div>
          <div className="profile_null_bg"></div>

          <div className="row">
            <div className="col-lg-12 profile-section">
              {/* {profile_data === null || profile_data === "" ? null : (
                        <div className="pro-content">
                          <h5 className="pro-heading">
                            <b style={{ textTransform: "capitalize" }}>
                              {profile_data === null || profile_data === ""
                                ? null
                                : profile_data.first_name}
                              <span>
                                {" "}
                                {profile_data.last_name === "" ||
                                profile_data.last_name === null
                                  ? null
                                  : profile_data.last_name}
                              </span>
                            </b>
                          </h5>
                          <p className="email">
                            {" "}
                            {profile_data.email === "" ||
                            profile_data.email === null
                              ? null
                              : profile_data.email}
                          </p>
                        </div>
                      )} */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 profile-bg-header  ">
              <div
                className="pro-gray-shape d-flex"
                style={{ cursor: 'default' }}
              >
                <div style={{ width: '60%' }}>
                  <h5 className="pro-heading">My Account</h5>
                </div>
                <div className="mr-2 arrow_icon">
                  <p
                    className="mb-0 "
                    style={{ fontSize: '14px', fontWeight: 500 }}
                  >
                    {plan}
                  </p>
                </div>
              </div>
            </div>
            <Link to="/price" className="w-100 link">
              <div className="col-lg-12 profile-bg-header">
                <div className="d-none">
                  <p id="sub-amount-total" className="d-none"></p>
                </div>
                {/* <a href="/subscription"> */}
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading">In App Purchases</h5>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </Link>
            <Link to="/referral" className="w-100 link">
              <div className="col-lg-12 profile-bg-header">
                <div className="d-none">
                  <p id="sub-amount-total" className="d-none"></p>
                </div>
                {/* <a href="/subscription"> */}
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading m-0">
                      {' '}
                      <img
                        src="assets/images/icon_gift.png"
                        className="gift-icon"
                        width={20}
                        height={'auto'}
                        alt=""
                        srcset=""
                      />{' '}
                      Referrals & AI Token Credits
                    </h5>
                    <p className="para fs-12 m-0">
                      Earn 500 credits for every new friend you refer.
                    </p>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </Link>

            <br />
            <div className="col-lg-12 profile-bg-header">
              <a
                href="https://songwriterspad.com/how-to-videos/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading">How to Videos</h5>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
              </a>{' '}
            </div>
            <div
              className="col-lg-12 profile-bg-header"
              style={{ cursor: 'pointer' }}
            >
              <a
                href="https://songwriterspad.com/faqs/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading">FAQs</h5>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-12 profile-bg-header"
              style={{ cursor: 'pointer' }}
            >
              <a
                href="https://songwriterspad.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading">Support</h5>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div
              className="col-lg-12 profile-bg-header"
              style={{ cursor: 'pointer' }}
            >
              <a
                href="https://songwriterspad.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <div className="pro-gray-shape d-flex">
                  <div style={{ width: '60%' }}>
                    <h5 className="pro-heading">Privacy Policy</h5>
                  </div>
                  <div className="arrow_icon mr-2">
                    <div>
                      <img
                        src="assets/images/arrow.svg"
                        width="8px"
                        alt="right-icon"
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-12 profile-bg-header">
              {/* <a
                href="#/"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              > */}
              <div
                className="pro-gray-shape d-flex"
                style={{ cursor: 'default' }}
              >
                <div style={{ width: '60%' }}>
                  <h5 className="pro-heading">
                    {' '}
                    Version {packageJson.version}
                  </h5>
                </div>
                <div className="arrow_icon mr-2">
                  <div>
                    <img
                      src="assets/images/arrow.svg"
                      width="8px"
                      alt="right-icon"
                    />
                  </div>
                </div>
              </div>
              {/* </a> */}
            </div>

            {/* <div
              className="col-lg-12 profile-bg-header"
              style={{ cursor: 'pointer' }}
            >
              <div className="pro-gray-shape d-flex">
                <div style={{ width: '60%' }}>
                  <LogoutModal />
                </div>
                <div className="arrow_icon mr-2">
                  <div>
                    <img
                      src="assets/images/arrow.svg"
                      width="8px"
                      alt="right-icon"
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-lg-12 mt-2 ml-2">
              <div style={{ width: '60%' }}>
                {/* <p className="copyright ps-2">
                  Copyright &copy; {2023}, Songwriter's Pad, LLC
                </p> */}
              </div>
              <DeleteAccount />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
