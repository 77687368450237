import cookie from 'js-cookie'

// Set in Cookie management

const setCookie = (key, value) => {
  if (window !== undefined) {
    cookie.set(key, value, {
      // 7 days
      expires: 7
    })
  }
}

const removeCookie = key => {
  if (window !== undefined) {
    cookie.remove(key, {
      // 7 days
      expires: 7
    })
  }
}

// Get from cookie like token
const getCookie = key => {
  if (window !== 'undefined') {
    return cookie.get(key)
  }
}

//@ redux management =>>> user

// Auth user after login for vendor

const authenticate = (token, next) => {
  setCookie('access_token', token)
  next()
}

const getAuthentication = () => {
  return getCookie('access_token')
}

const removeAuthentication = next => {
  removeCookie('access_token')
  next()
}

// SESSION STORAGE Auth user after login for vendor

const setSession = (token, next) => {
  localStorage.setItem('access_token', token)
  next()
}

const setSessionStorage = (key, value) => {
  localStorage.setItem(key, value)
}

const getSessionStorage = key => {
  const item = sessionStorage.getItem(key)
  if (!item) {
    return localStorage.getItem(key)
  }
  return item
}

const getSession = () => {
  const access_token = sessionStorage.getItem('access_token')
  if (!access_token) {
    return localStorage.getItem('access_token')
  }
  return access_token
}

const removeSession = next => {
  localStorage.removeItem('access_token')
  sessionStorage.removeItem('access_token')
  next()
}
// Auth user after login for vendor
const setEmail = (email, next) => {
  setCookie('e', email)
  next()
}

const getEmail = () => {
  return getCookie('e')
}

const removeEmail = next => {
  removeCookie('e')
  next()
}

export {
  setCookie,
  removeCookie,
  getCookie,
  authenticate,
  getAuthentication,
  removeAuthentication,
  setSession,
  getSession,
  removeSession,
  setEmail,
  getEmail,
  removeEmail,
  setSessionStorage,
  getSessionStorage
}
