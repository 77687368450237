import React, { useMemo } from 'react'
import { RecentSongComponents } from './common/Song'
import SongFolders from './SongFolders'
import ProfileFooter from './ProfileFooter'
import {
  addNotebookToggleAction,
  addSongToggleAction
} from '../../services/actions/toggle.action'
import { useDispatch, useSelector } from 'react-redux'
import {
  AIAssistLeftSidebar,
  LeftASideAddComponent,
  NotebookLeftSidebar
} from '../commonComponent/commonComponent'
const LeftASide = () => {
  const dispatch = useDispatch()
  const { recentSongs } = useSelector(state => state.recentSongs)

  const recent = useMemo(() => {
    if (recentSongs?.length > 0) {
      const arrayUniqueByKey = [
        ...new Map(recentSongs.map(item => [item['_id'], item])).values()
      ]

      return arrayUniqueByKey
    }
    return []
  }, [recentSongs])

  return (
    <div>
      <LeftASideAddComponent
        title={'Song'}
        handleClick={() => dispatch(addSongToggleAction('Add'))}
      />
      <div className="sidebar-content-wrapper">
        {/* Recent Songs */}
        <div className="recentSongs">
          <h5>Recent Songs</h5>
          <div className="innerRecentSongs">
            {recent?.map((song, index) => (
              <RecentSongComponents
                key={index}
                song={song}
                img={'assets/images/recent-song-icon-new.svg'}
              />
            ))}
          </div>
        </div>
        {/* notebook */}

        <NotebookLeftSidebar
          title={'Notes'}
          handleClick={() => dispatch(addNotebookToggleAction('Add'))}
          left={true}
        />

        {/* AI Assistant */}
        <AIAssistLeftSidebar />

        {/* Song Folders */}
        <SongFolders />
      </div>
      {/* Profile */}
      <div className="profile-footer-wrapper">
        <ProfileFooter />
      </div>
    </div>
  )
}

export default LeftASide
