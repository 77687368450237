import React from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import {
  backgroundColorToggleAction,
  colorToggleAction,
  colorToggleHideAction
} from '../../../services/actions/toggle.action'
import './ColorPicker.css'
function ColorPicker({ editor }) {
  const { colorToggle } = useSelector(state => state.colorToggle)
  const dispatch = useDispatch()

  return (
    <>
      <img
        src="assets/images/font-color-toolbar.svg"
        alt=""
        srcSet=""
        width="28px"
        onClick={() => dispatch(colorToggleAction())}
      />

      {colorToggle ? (
        <div className="color_picker mt-4 ">
          <CirclePicker
            onChange={event => {
              editor.chain().focus().setColor(event.hex).run()
              dispatch(colorToggleHideAction())
            }}
          />
        </div>
      ) : null}
    </>
  )
}

const BackgroundColorPicker = ({ editor }) => {
  const { backColorToggle } = useSelector(state => state.backColorToggle)
  const dispatch = useDispatch()
  return (
    <>
      <img
        src="assets/images/highlight-toolbar.svg"
        alt=""
        srcSet=""
        width="28px"
        onClick={() => dispatch(backgroundColorToggleAction())}
      />

      {backColorToggle ? (
        <div className="color_picker mt-4 ">
          <CirclePicker
            onChange={e => {
              editor.chain().focus().toggleHighlight({ color: e.hex }).run()
              dispatch(colorToggleHideAction())
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export { ColorPicker, BackgroundColorPicker }
