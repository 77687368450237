import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton'
import { postMethod, putMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { useAlert } from 'react-alert'
import { allFolderAction } from '../../services/actions/folder.action'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import CloseIcon from '@mui/icons-material/Close'
import { ideaAction } from '../../services/actions/idea/idea.action'
import { toast } from '../Toast/Toast'
export default function TabModal({
  openModal,
  setOpenModal,
  ideaType,
  ideaName,
  getIdeas
}) {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { token } = useSelector(state => state.auth)
  const { ideaList } = useSelector(state => state.ideaList)

  const dispatch = useDispatch()
  const alert = useAlert()

  const [ideaValue, setIdeaValue] = React.useState('')

  const handleSubmit = async () => {
    if (!ideaName) return toast.error('Please enter a name')
    let body = {
      ideaType: ideaType,
      ideaName: ideaName,
      ideaValue: ideaValue
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    const { data, error } = await postMethod(token, Api.addIdea, body)

    if (!error) {
      toast.success(data?.message)

      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data?.data }
      })
      delete body.ideaType
      let clone = [...ideaList, body]

      dispatch(ideaAction(clone))

      // await getIdeas()
      closeModal()
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  const closeModal = () => {
    setIdeaValue('')
    setOpenModal(false)
  }

  return (
    <div className="folder_modal">
      <Dialog open={openModal}>
        <DialogTitle className="custom_modal">Add Idea</DialogTitle>

        {/* <CloseIcon className="icon_close_btn" onClick={() => closeModal()} /> */}

        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => closeModal()}
        />
        <DialogContent className="dialog_content">
          <TextField
            className="folder_input"
            autoFocus
            margin="dense"
            id="name"
            required
            label={`Enter ${ideaType?.slice(0, -1)}`}
            type="name"
            fullWidth
            value={'' || ideaValue}
            variant="standard"
            onChange={e => setIdeaValue(e.target.value)}
            InputLabelProps={{ className: 'text_label' }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => closeModal()}
            className={state?.loading ? '' : 'cancelBtn'}
            disabled={state?.loading ? true : false}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={state?.loading}
            className={state?.loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
