import React from 'react'

import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import {
  leftSidebarToggleAction,
  rightSidebarToggleAction
} from '../../services/actions/toggle.action'

import CustomToolbar from './CustomToolbar'
import { hideTabs } from '../../utils/commonOptions'

const EditToolbar = ({ editor, isFullToolbar, blockRef }) => {
  const { leftToggle } = useSelector(state => state.leftToggle)
  const { rightToggle } = useSelector(state => state.rightToggle)
  const { type } = useSelector(state => state.songs)

  const dispatch = useDispatch()
  return (
    <>
      <div className="right-icon-close-new">
        <img
          src="assets/images/right-bar-close-new.svg"
          onClick={() => dispatch(leftSidebarToggleAction())}
          className={`right-icon-close ${leftToggle && 'd-none'}`}
          alt=""
          width="28px"
        />
      </div>
      <Typography
        noWrap
        sx={{ flexGrow: 1 }}
        component="div"
        className="custom_editor_toolbar d-flex align-items-center"
      >
        <CustomToolbar
          editor={editor}
          isFullToolbar={isFullToolbar}
          blockRef={blockRef}
        />
      </Typography>

      <img
        className={` ${
          !leftToggle && rightToggle && 'd-none'
        } left-bar-close-new right-toggle`}
        sx={{ ...(rightToggle && { display: 'none' }) }}
        src="assets/images/left-bar-close.svg"
        alt=""
        srcSet=""
        onClick={() => dispatch(rightSidebarToggleAction())}
        width={28}
      />
    </>
  )
}

export default EditToolbar
