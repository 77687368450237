import React from 'react'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import { postMethod } from '../../../helper/ApiMethods'
import { AddBlueIcon } from '../../../helper/icons'
import { getFavoriteTagsAction } from '../../../services/actions/cartTab/cartTabAction'
import { loaderAction } from '../../../services/actions/loader.action'
import { camalize } from '../../../utils/commonFunctions'
import { singleSongAction } from '../../../services/actions/song.action'
import { LeftRightPaneFolderTypes } from '../../../utils/commonOptions'

const IdeasTags = () => {
  const { ideaList, ideaType } = useSelector(state => state.ideaList)
  const { token } = useSelector(state => state.auth)
  const { singleSong } = useSelector(state => state.singleSong)
  const { type } = useSelector(state => state.songs)
  const { assistantTab } = useSelector(state => state.assistantTab)

  const alert = useAlert()
  const dispatch = useDispatch()

  function testFunc(e) {
    e.target.select()
  }

  const pixelNumber = item => {
    let num = item?.ideaValue?.trim()
    let number = num?.length

    return number > 26
      ? 27 + 'ch'
      : number > 22
      ? 22 + 'ch'
      : number <= 5
      ? 6 + 'ch'
      : number + 'ch'
  }

  const handleAddFavoriteTags = async item => {
    const body = {
      title: camalize(ideaType),
      label: item,
      songId: singleSong?._id
    }
    dispatch(loaderAction(true))

    const { data, error } = await postMethod(token, Api.addFavorites, body)

    if (!error) {
      dispatch(singleSongAction(data))
    }
    dispatch(loaderAction(false))
  }
  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-lg-12">
            <div className="drag-btn-right-bar">
              <ul
                className={`drag_idea_list  ${
                  singleSong &&
                  !assistantTab &&
                  type !== LeftRightPaneFolderTypes.NOTES
                    ? ''
                    : 'hide_footer'
                }`}
              >
                {ideaList?.length === 0 ? (
                  <div className="fs-14">
                    No data found, please add your ideas...
                  </div>
                ) : (
                  ideaList?.map((item, index) => (
                    <li className="drag-btn-inner" key={index}>
                      <input
                        type="text"
                        value={' ' + item?.ideaValue + ' '}
                        onFocus={testFunc}
                        id="select-input"
                        style={{
                          maxWidth: `${pixelNumber(item)}`
                        }}
                        onClick={testFunc}
                        onChange={testFunc}
                      />

                      <AddBlueIcon
                        className="add-blue-btn-iiner"
                        handleAddFavoriteTags={() =>
                          handleAddFavoriteTags(item?.ideaValue)
                        }
                      />
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IdeasTags
