import React, { useEffect, useState } from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import SongModal from '../../Modal/SongModal'
import { useDispatch, useSelector } from 'react-redux'
import { addSongBlockToggleAction } from '../../../services/actions/toggle.action'
import DragSongBlock from './DragSongBlock'
import {
  allSongAction,
  getRecordingAction,
  loaderAction,
  songAction
} from '../../../services/actions'
import { getMethod, putMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { UseAlert } from '../../commonComponent/commonComponent'
import { shiftUpdateSongElement } from '../../../utils/commonFunctions'
import { toast } from '../../Toast/Toast'

const SongBlocks = forwardRef((props, ref) => {
  const blockRef = useRef(null)

  const { disabled } = useSelector(state => state.loader)
  const { token } = useSelector(state => state.auth)
  const { songs, title, folderId } = useSelector(state => state.songs)
  const [isOpen, setIsOpen] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [heading, setHeading] = useState('')

  const { singleSong } = useSelector(state => state.singleSong)
  const dispatch = useDispatch()

  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      let data = blockRef.current.childFunction1()
      return data
    }
  }))

  const handleUpdateSongHeading = async () => {
    try {
      let url = Api.getSongBlock + singleSong?._id
      const { data, rest } = await getMethod(token, url)
      dispatch(songAction(rest.songDetails, data))
    } catch (error) {}
  }

  const handleRefresh = async () => {
    let url = Api.getRecordings + singleSong?._id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))

    setBtnLoading(false)
  }

  const handleUpdateRecordingNames = async () => {
    const body = {
      songName: heading
    }
    const url = Api.updateAllRecordingTitle?.replace(
      '{songId}',
      singleSong?._id
    )
    try {
      setBtnLoading(true)

      const { data, error } = await putMethod(token, url, body)

      if (!error) {
        toast.success(data?.message)
      }
      await handleRefresh()
    } catch (error) {
      console.log(error, 'recordings song deleted error')
    } finally {
      setIsOpen(false)
      dispatch(loaderAction(false))
      setBtnLoading(false)
    }
  }

  useEffect(() => {
    setHeading(singleSong?.songHeading || '')
  }, [singleSong?.songHeading])

  const handleUpdate = async () => {
    try {
      let body = {
        songHeading: heading
      }

      const { error } = await putMethod(
        token,
        Api.updateSong + '/' + singleSong?._id,
        body
      )

      if (!error) {
        const arr = await shiftUpdateSongElement(
          songs,
          heading,
          singleSong,
          'heading'
        )
        dispatch(allSongAction(arr, title, folderId))
        setIsOpen(true)
        await handleUpdateSongHeading()
      }
    } catch (error) {}
  }

  return (
    <>
      <SongModal />
      {isOpen && (
        <UseAlert
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          confirmDelete={handleUpdateRecordingNames}
          fullMessage={`Do you also want to change the title of your recordings`}
          cancelBtnText="No"
          confirmBtnText="Yes"
          loading={btnLoading}
        />
      )}
      <div className="row mb-2 mt-1">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div
            className="song-list-name"
            style={{ width: 'calc(100% - 150px)' }}
          >
            {/* <h5 >{singleSong?.songHeading || ''}</h5> */}
            <input
              type="text"
              value={heading}
              className="heading-input"
              onChange={e => setHeading(e.target.value)}
              // onBlur={handleUpdate}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleUpdate()
                }
              }}
            />
          </div>
          <div className={`${disabled ? 'disable' : ''} add-block`}>
            <button
              className="add-block-btn-new"
              onClick={() => {
                dispatch(addSongBlockToggleAction())
              }}
            >
              <img src="assets/images/add-song-icon-new.svg" alt="" />
              Add Song Block
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="song_block_content">
          <DragSongBlock ref={blockRef} />
        </div>
      </div>
    </>
  )
})

export default SongBlocks
