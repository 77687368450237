import { useQuery } from '../hooks/useQuery'

import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAlert } from 'react-alert'
import { initialState, postReducer } from '../hooks/useApiReducer'
import { ACTION_TYPES } from '../hooks/customReducerActionTypes'
import { Api } from './../helper/ApiList'
import axios from 'axios'

import { putMethod } from '../helper/ApiMethods'
import { toast } from '../components/Toast/Toast'

const theme = createTheme()

export default function SetPassword() {
  let query = useQuery()

  let accessToken = query.get('access_token')

  const alert = useAlert()
  const [state, postDispatch] = React.useReducer(postReducer, initialState)

  const handleSubmit = async event => {
    event.preventDefault()
    if (!accessToken) {
      toast.error('Url expired')
      return false
    }
    const form = new FormData(event.currentTarget)
    const body = {
      confirmPassword: form.get('confirmPassword'),
      password: form.get('password')
    }
    if (!body.confirmPassword || !body.password) {
      toast.error('All fields are required')
      return false
    }
    if (body.confirmPassword !== body.password) {
      toast.error('Confirm password is not matched')
      return false
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    try {
      const cancelToken = axios.CancelToken.source()
      const { data } = await putMethod(accessToken, Api.setPassword, body, {
        cancelToken: cancelToken.token
      })
      if (data.statusCode === 200) {
        toast.success(data.message)
      }

      postDispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: { data } })
      localStorage.clear()
      sessionStorage.clear()
      setTimeout(() => {
        window.location.href = '/login'
      }, 5000)
    } catch (error) {
      const message = error?.response?.data.message
      toast.error(message || error.message)
      console.log(error)
      postDispatch({ type: ACTION_TYPES.FETCH_ERROR })
    }
    // postReducer
  }

  return (
    <ThemeProvider theme={theme}>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center justify-content-center p-0 d-none d-md-block d-lg-block">
              <div className="login_left_side">
                <div className="logo text-center">
                  <img
                    className="vector_img"
                    src="assets/images/Blogging-bro.svg"
                    alt=""
                  />
                  <img
                    className=""
                    src="assets/images/sidebar_logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login_right_side">
                <div className="login_form">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Set Password
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{}}
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />

                      {state.loading ? (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Please wait...
                        </Button>
                      ) : (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ThemeProvider>
  )
}
