import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { initialState, postReducer } from '../hooks/useApiReducer'
import { ACTION_TYPES } from '../hooks/customReducerActionTypes'
import { Api } from './../helper/ApiList'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { authAction } from '../services/actions/auth.action'
import { Link } from 'react-router-dom'
import JsonAnimation from '../Loader/JsonAnimation'
import Recaptcha from 'react-google-invisible-recaptcha'
import { toast } from '../components/Toast/Toast'
import { getMobileOperatingSystem } from '../utils/commonFunctions'

const theme = createTheme()

export default function Login() {
  const refRecaptcha = React.useRef()

  const [state, postDispatch] = React.useReducer(postReducer, initialState)

  const dispatch = useDispatch()
  const handleSubmit = async event => {
    event.preventDefault()
    const form = new FormData(event.currentTarget)
    const body = {
      email: form.get('email'),
      password: form.get('password')
    }
    if (!body.email || !body.password) {
      toast.error('All fields are required')
      refRecaptcha?.current?.reset()

      return false
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })
    refRecaptcha?.current?.execute()

    try {
      const cancelToken = axios.CancelToken.source()
      const { data } = await axios.post(Api.loginAccount, body, {
        cancelToken: cancelToken.token
      })
      if (data.statusCode === 200) {
        toast.success(data.message)
        dispatch(authAction(data?.data))
      }

      postDispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: { data } })
    } catch (error) {
      const message = error?.response?.data.message
      toast.error(message || error.message)
      console.log(error)
      postDispatch({ type: ACTION_TYPES.FETCH_ERROR })
    }
    // postReducer
  }

  React.useEffect(() => getMobileOperatingSystem(), [])

  return (
    <ThemeProvider theme={theme}>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className=" d-flex align-items-center justify-content-center p-0 d-none d-md-block d-lg-block col-md-6">
              <div className="login_left_side">
                <div className="logo text-center">
                  {/* <img
                    className="vector_img"
                    src="assets/images/Blogging-bro.svg"
                    alt=""
                  /> */}
                  <JsonAnimation />

                  {/* <img
                    className=""
                    src="assets/images/sidebar_logo.svg"
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login_right_side">
                <div className="login_form">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{}}
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address/Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />

                      {state.loading ? (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Please wait...
                        </Button>
                      ) : (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Sign In
                        </Button>
                      )}
                      <Grid container>
                        <Grid item xs>
                          <Link to="/forget_password" variant="body2">
                            Forgot password?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link to="/signup" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recaptcha
          ref={refRecaptcha}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_API}
          onResolved={value => console.log(value)}
        />
      </section>
    </ThemeProvider>
  )
}
