// import { toast } from 'react-toastify'

const covertHtmlToPlainText = html => {
  html = html.replace(/<style([\s\S]*?)<\/style>/gi, '')
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, '')
  html = html.replace(/<\/div>/gi, '\n')
  html = html.replace(/<\/li>/gi, '\n')
  html = html.replace(/<li>/gi, '  *  ')
  html = html.replace(/<\/ul>/gi, '\n')
  html = html.replace(/<\/p>/gi, '\n')
  //   html = html.replace(/<br\s*[\/]?>/gi, '\n')
  html = html.replace(/<[^>]+>/gi, '')
  return html
}

const downloadSong = () => {
  const title = localStorage.getItem('title')

  let html = localStorage.getItem('attrTxt')
  const plainText = covertHtmlToPlainText(html)
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(plainText)
  )
  element.setAttribute('download', title)

  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  //   toast.success(`Exporting script...`, toastOptions)
  document.body.removeChild(element)
}

export { downloadSong, covertHtmlToPlainText }
