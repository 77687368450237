import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'
import { addFolderToggleAction } from '../../services/actions/toggle.action'
import LoadingButton from '@mui/lab/LoadingButton'
import { postMethod, putMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { useAlert } from 'react-alert'
import { allFolderAction } from '../../services/actions/folder.action'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import { toast } from '../Toast/Toast'

export default function FolderModal() {
  const { folderToggle, singleFolderData } = useSelector(
    state => state.folderToggle
  )
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { token } = useSelector(state => state.auth)
  const { folders } = useSelector(state => state.folders)

  const dispatch = useDispatch()
  const alert = useAlert()

  const [folderName, setFolderName] = React.useState('')

  React.useEffect(() => {
    singleFolderData !== 'Add' && setFolderName(singleFolderData?.folderName)
  }, [singleFolderData])

  const handleSubmit = async () => {
    if (!folderName) return toast.error('Please enter a folder name')
    let body = { folderName: folderName }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    if (singleFolderData !== 'Add') {
      handleUpdate()
      return
    }
    const { data, error } = await postMethod(token, Api.createFolder, body)

    if (!error) {
      toast.success(data?.message)
      let temp = [...folders]
      temp.unshift(data?.data)
      dispatch(allFolderAction(temp))
      dispatch(addFolderToggleAction())
      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data?.data }
      })
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  const handleUpdate = async () => {
    if (!folderName) return toast.error('Please enter a folder name')
    let body = { folderName: folderName }
    postDispatch({ type: ACTION_TYPES.FETCH_START })
    const { data, error } = await putMethod(
      token,
      Api.updateFolder + '/' + singleFolderData?._id,
      body
    )

    if (!error) {
      toast.success(data?.message)
      let temp = [...folders]
      let arr = temp?.filter(item => item._id !== singleFolderData?._id)

      arr.unshift(data?.data)

      dispatch(allFolderAction(arr))
      dispatch(addFolderToggleAction())
      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data?.data }
      })
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  return (
    <div className="folder_modal">
      <Dialog open={folderToggle}>
        <DialogTitle className="custom_modal">
          {singleFolderData === 'Add' ? 'Create' : 'Update'} Folder
        </DialogTitle>

        {/* <CloseIcon
          className="icon_close_btn"
          onClick={() => dispatch(addFolderToggleAction())}
        /> */}

        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => dispatch(addFolderToggleAction())}
        />

        <DialogContent className="dialog_content">
          <TextField
            className="folder_input"
            autoFocus
            margin="dense"
            id="name"
            required
            label="Enter folder name"
            type="name"
            fullWidth
            value={folderName || ''}
            variant="standard"
            onChange={e => setFolderName(e.target.value)}
            InputLabelProps={{ className: 'text_label' }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => dispatch(addFolderToggleAction())}
            className={state?.loading ? '' : 'cancelBtn'}
            disabled={state?.loading ? true : false}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={state?.loading}
            className={state?.loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={handleSubmit}
          >
            {singleFolderData === 'Add' ? 'Create' : 'Update'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
