import React, { useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { Select } from '@mui/material'
import { MenuItem } from '@mui/material'
import { BootstrapInput } from '../../utils/styled'
import { ColorPicker, BackgroundColorPicker } from './ColorPicker/ColorPicker'

import { useDispatch } from 'react-redux'
import { colorToggleHideAction } from '../../services/actions/toggle.action'
import { useSelector } from 'react-redux'
import { chordStyles } from '../../utils/commonFunctions'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'

const CustomToolbar = ({ editor, isFullToolbar, blockRef }) => {
  const dispatch = useDispatch()
  const divRef = useRef(null)

  const { type } = useSelector(state => state.songs)
  const { singleSong, singleNotebook, reOrderSongBlocks } = useSelector(
    state => state.singleSong
  )

  const { ai_generated } = useSelector(state => state.assistantTab)

  const [text, setText] = useState('')

  let tasks = useMemo(() => {
    function getAllTasks(data) {
      // Check if the "tasks" property exists in the data object
      if (data && data.tasks) {
        // Extract tasks from the "tasks" object
        const tasks = Object.values(data.tasks)

        // Return the array of tasks
        return tasks
      } else {
        // If "tasks" property is not found, return an empty array or handle accordingly
        return []
      }
    }

    return reOrderSongBlocks?.tasks && getAllTasks(reOrderSongBlocks)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reOrderSongBlocks, isFullToolbar, type])

  useEffect(() => {
    if (blockRef) {
      setInterval(() => {
        setText(
          blockRef?.current?.childFunction1()?.getHTML() ||
            ai_generated?.content
        )
      }, 2000)
    }
  }, [])

  const isFull = useMemo(() => {
    if (isFullToolbar) {
      return true
    }

    return false
  }, [isFullToolbar, type])

  if (!editor) {
    return null
  }


  const PrintSongBlocks = () => {
    return (
      <div ref={divRef} className="mt-3 p-5">
        <h5 className="fs-14 fw-500">
          {singleSong?.songHeading ||
            singleNotebook?.notebookHeading ||
            "Songwriter's Pad - AI Lyric Generator"}
        </h5>
        {isFull ? (
          <p
            className="fs-12"
            dangerouslySetInnerHTML={{
              __html: chordStyles(editor?.getHTML())
            }}
          />
        ) : (
          tasks?.map(blocks => (
            <div key={blocks?._id} className="">
              <h5 className="fs-12 fw-500">{blocks?.songBlockHeading}</h5>
              <p
                className="fs-12"
                dangerouslySetInnerHTML={{
                  __html: chordStyles(blocks?.songBlockDescription)
                }}
              />
            </div>
          ))
        )}
      </div>
    )
  }


  const isExportsBtn = () => {
    const id = type === LeftRightPaneFolderTypes.AI_ASSISTANT
      ? ai_generated?._id
      : isFullToolbar
      ? singleNotebook?._id
      : singleSong?._id;
  
    const isDisable = (type === LeftRightPaneFolderTypes.AI_ASSISTANT && !ai_generated) || !id;
  
    return {
      isDisable,
      type: type === LeftRightPaneFolderTypes.AI_ASSISTANT
        ? LeftRightPaneFolderTypes.AI_ASSISTANT
        : isFullToolbar
        ? 'notes'
        : 'song_block',
      id: id || undefined
    };
  };
  

  return (
    <>
      <div>
        <Select
          className="font-select selct-lang-cu"
          labelId="demo-customized-select-label"
          // id="demo-customized-select"
          input={<BootstrapInput className="select-font-inner" />}
          defaultValue={'Arial'}
          onClick={() => dispatch(colorToggleHideAction())}
          onChange={e => {
            if (isFull) {
              editor.chain().focus().setFontFamily(e.target.value).run()
            } else {
              blockRef?.current
                ?.childFunction1()
                ?.chain()
                ?.focus()
                ?.setFontFamily(e.target.value)
                ?.run()
            }
          }}
        >
          <MenuItem value={'Arial'}>Arial</MenuItem>
          <MenuItem value={'serif'}>Serif</MenuItem>
          <MenuItem value={'cursive'}>Cursive</MenuItem>
          <MenuItem value={'Georgia'}>Georgia</MenuItem>
          <MenuItem value={'Tahoma'}>Tahoma</MenuItem>
          <MenuItem value={'Verdana'}>Verdana</MenuItem>
          <MenuItem value={'Impact'}>Impact</MenuItem>
          <MenuItem value={'Courier New'}>Courier</MenuItem>
        </Select>

        <Select
          className="font-size"
          labelId="demo-customized-select-label"
          // id="demo-customized-select"
          input={<BootstrapInput className="select-font-inner" />}
          defaultValue={16}
          onClick={() => dispatch(colorToggleHideAction())}
          onChange={e => {
            if (isFull) {
              editor
                .chain()
                .focus()
                .setMark('textStyle', {
                  fontSize: `${e.target.value}px`
                })
                .run()
            } else {
              blockRef?.current
                ?.childFunction1()
                ?.chain()
                ?.focus()
                ?.setMark('textStyle', {
                  fontSize: `${e.target.value}px`
                })
                .run()
            }
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={16}>16</MenuItem>
          <MenuItem value={18}>18</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={22}>22</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={26}>26</MenuItem>
          <MenuItem value={28}>28</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={32}>32</MenuItem>
          <MenuItem value={34}>34</MenuItem>
          <MenuItem value={38}>38</MenuItem>
          <MenuItem value={40}>40</MenuItem>
        </Select>

        <IconButton
          className="toolbar-icons-new"
          onClick={() => {
            if (isFull) {
              editor.chain().focus().undo().run()
            } else {
              blockRef?.current
                ?.childFunction1()
                ?.chain()
                ?.focus()
                ?.undo()
                ?.run()
            }

            dispatch(colorToggleHideAction())
          }}
          // disabled={!editor.can().chain().focus().undo().run()}
        >
          <img
            src="assets/images/back-return-toolbar.svg"
            alt=""
            srcSet=""
            width="28px"
          />
        </IconButton>
        <IconButton
          className="toolbar-icons-new"
          onClick={() => {
            if (isFull) {
              editor.chain().focus().redo().run()
            } else {
              blockRef?.current
                ?.childFunction1()
                ?.chain()
                ?.focus()
                ?.redo()
                ?.run()
            }

            dispatch(colorToggleHideAction())
          }}
          // disabled={!editor.can().chain().focus().redo().run()}
        >
          <img
            src="assets/images/right-arrow-toolbar.svg"
            alt=""
            srcSet=""
            width="28px"
          />
        </IconButton>

        {isFull && (
          <>
            <IconButton className="toolbar-icons-new">
              <ColorPicker editor={editor} />
            </IconButton>

            <IconButton className="toolbar-icons-new">
              <BackgroundColorPicker editor={editor} />
            </IconButton>

            <IconButton
              onClick={() => {
                dispatch(colorToggleHideAction())

                editor.chain().focus().toggleBold().run()
              }}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={
                editor.isActive('bold') ? 'is-active' : 'toolbar-icons-new'
              }
            >
              <img
                src="assets/images/text-bold-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(colorToggleHideAction())

                editor.chain().focus().toggleItalic().run()
              }}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={
                editor.isActive('italic') ? 'is-active' : 'toolbar-icons-new'
              }
            >
              <img
                src="assets/images/italic-text-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
            <IconButton
              className="toolbar-icons-new"
              onClick={() => {
                editor.commands.toggleUnderline()
                dispatch(colorToggleHideAction())
              }}
            >
              <img
                src="assets/images/underliner-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(colorToggleHideAction())

                editor.chain().focus().setTextAlign('left').run()
              }}
              className={
                editor.isActive({ textAlign: 'left' }) ? 'is-active' : 'ssssss'
              }
            >
              <img
                src="assets/images/text-left-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(colorToggleHideAction())

                editor.chain().focus().setTextAlign('center').run()
              }}
              className={
                editor.isActive({ textAlign: 'center' })
                  ? 'is-active'
                  : 'center-tool-icon'
              }
            >
              <img
                src="assets/images/text-center-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(colorToggleHideAction())

                editor.chain().focus().setTextAlign('right').run()
              }}
              className={
                editor.isActive({ textAlign: 'right' })
                  ? 'is-active'
                  : 'right-tool-icon'
              }
            >
              <img
                src="assets/images/text-right-toolbar.svg"
                alt=""
                srcSet=""
                width="28px"
              />
            </IconButton>
          </>
        )}
      </div>

      <div aria-disabled={isExportsBtn().isDisable}>
        <img
          onClick={() =>
            window.open(
              `/preview?type=${isExportsBtn()?.type}&id=${isExportsBtn()?.id}`,
              'Preview',
              'left=250,top=100,width=650,height=700'
            )
          }
          src="assets/images/Preview-icon.svg"
          alt=""
          className="perview-icon-tool"
        />
      </div>
     
      <div className="d-none">
        <PrintSongBlocks />
      </div>
    </>
  )
}

export default CustomToolbar
