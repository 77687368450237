import React from 'react'
import { useSelector } from 'react-redux'

const Seekbar = ({ value, min, max, onInput }) => {
  const { activeSong } = useSelector(state => state.playPause)

  const { backTrackSelectActiveSong } = useSelector(
    state => state.selectBackTrack
  )

  const getTime = time =>
    `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(-2)}`

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="play-bar range-slider"
        type="range"
        step="any"
        value={value}
        min={min}
        max={max}
        onInput={onInput}
      />
      <span className="text-dark volume-custom">
        {value === 0
          ? '0:00'
          : activeSong || backTrackSelectActiveSong
          ? getTime(value)
          : '0:00'}
      </span>

      <span
        className="text-dark volume-max-custom"
        style={{ userSelect: 'none' }}
      >
        <span className='lint-play'>|</span>
        {max === 0
          ? '0:00'
          : activeSong || backTrackSelectActiveSong
          ? getTime(max)
          : '0:00'}
      </span>
    </div>
  )
}

export default Seekbar
