import React from 'react'

import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { backTrackAction } from '../../../services/actions/mediaPlayerActions/backTrack.action'
import { timeFormat } from '../../../utils/commonFunctions'

import { postFileUploadMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { useAlert } from 'react-alert'
import { loaderAction } from '../../../services/actions/loader.action'
import { toast } from '../../Toast/Toast'
import { AddMoreBlueIcon } from '../../../helper/icons'
const UploadBackTrack = () => {
  const { allBackTracks } = useSelector(state => state.allBackTracks)
  const { token } = useSelector(state => state.auth)
  const alert = useAlert()
  const dispatch = useDispatch()

  const getFile = e => {
    dispatch(loaderAction(true))
    let url = URL.createObjectURL(e.target.files[0])

    var file = e.target.files[0]

    // Create instance of FileReader
    var reader = new FileReader()

    // When the file has been succesfully read
    reader.onload = function (event) {
      // Create an instance of AudioContext
      var audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()

      // Asynchronously decode audio file data contained in an ArrayBuffer.
      audioContext.decodeAudioData(event.target.result, function (buffer) {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        var duration = timeFormat(buffer.duration)

        uploadFile(e, duration)
      })
    }

    // In case that the file couldn't be read
    reader.onerror = function (event) {
      console.error('An error ocurred reading the file: ', event)
    }

    // Read file as an ArrayBuffer, important !
    reader.readAsArrayBuffer(file)
  }

  const uploadFile = async (e, duration) => {
    var myForm = new FormData()
    myForm.append('title', e.target.files[0].name)
    myForm.append('duration', duration)
    myForm.append('audioFile', e.target.files[0])
    const { error, data } = await postFileUploadMethod(
      token,
      Api.addBackTrack,
      myForm
    )
    if (data) {
      console.log(data, 'upload success')
      let arr = [...allBackTracks, data?.data]
      dispatch(backTrackAction(arr))
      toast.success(data?.message)
      dispatch(loaderAction(false))
    }
    if (error) {
      toast.error(error || 'Something went wrong')
      dispatch(loaderAction(false))

      return
    }
  }

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="label"
        className="add_track_btn"
      >
        <input
          hidden
          accept=".mp3,audio/mp3,audio/*;capture=microphone/*"
          type="file"
          onChange={getFile}
        />
        <AddMoreBlueIcon
          className={`pr-2 gap-2 add-icon`}
          aria-label="upload picture"
          height={22}
          width={22}
        />{' '}
        {/* <span className="add-back-track-icon">Add track</span> */}
      </IconButton>
    </>
  )
}

export { UploadBackTrack }
