import React from 'react'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { backTrackAction } from '../../../services/actions/mediaPlayerActions/backTrack.action'
import { putMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { toast } from '../../Toast/Toast'

const BackTrack = ({
  backTrackIsPlaying,
  track,
  handlePlayPause,
  backTrackActiveSong,
  handleSongSelect,
  selectSongs,
  loaderId,
  isEdit,
  setIsEdit,
  index
}) => {
  const dispatch = useDispatch()
  const { allBackTracks } = useSelector(state => state.allBackTracks)

  const { token } = useSelector(state => state.auth)
  const [isEditItemId, setIsEditItemId] = useState(null)

  const handleTitleChange = e => {
    let allRecordings = [...allBackTracks]
    let findEle = allBackTracks[index]

    findEle.title = e.target.value
    allRecordings[index] = findEle
    dispatch(backTrackAction(allRecordings))
  }

  const handleUpdateTitle = async e => {
    const body = {
      title: e.target.value
    }
    if (!isEditItemId) {
      return
    }
    const url = Api.updateBackTrackTitle + allBackTracks[index]?._id

    setIsEditItemId(null)
    try {
      const { data, error } = await putMethod(token, url, body)

      if (!error) {
        toast.success(data?.message)
      }
    } catch (error) {
      console.log(error, 'recordings song deleted error')
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleUpdateTitle(e)
    }
  }

  return (
    <>
      <div
        className={`track-list cursor ${
          selectSongs.includes(track?._id) && 'active'
        }`}
      >
        <div>
          <div className="d-flex">
            {/* <span> */}
            <img
              className="recording_play_icon"
              src={
                track?._id === backTrackActiveSong?._id && backTrackIsPlaying
                  ? 'assets/images/pause.svg'
                  : 'assets/images/play-border-new.svg'
              }
              alt=""
              width={22}
              onClick={e => {
                e.stopPropagation()
                handlePlayPause(track)
              }}
            />
            {/* </span> */}
            <div className="song-item-wrapper">
              <div
                onClick={() => handleSongSelect(track)}
                className="song-track"
              >
                {isEdit ? (
                  <input
                    type="text"
                    autoComplete="off"
                    value={track?.title}
                    onClick={e => {
                      e.stopPropagation()
                      setIsEditItemId(track?._id)
                    }}
                    onChange={e => handleTitleChange(e)}
                    onKeyPress={handleKeyPress}
                    onBlur={handleUpdateTitle}
                  />
                ) : (
                  track?.title
                )}
              </div>
            </div>
          </div>
          <div>
            {track?.created_at && moment(track?.created_at).format('MM/DD/YY')}
            {loaderId.includes(track?.title) && (
              <span className="download-msg">Downloading...</span>
            )}
          </div>
          {/* <div onClick={() => handleSongSelect(track)}>
          {loaderId.includes(track?.title) && (
            <span className="download-msg">Downloading...</span>
          )}
        </div> */}
        </div>
      </div>
    </>
  )
}

export default BackTrack
