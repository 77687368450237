import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import { useSelector, useDispatch } from 'react-redux'
import { deleteMethod, getMethod, postMethod } from '../../helper/ApiMethods'
import {
  assistantFooterDeleteAction,
  assistantFooterToggleAction
} from '../../services/actions/toggle.action'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { Api } from '../../helper/ApiList'
import { useState } from 'react'
import { loaderAction } from '../../services/actions/loader.action'
import { allSongAction } from '../../services/actions/song.action'
import { UseAlert } from '../commonComponent/commonComponent'
import RenameModal from '../Modal/RenameModal'
import { ButtonLoader } from '../../Loader/ButtonLoader'
import { toast } from '../Toast/Toast'

export default function OptionMenu({ allLyrics, setAllLyrics }) {
  const { ai_generated } = useSelector(state => state.assistantTab)

  const searchParams = new URLSearchParams(window.location.search)
  // const newIdeas = searchParams.get('new_ideas')
  const newIdeas = sessionStorage.getItem('new_ideas')

  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const [renameModal, setRenameModal] = useState(false)

  const [btnLyricLoader, setBtnLyricLoader] = useState(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSaveLyrics = async () => {
    try {
      const { inputs } = ai_generated

      let body = {
        theme: inputs?.theme,
        genre: inputs?.genre,
        style: inputs?.style,
        verses: inputs?.verses,
        typeOfIdeas: inputs?.typeOfIdeas,
        pre_chorus: inputs?.pre_chorus,
        chorus: inputs?.chorus,
        bridge: inputs?.bridge,
        outro: inputs?.outro,
        lang: inputs?.lang || 'English',
        aiAssistantLyricsQuestion: inputs?.theme,
        aiAssistantLyricsContent: ai_generated?.content
      }
      let url = Api.saveLyric
      if (type === LeftRightPaneFolderTypes.NOTES || newIdeas) {
        body = {
          typeOfIdeas: inputs?.typeOfIdeas,
          isTitle: true,
          theme: inputs?.theme,
          genre: inputs?.genre,
          lang: inputs?.lang || 'English',
          aiAssistantLyricsQuestion: 'Song Ideas',
          aiAssistantLyricsContent: ai_generated?.content
        }
      }

      setBtnLyricLoader(true)
      const { data } = await postMethod(token, url, body)

      if (data?.message) {
        toast.success(data?.message)
        setAllLyrics(pre => [...pre, data?.data])

        dispatch(
          assistantFooterToggleAction(true, {
            ...data?.data,
            content: data?.data?.aiAssistantLyricsContent
          })
        )
      }
      if (type === LeftRightPaneFolderTypes.NOTES || newIdeas) {
        setRenameModal(true)
      }
      if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        await handleFolderLyrics()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
    handleClose()
    setBtnLyricLoader(false)
  }

  const handleFolderLyrics = async () => {
    dispatch(loaderAction(false))
    const { data } = await getMethod(token, Api.getAllAILyricsWithOutFilter)
    dispatch(
      allSongAction(
        data?.lyrics,
        'AI Generated',
        '',
        LeftRightPaneFolderTypes.AI_ASSISTANT
      )
    )
    dispatch(loaderAction(false))
  }

  const handleDeleteLyrics = async () => {
    try {
      const body = {
        lyricsIds: [ai_generated?._id]
      }
      const { data } = await deleteMethod(token, Api.deleteLyrics, body)
      dispatch(assistantFooterDeleteAction(true))
      toast.success(data?.message)

      if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        await handleFolderLyrics()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
    setIsOpen(false)
  }

  const isDisabled = React.useMemo(
    () => (ai_generated?._id ? '' : 'disabled auto'),
    [ai_generated?._id]
  )

  return (
    <div className="ai-options">
      <RenameModal
        openModal={renameModal}
        setOpenModal={setRenameModal}
        allLyrics={allLyrics}
        setAllLyrics={setAllLyrics}
      />

      <Tooltip title="Save" arrow>
        <img
          className={ai_generated?._id ? 'disabled auto' : ''}
          src="assets/images/bookmark-accor.svg"
          alt=""
          onClick={() =>
            !allLyrics.includes(ai_generated?._id) && handleSaveLyrics()
          }
          disabled={allLyrics.includes(ai_generated?._id) ? true : false}
        />
      </Tooltip>
      {btnLyricLoader && <ButtonLoader />}
      <Tooltip title="Rename" arrow>
        <img
          className={isDisabled}
          src="assets/images/remane-icon-accor.svg"
          alt=""
          onClick={() => {
            setRenameModal(true)
          }}
          disableRipple
        />
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <img
          className={`m-0 ${isDisabled}`}
          src="assets/images/delete-icon-accor.svg"
          alt=""
          onClick={() => setIsOpen(true)}
          disableRipple
        />
      </Tooltip>

      <UseAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmDelete={handleDeleteLyrics}
        message={`lyrics`}
      />
    </div>
  )
}
