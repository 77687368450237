import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useSelector } from 'react-redux'
import { ButtonLoader } from '../../../Loader/ButtonLoader'

export default function MoveDropdown({ handleMove }) {
  const { folders } = useSelector(state => state.folders)
  const { selectedFolders } = useSelector(state => state.selectedFolders)

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <button className="move-to-icon" {...bindTrigger(popupState)}>
            Move To
          </button>
          <Menu {...bindMenu(popupState)} className="move-option-wrapper">
            {folders.map((folder, i) => (
              <MenuItem>
                {selectedFolders?.includes(folder?._id) && <ButtonLoader />}

                <input
                  type="checkbox"
                  checked={selectedFolders?.includes(folder?._id)}
                  onClick={() => {
                    handleMove(folder?._id)
                  }}
                />

                <span className="ps-2">{folder?.folderName}</span>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
