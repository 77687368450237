import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { constants } from '../../services/constants'
import { logoutAction } from '../../services/actions/auth.action'
import { windowFeatures } from '../../utils/commonOptions'
const ProfileFooter = () => {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const [plan, setPlan] = useState('Free Limited')

  const planNames = {
    free: 'Free Limited',
    premium: 'Premium',
    expired: 'Expired',
    cancel: 'Cancel'
  }

  useEffect(() => {
    const decoded = jwt_decode(token)

    const a = moment().format('YYYY-MM-DD')
    if (decoded?.createdAt) {
      let b = moment(`${decoded?.createdAt}`).format('YYYY-MM-DD')
      var date1 = moment(`${a}`)
      var date2 = moment(`${b}`)
      var days = date1.diff(date2, 'days')

      if (days >= 7) {
        if (
          decoded?._doc?.planType === 'free' ||
          decoded?._doc?.planType === 'expired' ||
          decoded?.planType === 'free' ||
          decoded?.planType === 'expired' ||
          decoded?._doc?.planType === 'cancel' ||
          decoded?.planType === 'cancel'
        ) {
          dispatch({
            type: constants.DISABLE_ACCESS,
            payload: { disabled: true }
          })
        } else {
          dispatch({
            type: constants.DISABLE_ACCESS,
            payload: { disabled: false }
          })
        }
      }
    }
    let planName = decoded?._doc?.pro?.planName || decoded?.pro?.planName
    setPlan(planName === 'free' ? 'Free Limited' : planName)
  }, [])

  const handleOpen = e => {
    e.preventDefault()
    const handle = window.open('/profile', 'Price Page', windowFeatures)
    if (!handle) {
      window.alert(
        "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
      )
    }
  }

  return (
    <div
      className="profile-sidebar d-flex"
      // onClick={() => dispatch(logoutAction())}
    >
      {/* {token} */}
      <div className="pro-img pl-2 mr-3" style={{ cursor: 'pointer' }}>
        <a href="#/">
          <img
            src="assets/images/my-account-icon-new.svg"
            className="pro-img"
            alt="logout"
            width="24px"
          />
        </a>
      </div>
      <div className="profile-text text-right">
        <a href="#/" onClick={handleOpen}>
          My Account
        </a>
      </div>

      {/* <div className="logout-btn">
        <p className="free">{plan}</p>
        <img
          src="assets/images/Flag.svg"
          className="pro-img"
          alt="logout"
          width="93px"
        />
      </div> */}
      <div className="logout-new" onClick={() => dispatch(logoutAction())}>
        <img
          src="assets/images/logout-new-icon.svg"
          className="pro-img"
          alt="logout"
          width="24px"
        />

        <a href="#/">Log Out</a>
      </div>
    </div>
  )
}

export default ProfileFooter
