import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lookUpAction } from '../../../services/actions/idea/idea.action'
import { RhymesSearchInput } from '../../commonComponent/tabs/TabSearchInput'

const Lookup = () => {
  const { boolean } = useSelector(state => state.lookUpToggle)

  const dispatch = useDispatch()
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12 text-center">
          <div className="Generator-content ">
            <p className="mb-0">Lookup Type</p>
            <span
              className={`Generator-tab ${!boolean && 'active'}`}
              onClick={() => dispatch(lookUpAction(false))}
            >
              Dictionary
            </span>
            <span>|</span>
            <span
              className={`Generator-tab ${boolean && 'active'}`}
              onClick={() => dispatch(lookUpAction(true))}
            >
              Thesaurus
            </span>
            <div className="search-bar-right-bar">
              <RhymesSearchInput type="lookup" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Lookup
