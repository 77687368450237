import React, { useState } from 'react'
import { UploadBackTrack } from './Upload'
import VolumeBar from './../../Player/VolumeBar'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'

import {
  backTrackAction,
  backTrackPlayPauseAction,
  backTrackSelectAction
} from '../../../services/actions/mediaPlayerActions/backTrack.action'
import { loaderAction } from '../../../services/actions/loader.action'
import BackTrack from './BackTrack'
import { deleteMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { UseAlert } from '../../commonComponent/commonComponent'
import { useAlert } from 'react-alert'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { playerConstants } from '../../../services/constants'
import { playPauseAction } from '../../../services/actions/mediaPlayerActions/mediaPlayer.action'
import { LeftRightPaneFolderTypes } from '../../../utils/commonOptions'
import { toast } from '../../Toast/Toast'

const BackingTab = () => {
  const { allBackTracks } = useSelector(state => state.allBackTracks)
  const { token } = useSelector(state => state.auth)

  const { singleSong } = useSelector(state => state.singleSong)
  const { type } = useSelector(state => state.songs)
  const { assistantTab } = useSelector(state => state.assistantTab)

  const { backTrackActiveSong, backTrackIsPlaying } = useSelector(
    state => state.backTrackState
  )
  const { playerVolume } = useSelector(state => state.playPause)

  const { isBlockRecording } = useSelector(state => state.isBlockRecording)

  const [loaderId, setLoaderId] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectSongs, setSelectSongs] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const dispatch = useDispatch()

  const handlePlayPause = track => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }

    // Pause current playing track and clear active song state
    dispatch(playPauseAction(false, null))

    if (backTrackActiveSong?._id === track._id) {
      // If the clicked track is the same as the active back track, toggle its play/pause state
      dispatch(backTrackPlayPauseAction(!backTrackIsPlaying, track))
      dispatch(backTrackSelectAction(!backTrackIsPlaying, track))
    } else {
      // If a different track is clicked, play the new track and select it
      dispatch(backTrackPlayPauseAction(false, null))
      dispatch(backTrackSelectAction(false, null))
      setTimeout(() => {
        dispatch(backTrackPlayPauseAction(true, track))
        dispatch(backTrackSelectAction(true, track))
      }, 100)
    }
  }

  const handleSongSelect = songItem => {
    if (selectSongs.includes(songItem?._id)) {
      let temp = selectSongs?.filter(item => item !== songItem?._id)
      setSelectSongs(temp)
    } else {
      setSelectSongs(pre => [...pre, songItem?._id])
    }
  }

  const confirmDeleteSongs = async () => {
    dispatch(loaderAction(true))
    const filteredValidItems = allBackTracks.filter(item => {
      return selectSongs.includes(item?._id) && item
    })
    const filteredBackTracks = allBackTracks.filter(item => {
      return !selectSongs.includes(item?._id) && item
    })

    const filteredItems = filteredValidItems.map(item => {
      return {
        fileName: item.keyName,
        backTrackId: item?._id
      }
    })

    const body = {
      backTracks: filteredItems
    }

    try {
      const { data, error } = await deleteMethod(
        token,
        Api.deleteBackTrack,
        body
      )

      if (!error) {
        toast.success(data?.message)
        dispatch(backTrackAction(filteredBackTracks))
        setSelectSongs([])
      }
    } catch (error) {
      console.log(error, 'recordings song deleted error')
    }
    dispatch(loaderAction(false))

    setIsOpen(false)
  }

  const handleComplete = (data, url, name) => {
    saveAs(url, name)
    setTimeout(() => {
      let temp = loaderId?.filter(item => item !== name)
      setLoaderId(temp)
    }, 3000)
  }

  const handleParse = async (url, name) => {
    // setLoaderId(true)
    setLoaderId(pre => [...pre, name])

    Papa.parse(url, {
      download: true,
      complete: ({ data }) => handleComplete(data, url, name)
    })
  }

  const handleExport = () => {
    if (selectSongs?.length > 0) {
      for (const item of allBackTracks) {
        for (const id of selectSongs) {
          if (item?._id === id) {
            let url = `${Api.getFileInfo}/${item?._id}`

            handleParse(url, item?.title)
          }
        }
      }
    }
  }

  const handleVolume = event => {
    dispatch({
      type: playerConstants.PLAYER_VOLUME,
      payload: { playerVolume: event.target.value }
    })
  }

  return (
    <>
      <UseAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmDelete={confirmDeleteSongs}
      />
      <section className="outer-bg-volume">
        <div className="container">
          <div className="row">
            <div className="col-3 m-0 p-0 ">
              <div className="edit-icon-right-bar">
                <Tooltip title="Edit Music" arrow>
                  <img
                    className="cursor"
                    src="assets/images/edit-music-icon-new.svg"
                    alt=""
                    width={24}
                    onClick={() => setIsEdit(!isEdit)}
                  />
                </Tooltip>
                {/*  */}
                <UploadBackTrack />
              </div>
            </div>
            <div className="col-7 position-relative right-20">
              <div className="sound-volume recording-tab">
                <VolumeBar
                  value={playerVolume}
                  min="0"
                  max="1"
                  onChange={event => handleVolume(event)}
                  className="backTrackVolume"
                />
              </div>
            </div>

            <div
              className="col-2 p-0 text-center d-flex position-relative right-15"
              style={{ marginLeft: '' }}
            >
              <div className="share-and-delete d-flex mt-0">
                <Tooltip title="Export" arrow>
                  <img
                    onClick={() => handleExport()}
                    className="cursor"
                    src="assets/images/share-song-new.svg"
                    alt=""
                    width={26}
                  />
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <img
                    onClick={() => {
                      selectSongs.length > 0 && setIsOpen(true)
                    }}
                    className="ml-3 cursor"
                    src="assets/images/trash-song-new.svg"
                    alt=""
                    width={23}
                  />
                </Tooltip>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="custom-table-right-bar tableWrap">
              <div className="table">
                {' '}
                <div className="recording-header">
                  <p>Title & Time</p>
                  {/* <th scope="col">Time</th> */}
                  <p>Date</p>
                  {/* {isDeleteActive ||
                      (exportBtnActive && <th scope="col">Action</th>)} */}
                </div>
                <div
                  className={`rec_list ${
                    singleSong &&
                    !assistantTab &&
                    type !== LeftRightPaneFolderTypes.NOTES
                      ? ''
                      : 'hide_footer'
                  }`}
                >
                  {allBackTracks?.length === 0 && (
                    <div className="empty-song-list">No backtrack found</div>
                  )}
                  {allBackTracks?.map((track, index) => (
                    <BackTrack
                      key={track._id}
                      backTrackIsPlaying={backTrackIsPlaying}
                      track={track}
                      handlePlayPause={handlePlayPause}
                      backTrackActiveSong={backTrackActiveSong}
                      loaderId={loaderId}
                      handleSongSelect={handleSongSelect}
                      selectSongs={selectSongs}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      allBackTracks={allBackTracks}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Player configuration */}
      </div>
    </>
  )
}

export default BackingTab
