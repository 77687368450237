import React from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Ideas from './Ideas'
import Rhymes from './Rhymes'
import Lookup from './Lookup'
import Favs from './Favs'
import { a11yProps, TabPanel } from '../../../utils/Tabs'
import IdeasTags from './IdeasTags'
import RhymesTags from './RhymesTags'
import LookupContent from './LookupContent'
import FavsTags from './FavsTags'

const IdeaPage = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <div className="gray-section-right-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="right-side-tab mt-2">
                <Box sx={{ width: '100%' }}>
                  <Box className="tabs-outer-bg">
                    <Tabs
                      className="round-blue"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        className="right-tab-custom"
                        label="Ideas"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Rhymes"
                        {...a11yProps(1)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Lookup"
                        {...a11yProps(2)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Favs"
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Ideas />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Rhymes />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Lookup />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Favs />
                  </TabPanel>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TabPanel className="" value={value} index={0}>
        <IdeasTags />
      </TabPanel>
      <TabPanel className="" value={value} index={1}>
        <RhymesTags />
      </TabPanel>
      <TabPanel className="" value={value} index={2}>
        <LookupContent />
      </TabPanel>
      <TabPanel className="" value={value} index={3}>
        <FavsTags />
      </TabPanel>
    </div>
  )
}

export default IdeaPage
