import React from 'react'

const BackHeader = () => {
  return (
    <div className="row">
    <div className="col-lg-12 text-left ps-3">
      <div className="heading-title mt-2 position-relative">
        <img
          onClick={() => window.history.length>1?window.history.back():window.location.assign('/profile')}
          className="cursor"
          src="assets/images/left_arrow.svg"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
  )
}

export default BackHeader