import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import './loader.css'
import { useSelector } from 'react-redux'

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer value">1</div>
  }

  return (
    <div className="timer">
      <div className="value">{remainingTime || 0}</div>
    </div>
  )
}

function CountDown() {
  const { countDown } = useSelector(state => state.loader)

  return (
    <div className="custom_loader">
      <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          duration={countDown ? 3 : 1}
          colors={['#1a70b4', '#1a70b4', '#1a70b4', '#1a70b4']}
          colorsTime={[3, 2, 1, 0]}
          onComplete={() => ({ shouldRepeat: false, delay: 1 })}
        >
          {renderTime || 0}
        </CountdownCircleTimer>
      </div>
    </div>
  )
}

export { CountDown }
