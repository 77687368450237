import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { positions, transitions, Provider as AlertProvider } from 'react-alert'
import App from './App'
import AlertTemplate from 'react-alert-template-basic'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import store from './services/store/store'

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  transition: transitions.SCALE,
  // background: 'red'
}
window.store = store

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options} className="toast_ui">
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
)
