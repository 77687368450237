import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

const CommonModal = ({ isOpen, setIsOpen, options }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="custom_modal">
          {options?.heading || ''}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {options?.message || ''}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CommonModal
