import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'

import { closeNotebookToggleAction } from '../../services/actions/toggle.action'
import { Form } from 'react-bootstrap'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import { postMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import { allSongAction } from '../../services/actions/song.action'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { constants } from '../../services/constants'
import { toast } from '../Toast/Toast'

export default function NotebookModal({
  showModal,
  handleSubmitNote,
  setShowModal,
  getAllNotebooks
}) {
  const { notebookToggle } = useSelector(state => state.notebook)
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { songs } = useSelector(state => state.songs)

  const { token } = useSelector(state => state.auth)
  const [error, setError] = React.useState(false)
  const dispatch = useDispatch()
  const [body, setBody] = React.useState({
    notebookHeading: '',
    notebookContent: ''
  })

  const handleInput = e => {
    const { name, value } = e.target
    const data = { ...body }
    data[name] = value
    setBody(data)
  }

  const handleSubmit = async () => {
    if (!body.notebookHeading) {
      setError('Please enter name ')
      return
    }

    postDispatch({ type: ACTION_TYPES.FETCH_START })

    const { data, error } = await postMethod(token, Api.createNotebook, body)
    toastMsg(data, error)
  }

  const toastMsg = async (data, error) => {
    if (!error) {
      toast.success(data?.message)

      if (showModal) {
        handleSubmitNote(data?.data)
      } else {
        let temp = [...songs]

        temp.unshift(data?.data)
        data['isNotebook'] = true

        dispatch({
          type: constants.SINGLE_SONG,
          payload: { song: null, singleNotebook: data?.data }
        })

        if (getAllNotebooks) {
          await getAllNotebooks(true)
          setTimeout(() => {
            dispatch({
              type: constants.SINGLE_SONG,
              payload: { song: null, singleNotebook: data?.data }
            })
          }, 100)
        } else {
          dispatch(
            allSongAction([], 'Notebook', '', LeftRightPaneFolderTypes.NOTES)
          )
        }

        dispatch(closeNotebookToggleAction())
      }

      setError(false)
      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data?.data }
      })
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  return (
    <div>
      <Dialog open={showModal ? showModal : notebookToggle}>
        <DialogTitle className="custom_modal">Create Notebook</DialogTitle>

        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => {
            setError(false)
            setShowModal && setShowModal(false)
            dispatch(closeNotebookToggleAction())
          }}
        />
        <DialogContent className="dialog_content">
          {error && (
            <Alert severity="error" className="alert_modal_error">
              {error}
            </Alert>
          )}

          <TextField
            margin="dense"
            name="notebookHeading"
            required
            id="name"
            label={`Enter title `}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleInput}
            InputLabelProps={{ className: 'text_label' }}
          />

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{`Enter description`}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="notebookContent"
              onChange={handleInput}
            />
          </Form.Group>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setError(false)
              setShowModal && setShowModal(false)
              dispatch(closeNotebookToggleAction())
            }}
            className="cancelBtn"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={state?.loading}
            className={state?.loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={() => handleSubmit()}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
