/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import BackHeader from '../components/BackHeader'
import { useSelector } from 'react-redux'
import { toast } from '../components/Toast/Toast'
import { getMethod } from '../helper/ApiMethods'
import { Api } from '../helper/ApiList'
import { useDispatch } from 'react-redux'
import { constants } from '../services/constants'

const ReferralBalance = () => {
  const { user, token } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  let credits = {
    earn_credits: user?.earn_credits || user?.user?.earn_credits || 0,
    potential_earn_credits:
      user?.potential_earn_credits ||
      user?.user?.potential_earn_credits ||
      25000
  }

  let link = user?.link || user?.user?.link
  const generateRefedLink = async () => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(res => toast.success('Referral Link Copied'))
        .catch(err => console.log(err, 'Error while copy text'))
      return
    } else {
      try {
        const { data } = await getMethod(token, Api.generateLink)
        navigator.clipboard
          .writeText(data?.link)
          .then(res => toast.success('Referral Link Copied'))
          .catch(err => console.log(err, 'Error while copy text'))
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message)
      }
    }
  }

  useEffect(() => {
    if (token) {
      const userInfo = async () => {
        const { data } = await getMethod(token, Api.userInfo)

        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: data?.accessToken || token,
            email: data.email,
            plan: data.planType,
            user: data
          }
        })
      }

      !user?.user && userInfo()
    }
  }, [user])

  return (
    <div className="referral_balance">
      <BackHeader />
      <div className="row">
        <div className="col-12">
          <div className="content-mid">
            <div className="referral-mid">
              <div className="">
                <h2 className="mt-4">Earnings</h2>
              </div>
              <div className="baln-page-custom">
                <h4>{credits?.earn_credits?.toLocaleString('en-US') || 0}</h4>
                <p className="m-0">Credits received</p>
              </div>
              <div className="baln-page-custom">
                <h4>
                  {credits?.potential_earn_credits?.toLocaleString('en-US')}
                </h4>
                <p className="m-0">Potential credit earnings</p>
              </div>
              <div className="">
                <h2 className="mt-4">How referrals work</h2>
              </div>
              <div className="baln-page-custom mt-2 border-0">
                <h5>1. Refer friends</h5>
                <p className="m-0">
                  Share your referral link with others{' '}
                  <span
                    className="fs-14 cursor link"
                    onClick={() => generateRefedLink()}
                  >
                    Copy your link
                  </span>
                </p>
              </div>
              <div className="baln-page-custom mt-2 border-0">
                <h5>2. Get credits</h5>
                <p className="m-0">
                  When your friend signs up, you'll get credits after they write
                  their first song.
                </p>
              </div>
              <div className="baln-page-custom mt-2 border-0">
                <h5>3. Write better songs, faster</h5>
                <p className="m-0">
                  Use your extra credits to generate even more ideas for endless
                  inspiration.
                </p>
              </div>
              <div className="text-center mt-3">
                <a
                  href="https://songwriterspad.com/faqs"
                  target="_blank"
                  rel="noreferrer"
                  className="red-terms-req fs-13"
                >
                  Read the terms and program requirements
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferralBalance
