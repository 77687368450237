import React from 'react'
import Alert from '@mui/material/Alert'
import toaster from 'react-hot-toast'
import {
  ErrorIcon,
  SuccessIcon,
  ToastCloseIcon,
  WarningIcon
} from '../../helper/icons'

const toast = {
  success: message =>
    toaster.custom(t => <SuccessToast message={message} t={t} />),
  error: message => toaster.custom(t => <ErrorToast message={message} t={t} />),

  info: message => toaster.custom(t => <WarningToast message={message} t={t} />)
}

const SuccessToast = ({ message, t }) => {
  return (
    <Alert severity="success" className="success_toast" icon={<SuccessIcon />}>
      {message}{' '}
      <span onClick={() => toaster.dismiss(t.id)}>
        <ToastCloseIcon />
      </span>
    </Alert>
  )
}

const ErrorToast = ({ message, t }) => {
  return (
    <Alert
      severity="error"
      className="error_toast"
      icon={<ErrorIcon />}
      closeText="close"
    >
      {message}{' '}
      <span onClick={() => toaster.dismiss(t.id)}>
        <ToastCloseIcon />
      </span>
    </Alert>
  )
}

const WarningToast = ({ message, t }) => {
  return (
    <Alert severity="warning" className="warning_toast" icon={<WarningIcon />}>
      {message}{' '}
      <span onClick={() => toaster.dismiss(t.id)}>
        <ToastCloseIcon />
      </span>
    </Alert>
  )
}

const InfoToast = ({ message, t }) => {
  return (
    <Alert severity="info" icon={<SuccessIcon />}>
      {message}
      <span onClick={() => toaster.dismiss(t.id)}>
        <ToastCloseIcon />
      </span>
    </Alert>
  )
}

export { SuccessToast, ErrorToast, WarningToast, InfoToast, toast }
