import React, { useState } from 'react'
import SearchInput from './SearchInput'
import { SongItemComponents } from './common/Song'
import { selectButtonToggleHideAction } from '../../services/actions/toggle.action'
import { useDispatch, useSelector } from 'react-redux'
import { loaderAction } from '../../services/actions/loader.action'
import { deleteMethod, getMethod, putMethod } from '../../helper/ApiMethods'
import {
  allSongAction,
  recentSongAction,
  songAction
} from '../../services/actions/song.action'
import {
  allFolderAction,
  selectFolderAction
} from '../../services/actions/folder.action'
import { Api } from '../../helper/ApiList'
import { UseAlert } from '../commonComponent/commonComponent'
import { constants } from '../../services/constants/index'
import { getRecordingAction } from '../../services/actions/mediaPlayerActions/mediaPlayer.action'
import { LeftRightPaneFolderTypes, hideTabs } from '../../utils/commonOptions'
import MoveDropdown from './common/MoveDropdown'
import { toast } from '../Toast/Toast'
import FilterDropdown from '../commonComponent/FilterDropdown'

const RightASide = () => {
  const dispatch = useDispatch()
  const { selectBtnToggle } = useSelector(state => state.selectBtnToggle)
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)

  const [selectSongs, setSelectSongs] = useState([])
  const [selectAction, setSelectAction] = useState(false)
  const { songs, title, folderId } = useSelector(state => state.songs)
  const { singleSong } = useSelector(state => state.singleSong)
  const { recentSongs } = useSelector(state => state.recentSongs)
  const [isOpen, setIsOpen] = useState(false)

  const handleRefresh = async () => {
    dispatch(loaderAction(true))

    if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
      const { data } = await getMethod(token, Api.getAllAILyricsWithOutFilter)
      dispatch(
        allSongAction(
          data?.lyrics,
          'AI Generated',
          '',
          LeftRightPaneFolderTypes.AI_ASSISTANT
        )
      )
      dispatch(loaderAction(false))

      return
    }

    if (type === LeftRightPaneFolderTypes.NOTES) {
      const { data } = await getMethod(token, Api.getNotebooks)

      dispatch(
        allSongAction(data, 'Notebook', '', LeftRightPaneFolderTypes.NOTES)
      )
      dispatch(loaderAction(false))

      return
    }
    const allSongs = await getMethod(token, Api.getSongs)
    const allFolders = await getMethod(token, Api.getFolders)

    // Sort the array based on the 'updated_at' key in descending order
    const sortedArray =
      allSongs?.data?.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      ) || []

    // Get the last three objects
    const lastThreeObjects = sortedArray?.slice(0, 3)
    dispatch(allSongAction(allSongs?.data))
    dispatch(allFolderAction(allFolders?.data))
    dispatch(recentSongAction(lastThreeObjects))

    await getLastVisitSong(lastThreeObjects)

    dispatch(loaderAction(false))
    if (singleSong?._id) {
      await handleRecordings(singleSong?._id)
    }
  }

  React.useEffect(() => {
    handleRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const selectToggle = () => {
    setSelectSongs([])
    dispatch(selectButtonToggleHideAction())
    setSelectAction(false)
  }

  const handleSelect = data => {
    if (selectSongs.includes(data?._id)) {
      let temp = selectSongs.filter(item => item !== data?._id)
      setSelectSongs(temp)
    } else {
      setSelectSongs(pre => [...pre, data?._id])
    }
  }

  const handleSelectAll = () => {
    if (selectAction) {
      setSelectAction(false)
      setSelectSongs([])
    } else {
      setSelectAction(true)
      let temp = songs?.map(item => item?._id)
      setSelectSongs(temp)
    }
  }

  const handleMove = async folderId => {
    if (!folderId || selectSongs.length === 0) {
      toast.info(
        `Please select ${selectSongs.length === 0 ? 'Songs' : 'Folders'}`
      )
      return
    }
    let body = {
      folders: [folderId],
      songs: selectSongs
    }

    dispatch(selectFolderAction(folderId))

    // dispatch(loaderAction(true))
    const { data, error } = await putMethod(
      token,
      Api.moveSongsUnderFolder,
      body
    )

    !error && toast.success(data?.message)
    error && toast.error(error)
    setSelectAction(false)
    setSelectSongs([])
    selectToggle()
    dispatch(selectFolderAction(folderId))
  }

  const handleDelete = async () => {
    if (selectSongs.length === 0) {
      setIsOpen(false)
      toast.info(`Please select Songs`)
      return
    }
    let body = {
      songId: selectSongs
    }
    dispatch(loaderAction(true))

    if (folderId) {
      await handleFolderSongDelete(body)
      return
    }

    let api = Api.deleteMultipleSong

    if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
      api = Api.deleteLyrics
      body = {
        lyricsIds: selectSongs
      }
    }
    if (type === LeftRightPaneFolderTypes.NOTES) {
      api = Api.deleteNotebook
      body = {
        notebookIds: selectSongs
      }
    }

    const { data, error } = await deleteMethod(token, api, body)

    !error && toast.success(data?.message)

    if (!error) {
      let arr = songs?.filter(item => !selectSongs.includes(item._id))
      let recent = recentSongs?.filter(item => !selectSongs.includes(item._id))

      !type && dispatch(recentSongAction(recent))

      dispatch(allSongAction(arr, title, folderId, type))
      if (selectSongs.includes(singleSong?._id)) {
        dispatch({ type: constants.RESET_SINGLE_SONG_DATA })
      }
    }
    error && toast.error(error)

    dispatch(loaderAction(false))
    selectToggle()
    setIsOpen(false)
  }

  const handleFolderSongDelete = async body => {
    const { data, error } = await deleteMethod(
      token,
      Api.deleteSongsUnderFolder + folderId,
      body
    )

    !error && toast.success(data?.message)

    if (!error) {
      let arr = songs?.filter(item => !selectSongs.includes(item._id))
      dispatch(allSongAction(arr, title, folderId))
      if (selectSongs.includes(singleSong?._id)) {
        dispatch({ type: constants.RESET_SINGLE_SONG_DATA })
      }
    }
    error && toast.error(error)

    dispatch(loaderAction(false))
    selectToggle()
    setIsOpen(false)
  }

  const getLastVisitSong = async data => {
    if (data?.length > 0) {
      let lastElem = data?.length > 0 && data[0]
      if (!lastElem) return
      try {
        const songBlock = await getMethod(
          token,
          Api.getSongBlock + lastElem?._id
        )
        if (!songBlock?.error) dispatch(songAction(lastElem, songBlock?.data))
      } catch (error) {}
      await handleRecordings(lastElem?._id)
    }
  }

  const handleRecordings = async id => {
    dispatch(loaderAction(true))

    let url = Api.getRecordings + id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))
    dispatch(loaderAction(false))
  }

  return (
    <div className="searchInput">
      <div className="input-custom d-flex">
        <SearchInput />
      </div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <div className="script-name d-flex">
              <h6>{title}</h6>
              <img
                className="loader-img"
                src="assets/images/rotate-left-icon-new.svg"
                alt=""
                onClick={() => handleRefresh()}
              />
            </div>
          </div>
          <div className="col-6 text-end select-btn">
            {songs?.length !== 0 && (
              <>
                <span
                  onClick={() => {
                    selectToggle()
                  }}
                >
                  {selectBtnToggle
                    ? 'Cancel'
                    : (songs?.length !== 0 && 'Select') || 'Select'}
                </span>

                <FilterDropdown />
              </>
            )}
          </div>
        </div>

        {selectBtnToggle && (
          <div className="row mb-2 mt-2">
            <div className="col-12">
              <div className="d-flex gap-2">
                <button
                  className="select-all-icon"
                  onClick={() => handleSelectAll()}
                >
                  {!selectAction ? 'Select All' : 'Un Select'}
                </button>
                {!type && <MoveDropdown handleMove={handleMove} />}

                <button
                  className="delete-icon"
                  onClick={() =>
                    selectSongs?.length === 0 ? handleDelete() : setIsOpen(true)
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="all-song-list-new mt-1">
          <div className="head-all-song-list">
            <div className="row">
              <div className="col-7">
                <p className="m-0">Title</p>
              </div>
              <div className="col-5">
                <p className="m-0 text-center">Date</p>
              </div>
            </div>
          </div>
          <div className={`${selectBtnToggle ? '' : 'full'} song_list_wrapper`}>
            {songs?.length === 0 && (
              <div className="empty-song-list">No records found</div>
            )}

            {songs?.map((song, index) => (
              <SongItemComponents
                key={index}
                song={song}
                checked={selectSongs.includes(song?._id)}
                handleSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      </div>

      <div
        className={`song-card-bottom ${hideTabs?.includes(type) && 'd-none'}`}
      ></div>
      <UseAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmDelete={handleDelete}
      />
    </div>
  )
}

export default RightASide
