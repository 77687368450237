import { CircularProgress } from '@mui/material';
import React from 'react'

const ButtonLoader = ({classes}) => {
  return (
    
        <CircularProgress
        className={classes||""}
          size={24}
          sx={{
            color: '#1976d2',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
     
  )
}

const NoInternetSVG = () => (
 <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#1d8bdf" // Change the stroke color here
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-wifi-off"
    style={{
      width: '100%',
      height: '100%',
      maxWidth: '100px',
      maxHeight: '100px',
      animation: 'spin 2s linear infinite',
    }}
  >
    <path d="M1 1l22 22m-2-2H1v-2h8.2a5.999 5.999 0 0 0-1.116-2.8M12 12l4-4m4 4l-4 4" />
  </svg>
);

const NoInternetConnection = () => (
  <div className="no-internet">
  <div className="no-internet-inner">
    <NoInternetSVG />
    <p>Oops! No internet connection.</p>
  </div>
</div>

);


export {ButtonLoader,NoInternetConnection}