import { constants } from '../constants'
import store from '../store/store'

const selectFolderAction = folder => async dispatch => {
  let folders = store.getState().selectedFolders.selectedFolders

  if (folders.includes(folder)) {
    let temp = folders.filter(item => item !== folder)
    return dispatch({
      type: constants.SELECT_FOLDERS_DATA,
      payload: { folders: temp }
    })
  } else {
    let temp = [...folders]
    temp.push(folder)

    return dispatch({
      type: constants.SELECT_FOLDERS_DATA,
      payload: { folders: temp }
    })
  }
}

const allFolderAction = data => async dispatch => {
  return dispatch({
    type: constants.ALL_FOLDERS,
    payload: { folders: data }
  })
}

export { selectFolderAction, allFolderAction }
