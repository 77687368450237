import React, { useState } from 'react'

import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import {
  getDictionary,
  getRhymes,
  getThesaurus
} from '../../../utils/dictionary'
import { ideaSearchDataAction } from '../../../services/actions/idea/idea.action'
import { useDispatch } from 'react-redux'
import { loaderAction } from './../../../services/actions/loader.action'
import { useSelector } from 'react-redux'
import { get_random_words } from '../../../utils/commonFunctions'

const RhymesSearchInput = ({ type, initial }) => {
  const { keyword } = useSelector(state => state.ideaSearchData)

  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const handleSearch = e => {
    e.preventDefault()
    if (e.keyCode == 13 || e.key === 'Enter') {
      const { value } = e.target
      setValue(value.trim())
      return false
    }
    // e.preventDefault()
    const { value } = e.target
    setValue(value.trim())

    if (!value.trim()) {
      const result = {
        rhymesData: [],
        dictionaryData: [],
        thesaurusData: []
      }

      dispatch(ideaSearchDataAction(value, result))
    }
  }

  const handleReset = () => {
    setValue('')
  }

  const handleFetch = async () => {
    const result = {
      rhymesData: [],
      dictionaryData: [],
      thesaurusData: []
    }
    try {
      dispatch(loaderAction(true))
      const rhymes = await getRhymes(value)

      const randomRhymes = rhymes ? get_random_words(rhymes) : []

      result['rhymesData'] = randomRhymes
    } catch (error) {
      dispatch(loaderAction(false))
    }

    // const { data, error } = await getRhymes(value)

    const dictionary = await getDictionary(value)
    const thesaures = await getThesaurus(value)
    // const { data, error } = get_random_words(rhymes)

    result['dictionaryData'] = dictionary?.data
    result['thesaurusData'] = thesaures?.data

    dispatch(ideaSearchDataAction(value, result))
    dispatch(loaderAction(false))

    dispatch(ideaSearchDataAction(value, result))
  }
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value) {
        // Send Axios request here
        value && handleFetch()
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [value])

  return (
    <>
      <div className="custom-search">
        <Paper
          component="div"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 399
          }}
        >
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          {type === 'rhymes' ? (
            <input
              className="search-input"
              placeholder="Search..."
              onChange={e => handleSearch(e)}
              defaultValue={value || keyword}
            />
          ) : (
            <input
              className="search-input"
              placeholder="Search..."
              onChange={e => handleSearch(e)}
              defaultValue={value || keyword}
            />
          )}

          {value && (
            <CloseIcon
              onClick={() => handleReset()}
              style={{
                width: '0.8em',
                paddingRight: '5px',
                cursor: 'pointer'
              }}
            />
          )}
        </Paper>
      </div>
    </>
  )
}

export { RhymesSearchInput }
