import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAlert } from 'react-alert'
import { initialState, postReducer } from '../hooks/useApiReducer'
import { ACTION_TYPES } from '../hooks/customReducerActionTypes'
import { Api } from './../helper/ApiList'
import axios from 'axios'

import { Link } from 'react-router-dom'
import { useState } from 'react'
import JsonAnimation from '../Loader/JsonAnimation'
import { toast } from '../components/Toast/Toast'

const theme = createTheme()

export default function ForgetPassword() {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const [msg, setMsg] = useState({
    isError: false,
    message: ''
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const form = new FormData(event.currentTarget)
    const body = {
      email: form.get('email')
    }
    if (!body.email) {
      toast.error('All fields are required')
      return false
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    try {
      const cancelToken = axios.CancelToken.source()
      const { data } = await axios.put(Api.forgetPassword, body, {
        cancelToken: cancelToken.token
      })
      if (data.statusCode === 200) {
        toast.success(data.message)

        setMsg({ isError: false, message: data?.message })
      }

      postDispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: { data } })
    } catch (error) {
      const message = error?.response?.data.message
      toast.error(message || error.message)
      console.log(error)
      setMsg({ isError: true, message: message })

      postDispatch({ type: ACTION_TYPES.FETCH_ERROR })
    }
    // postReducer
  }

  return (
    <ThemeProvider theme={theme}>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className=" d-flex align-items-center justify-content-center p-0 d-none d-md-block d-lg-block col-md-6">
              <div className="login_left_side">
                <div className="logo text-center">
                  <JsonAnimation />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login_right_side">
                <div className="login_form">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Forget Password
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ width: { xs: '100%', sm: '70%' } }}
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                      />
                      <small
                        className={
                          msg?.isError ? 'text-danger' : 'text-success'
                        }
                      >
                        {msg?.message}
                      </small>
                      {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                          /> */}

                      {state.loading ? (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Please wait...
                        </Button>
                      ) : (
                        <Button
                          className="submit_btn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Submit
                        </Button>
                      )}
                      <Grid container>
                        <Grid item xs>
                          <Link to="/login" variant="body2">
                            Login Account
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link to="/signup" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ThemeProvider>
  )
}
