import React, { useCallback, useState } from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { DragDropContext } from 'react-beautiful-dnd'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Api } from '../../../helper/ApiList'
import { putMethod } from '../../../helper/ApiMethods'
import { DragColumn } from './DragColumn'
import { toast } from '../../Toast/Toast'

const DragSongBlock = forwardRef((props, ref) => {
  const blockRef = useRef(null)
  const { singleSong } = useSelector(state => state.singleSong)

  const { reOrderSongBlocks } = useSelector(state => state.singleSong)


  const [state, setState] = useState(null)
  const { token } = useSelector(state => state.auth)




  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      let data = blockRef?.current?.childFunction1()
      return data
    }
  }))

  const handleDragStart = useCallback(
    start => {
      document.body.style.color = 'orange'
      document.body.style.transition = 'background-color 0.2s ease'

      setState({
        ...state,
        homeIndex: state.columnOrder.indexOf(start.source.droppableId)
      })
    },
    [state]
  )

  const handleDragUpdate = useCallback(
    update => {
      const opacity = update.destination
        ? update.destination.index / Object.keys(state.tasks).length
        : 0

      document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
    },
    [state]
  )

  const handleDragEnd = useCallback(
    async result => {
      document.body.style.color = 'inherit'
      document.body.style.backgroundColor = 'inherit'

      setState({
        ...state,
        homeIndex: null
      })

      if (!result.destination) {
        return
      }

      if (
        result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index
      ) {
        return
      }

      const start = state.columns[result.source.droppableId]
      const finish = state.columns[result.destination.droppableId]

      if (start === finish) {


   
        let body = {
          _id: result.draggableId,
          destinationIndex: result.destination.index,
          draggableId: result?.draggableId,
          destinationId: start?.taskIds[result?.destination?.index],
          sourceIndex:result?.source?.index
        }

        const newTaskIds = Array.from(start.taskIds)
        newTaskIds.splice(result.source.index, 1)
        newTaskIds.splice(result.destination.index, 0, result.draggableId)


        const newColumn = {
          ...start,
          taskIds: newTaskIds
        }

        setState({
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn
          }
        })
        // send swap data in to server
        const { error } = await putMethod(
          token,
          Api.swapSongBlock + singleSong?._id,
          body
        )
        error && toast.error(error)
        return
      }

      const startTaskIds = Array.from(start.taskIds)
      startTaskIds.splice(result.source.index, 1)
      const newStart = {
        ...start,
        taskIds: startTaskIds
      }

      const finishTaskIds = Array.from(finish.taskIds)
      finishTaskIds.splice(result.destination.index, 0, result.draggableId)
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds
      }

      setState({
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      })
    },
    [state]
  )

  useEffect(() => {
    setState(reOrderSongBlocks)
  }, [reOrderSongBlocks])

  return (
    <>
      {state && state.length != 0 && (
        <DragDropContext
          onDragStart={handleDragStart}
          onDragUpdate={handleDragUpdate}
          onDragEnd={handleDragEnd}
        >
          {state && (
            <div className="category_list">
              {state &&
                state?.columnOrder?.map((columnId, index) => {
                  const column = state && state.columns[columnId]

                  const tasks =
                    column &&
                    column.length !== 0 &&
                    column.taskIds.map(taskId => state && state.tasks[taskId])

                  const isDropDisabled = index < state && state.homeIndex

                  return (
                    <DragColumn
                      //   type={type}
                      key={column.id}
                      column={column}
                      tasks={tasks}
                      isDropDisabled={isDropDisabled}
                      ref={blockRef}
                    />
                  )
                })}
            </div>
          )}
        </DragDropContext>
      )}
    </>
  )
})

export default DragSongBlock
