import React from 'react'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import { deleteMethod } from '../../../helper/ApiMethods'
import { DeleteIcon } from '../../../helper/icons'

import { useMemo } from 'react'
import { singleSongAction } from '../../../services/actions/song.action'
import { toast } from '../../Toast/Toast'

const FavsTags = () => {
  const { token } = useSelector(state => state.auth)
  // const { favTags } = useSelector(state => state.favTags);
  const { singleSong } = useSelector(state => state.singleSong)

  const dispatch = useDispatch()
  const alert = useAlert()

  function testFunc(e) {
    e.target.select()
  }

  const handleDeleteFavoriteTag = async (tag, label) => {
    const body = {
      title: tag?.title,
      label: label,
      songId: singleSong?._id
    }

    const { data, error } = await deleteMethod(
      token,
      Api.deleteFavoritesTag,
      body
    )

    if (error) {
      toast.error(data?.message)
      return
    }
    if (data) {
      dispatch(singleSongAction(data?.data))
    }
  }

  const pixelNumber = item => {
    let num = item?.trim()
    let number = num?.length

    return number > 26
      ? 28 + 'ch'
      : number > 22
      ? 22 + 'ch'
      : number <= 5
      ? 6 + 'ch'
      : number + 'ch'
  }

  const favTags = useMemo(() => {
    return [
      {
        title: 'words',
        label: singleSong?.words || []
      },
      {
        title: 'phrases',
        label: singleSong?.phrases || []
      },
      {
        title: 'rhymes',
        label: singleSong?.rhymes || []
      }

      // {
      //   title: 'synonym',
      //   label: singleSong?.synonym || []
      // }
    ]
  }, [singleSong])

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-lg-12">
            <div className="drag-btn-right-bar">
              <>{favTags?.length === 0 && 'Data not found'}</>
              {favTags?.map((tag, index) => (
                <div key={index}>
                  <p className="mb-2">
                    <b>{tag?.title || ''}</b>
                  </p>

                  <ul>
                    {tag?.label?.map((item, index) => (
                      <li className="drag-btn-inner" key={index}>
                        <input
                          type="text"
                          value={' ' + item + ' '}
                          onFocus={testFunc}
                          id="select-input"
                          style={{
                            width: `${pixelNumber(item)}`
                          }}
                        />

                        <DeleteIcon
                          handleDelete={() =>
                            handleDeleteFavoriteTag(tag, item)
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FavsTags
