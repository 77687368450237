import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
const Player = ({
  volume,
  onTimeUpdate,
  onLoadedData,
  seekTime,
  onEnded,
  blobUrl
}) => {
  const { isPlaying, playerVolume } = useSelector(state => state.playPause)

  const ref = useRef(null)
  // eslint-disable-next-line no-unused-expressions

  useEffect(() => {
    if (ref.current) {
      if (isPlaying) {
        ref.current.play()
      } else {
        ref.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    ref.current.volume = playerVolume
  }, [playerVolume])
  // updates audio element only on seekTime change (and not on each rerender):
  useEffect(() => {
    ref.current.currentTime = seekTime
  }, [seekTime])

  return (
    <>
      <audio
        // controls="true"
        src={blobUrl}
        // src={activeSong && activeSong.isNew ? blobUrl : activeSong?.songLink}
        ref={ref}
        // loop={repeat}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onLoadedData={onLoadedData}
      />
      {/* <span className="take-name">{activeSong && activeSong?.songName}</span> */}
    </>
  )
}

export default Player
