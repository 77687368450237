import React, { useMemo, useRef, useState } from 'react'
import { chordStyles } from '../utils/commonFunctions'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getMethod } from '../helper/ApiMethods'
import { loaderAction } from '../services/actions'
import { Api } from '../helper/ApiList'
import html2pdf from 'html2pdf.js'
import { PrintEmailMenuComponent } from '../components/mainPage/DropDownMenu/PrintEmailMenuComponent'
import moment from 'moment/moment'
import { LeftRightPaneFolderTypes } from '../utils/commonOptions'

const PreviewPage = () => {
  const divRef = useRef(null)
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)

  const urlString = window.location.href

  // Extract everything after the hash
  const hashIndex = urlString.indexOf('#')
  const hashString = urlString.slice(hashIndex + 1)

  // Create a URL object for the hash part with a dummy base URL
  const url = new URL(`${window.location.origin}/` + hashString)

  // Get the search parameters
  const queryParams = url.searchParams

  // Access individual query parameters
  const type = queryParams.get('type')
  const id = queryParams.get('id')

  console.log(type, 'asdjhajsd', id)

  const [tasks, setTasks] = useState([])
  const [heading, setHeading] = useState('')
  const [notesData, setNotesData] = useState(null)
  const [songWholeData, setSongWholeData] = useState(null)

  const lyricsHeading = ai_generated => {
    if (ai_generated) {
      return ai_generated?.aiAssistantLyricsQuestion
    }
    return ''
  }

  useMemo(async () => {
    const getSongBlocks = async () => {
      dispatch(loaderAction(true))

      let url = Api.getSongBlock
      if (type === 'notes') {
        url = Api.notebookDetail
      } else if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        url = Api.aiDetails
      }

      url = url + id

      const { data, rest } = await getMethod(token, url)
      setHeading(
        rest?.songDetails?.songHeading ||
          rest?.data?.notebookHeading ||
          lyricsHeading(rest?.data) ||
          "Songwriter's Pad - AI Lyric Generator"
      )
      setSongWholeData(rest)

      dispatch(loaderAction(false))
      if (type === 'notes' || type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        setNotesData(rest)
      } else {
        setTasks(data)
      }
      return data
    }

    getSongBlocks()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const handleSave = () => {
    if (divRef.current) {
      let content = tasks
        ?.map(
          blocks =>
            `${blocks?.songBlockHeading}\n${stripHtmlTags(
              chordStyles(blocks?.songBlockDescription)
            )}\n`
        )
        .join('\n')

      if (type === 'notes' || type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        content = chordStyles(notesData?.data?.notebookContent || notesData?.data?.aiAssistantLyricsContent|| '')
      } 

      // Create a Blob with the content
      const blob = new Blob([content], { type: 'text/plain' })

      // Create a download link
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = heading || ''

      // Append the link to the body and click it
      document.body.appendChild(link)
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
    }
  }

  const writtenBy = useMemo(() => {
    if (
      songWholeData?.songDetails?.co_author &&
      songWholeData?.songDetails?.author_names
    ) {
      return `Written by: ${songWholeData?.songDetails?.author_names}, ${songWholeData?.songDetails?.co_author}`
    }
    if (songWholeData?.songDetails?.author_names) {
      return `Written by: ${songWholeData?.songDetails?.author_names || ''}`
    }
    return ''
  }, [songWholeData?.songDetails])

  const copyWrite = useMemo(() => {
    if (
      songWholeData?.songDetails?.co_author &&
      songWholeData?.songDetails?.author_names
    ) {
      return `${moment(
        songWholeData?.songDetails?.created_at || new Date()
      ).format('YYYY')}, ${songWholeData?.songDetails?.author_names}, ${
        songWholeData?.songDetails?.co_author
      }`
    }
    if (songWholeData?.songDetails?.author_names) {
      return `${moment(
        songWholeData?.songDetails?.created_at || new Date()
      ).format('YYYY')}, ${songWholeData?.songDetails?.author_names}`
    }
    return `${moment(
      songWholeData?.songDetails?.created_at || new Date()
    ).format('YYYY')}`
  }, [songWholeData?.songDetails])


  const ContentWrapper = () => {
    return (
      <div>
        <p
          className="fs-12"
          dangerouslySetInnerHTML={{
            __html: chordStyles(notesData?.data?.notebookContent || notesData?.data?.aiAssistantLyricsContent|| '')?.replaceAll(`\n`, `</br>`)
          }}
        />
      </div>
    )
  }

  const ChildComponent = () => {
    return (
      <div className="printable" ref={divRef}>
        <div className="per-heading-new ps-4">
          <h5 className="fs-14">{heading}</h5>
          <p className="fs-12">{writtenBy || ''}</p>

          {type === 'notes' || type === LeftRightPaneFolderTypes.AI_ASSISTANT ? (
            <ContentWrapper />
          ) : (
            tasks?.map(blocks => (
              <div key={blocks?._id} className="">
                <h5 className="fs-12">{blocks?.songBlockHeading}</h5>
                <p
                  className="fs-12"
                  dangerouslySetInnerHTML={{
                    __html: chordStyles(blocks?.songBlockDescription)
                  }}
                />
              </div>
            ))
          )}
        </div>
        <div className="footer">
          <p className="copyright ps-2">Copyright &copy; {copyWrite}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="perview-background">
      <div className="perview-new-page d-flex justify-content-between p-1">
        <div></div>
        <div className="pre-heading"> {heading}</div>
        <div className="d-flex me-2">
          {/* <img
            onClick={() => handlePrint()}
            src="assets/images/bookmark-toolbar.svg"
            alt=""
            srcSet=""
            className="toolbar-btn me-2"
          /> */}

          <PrintEmailMenuComponent
            // onClick={() => dispatch(colorToggleHideAction())}
            editor={<ChildComponent />}
          />

          <img
            onClick={() => handleSave()}
            className="toolbar-btn ms-1"
            src="assets/images/share-icon-toolbar.svg"
            alt=""
            srcSet=""
            // width="22px"
          />
        </div>
      </div>
      <ChildComponent />
    </div>
  )
}

export default PreviewPage
