import React from 'react'
import ReactToPrint from 'react-to-print'
import { chordStyles } from '../../../utils/commonFunctions'

class ComponentToPrint extends React.Component {
  render(props) {
    return (
      <>
        <div className="mt-4">
        {this.props?.editor}
        </div>

      
      </>
    )
  }
}

class PrintEmailMenuComponent extends React.Component {
  render(props) {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <img
              src="assets/images/bookmark-toolbar.svg"
              alt=""
              srcSet=""
              className="toolbar-btn"
            />
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: 'none' }}>
          <ComponentToPrint
            ref={el => (this.componentRef = el)}
            editor={this.props?.editor}
          />
        </div>
      </div>
    )
  }
}

function EmailComponent() {
  return (
    <img
      src="assets/images/share-icon-toolbar.svg"
      alt=""
      srcSet=""
      width="22px"
    />
  )
}

export { PrintEmailMenuComponent, EmailComponent }
