import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
const BackTrackPlayer = ({
  volume,
  onTimeUpdate,
  onLoadedData,
  seekTime,
  onEnded
}) => {
  const { backTrackSelectIsPlaying, backTrackSelectActiveSong } = useSelector(
    state => state.selectBackTrack
  )

  const { playerVolume } = useSelector(state => state.playPause)

  const ref = useRef(null)
  // eslint-disable-next-line no-unused-expressions

  useEffect(() => {
    if (ref.current) {
      if (backTrackSelectIsPlaying) {
        ref.current.play()
      } else {
        ref.current.pause()
      }
    }
  }, [backTrackSelectIsPlaying])

  useEffect(() => {
    ref.current.volume = playerVolume
  }, [playerVolume])
  // updates audio element only on seekTime change (and not on each rerender):
  useEffect(() => {
    ref.current.currentTime = seekTime
  }, [seekTime])

  return (
    <>
      <audio
        // controls="true"
        src={backTrackSelectActiveSong?.trackLink}
        ref={ref}
        // loop={repeat}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onLoadedData={onLoadedData}
      />
    </>
  )
}

export default BackTrackPlayer
