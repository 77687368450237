import { playerConstants } from '../../constants'

const initialState = {
  allBackTracks: []
}
const backTrackState = {
  currentSongs: [],
  backTrackIsActive: false,
  backTrackIsPlaying: false,
  backTrackActiveSong: {},
  isBlockBackTrack: false,
  isFooterBackTrack:false
}
const backTrackSelectState = {
  backTrackSelectIsActive: false,
  backTrackSelectIsPlaying: false,
  backTrackSelectActiveSong: {}
}

const backTrackBuffer = {
  backTrackBuffer: null
}


const isBackTrackEnableState = {
  isFBackTrackActive: false,
  isFBackTrackActiveSong: null
}

const getBackTrackReducer = (state = initialState, action) => {
  switch (action.type) {
    case playerConstants.ALL_BACKTRACKS: {
      return {
        ...state,
        allBackTracks: action.payload
      }
    }
    default:
      return state
  }
}

const backTrackSelectReducer = (state = backTrackSelectState, action) => {
  switch (action.type) {
    case playerConstants.BACK_TRACK_SELECT: {
      return {
        ...state,
        backTrackSelectIsPlaying: action.payload.isPlaying,
        backTrackSelectActiveSong: action.payload.activeSong
      }
    }
    default:
      return state
  }
}
const backTrackPlayPauseReducer = (state = backTrackState, action) => {
  switch (action.type) {
    case playerConstants.BACK_TRACKS_PLAY_PAUSE: {
      return {
        ...state,
        backTrackIsPlaying: action.payload.isPlaying,
        backTrackActiveSong: action.payload.activeSong
      }
    }
    case playerConstants.SONG_BLOCK_SELECTED_BACKTRACK: {      
      return {
        ...state,
        isBlockBackTrack: action.payload.boolean,
        isFooterBackTrack: action.payload.isFooter
      }
    }

    default:
      return state
  }
}

const backTrackSelectBufferDataReducer = (state = backTrackBuffer, action) => {
  switch (action.type) {
    case playerConstants.BACK_TRACK_BUFFER: {
      return {
        ...state,
        backTrackBuffer: action.payload.buffer
      }
    }
    default:
      return state
  }
}


const backTrackFooterReducer = (state = isBackTrackEnableState, action) => {
  switch (action.type) {
    case playerConstants.FOOTER_BACKTRACK_ACTIVE: {
      return {
        ...state,
        isFBackTrackActive: action.payload.isFBackTrackActive,
        isFBackTrackActiveSong: action.payload.activeSong
      }
    }
    case playerConstants.FOOTER_BACKTRACK_RESET: {
      return {
        ...state,
        isFBackTrackActive: false,
        isFBackTrackActiveSong: null

      }
    }
    default:
      return state
  }
}

export {
  getBackTrackReducer,
  backTrackPlayPauseReducer,
  backTrackSelectReducer,
  backTrackSelectBufferDataReducer,backTrackFooterReducer
}
