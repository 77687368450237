import React, { useRef, useState } from 'react'

const backgroundMusicUrl =
  'https://test-swp-api.appsmaventech.com/api/v1/file-data/668b88dd8cd44fab910632aa'

const AudioRecorder = () => {
  const [audioUrl, setAudioUrl] = useState(null)
  const audioContextRef = useRef(null)
  const mediaStreamRef = useRef(null)
  const mediaStreamSourceRef = useRef(null)
  const microphoneGainNodeRef = useRef(null)
  const musicGainNodeRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const recordedChunksRef = useRef([])

  const startAudioSession = async () => {
    try {
      // Create an AudioContext
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)()

      // Get user media (microphone access)
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true
      })

      // Create a MediaStreamAudioSourceNode from the stream
      mediaStreamSourceRef.current = audioContextRef.current.createMediaStreamSource(
        mediaStreamRef.current
      )

      // Create a gain node to control the microphone volume (for recording)
      microphoneGainNodeRef.current = audioContextRef.current.createGain()
      microphoneGainNodeRef.current.gain.value = 2 // Set microphone gain value (volume) to 200%

      // Connect the input to the microphone gain node
      mediaStreamSourceRef.current.connect(microphoneGainNodeRef.current)

      // Load and decode background music
      const response = await fetch(backgroundMusicUrl)
      const arrayBuffer = await response.arrayBuffer()
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      )
      const backgroundMusicSource = audioContextRef.current.createBufferSource()
      backgroundMusicSource.buffer = audioBuffer
      backgroundMusicSource.loop = true

      // Create a gain node for background music
      musicGainNodeRef.current = audioContextRef.current.createGain()
      musicGainNodeRef.current.gain.value = 0.5 // Set background music volume to 50%

      // Connect background music to its gain node
      backgroundMusicSource.connect(musicGainNodeRef.current)
      // Connect background music gain node to the audio context destination (speakers)
      musicGainNodeRef.current.connect(audioContextRef.current.destination)

      // Create a destination node for recording
      const destinationNode = audioContextRef.current.createMediaStreamDestination()

      // Connect gain nodes to the destination node
      microphoneGainNodeRef.current.connect(destinationNode)
      musicGainNodeRef.current.connect(destinationNode)

      // Create a MediaRecorder to record the mixed audio
      mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)
      mediaRecorderRef.current.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data)
        }
      }

      // Start recording
      mediaRecorderRef.current.start()
      backgroundMusicSource.start()

      console.log('Audio session started for play and record.')
    } catch (error) {
      console.error('Failed to set up audio session:', error.message)
    }
  }

  const stopAudioSession = () => {
    if (audioContextRef.current && mediaRecorderRef.current) {
      // Stop recording
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.onstop = () => {
        // Create a Blob from the recorded chunks
        const audioBlob = new Blob(recordedChunksRef.current, {
          type: 'audio/webm'
        })
        // Create a URL for the Blob
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioUrl(audioUrl)

        console.log('Recorded audio Blob:', audioBlob)
        console.log('Recorded audio URL:', audioUrl)

        // Reset recordedChunks for the next recording session
        recordedChunksRef.current = []
      }

      // Disconnect the nodes
      if (mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect()
      }
      if (microphoneGainNodeRef.current) {
        microphoneGainNodeRef.current.disconnect()
      }
      if (musicGainNodeRef.current) {
        musicGainNodeRef.current.disconnect()
      }

      // Stop all tracks in the media stream
      mediaStreamRef.current.getTracks().forEach(track => track.stop())

      // Close the audio context
      audioContextRef.current.close()

      console.log('Audio session stopped.')
    } else {
      console.warn('Audio session is not active.')
    }
  }

  return (
    <div>
      <hr />
      Background Music <audio controls src={backgroundMusicUrl} />
      <hr />
      <div>
        <button onClick={startAudioSession}>Start Recording</button>
        <button onClick={stopAudioSession}>Stop Recording</button>
        {audioUrl && (
          <div>
            <audio controls src={audioUrl}></audio>
            <a href={audioUrl} download="recording.webm">
              Download Recording
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default AudioRecorder
