import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { BootstrapInput } from './commonComponent'
import {
  languageOptions,
  languagePopMsg,
  lyricStyles
} from '../../utils/commonOptions'
import { useDispatch } from 'react-redux'
import { assistantFooterToggleAction } from '../../services/actions/toggle.action'
import { useSelector } from 'react-redux'
import { constants } from '../../services/constants'
import CommonModal from '../Modal/CommonModal'
import ReactSelect from 'react-select'

const customStyles = {
  noOptionsMessage: provided => ({
    ...provided,
    color: '#808080', // Change the color to your desired style
    fontSize: '12px',
    height: '20px', // Set the height to 100px
    display: 'flex',
    alignItems: 'center', // Center vertically
    justifyContent: 'center' // Center horizontally
  })
  // menu: provided => ({
  //   ...provided,
  //   maxHeight: '150px', // Adjust the max height as needed
  //   overflowY: 'auto',
  // }),
}

const SelectMenu = ({ name, onChange, value }) => {
  return (
    <div>
      <FormControl
        sx={{ minWidth: '100%', height: 30, borderRadius: '8px' }}
        size="small"
        variant="standard"
        // className="ai-select-menu "
        className="style-select-menu"
      >
        <Select
          // className="new-sel-menu"
          className="language-select select-custom-ai"
          value={value}
          placeholder="Select"
          name={name}
          onChange={onChange}
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {[1, 2, 3, 4, 5, 6, 7, 8].map(count => (
            <MenuItem value={count} key={count}>
              {count}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
const TypesOfIdeas = ({ name, onChange, value }) => {
  return (
    <div>
      <FormControl
        sx={{ minWidth: '100%', height: 30, borderRadius: '8px' }}
        size="small"
        variant="standard"
        className="style-select-menu"
      >
        <Select
          className="language-select select-custom-ai"
          value={value}
          placeholder="Select"
          name={name}
          onChange={onChange}
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {['Lyrical Ideas', 'Song Concepts', 'Song Titles'].map(count => (
            <MenuItem value={count} key={count}>
              {count}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

const LanguageSelectMenu = ({ name, onChange, value }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="custom-select-lang">
      <FormControl
        sx={{ minWidth: '100%', height: 30, borderRadius: '8px' }}
        size="small"
        variant="standard"
        name={name}
        // className="ai-select-menu"
        className="style-select-menu"
        // onClick={() => window?.electron && setIsOpen(true)}
      >
        <Select
          className="language-select "
          labelId="demo-customized-select-label"
          id="demo-customized-select-lang"
          value={value}
          placeholder="Select"
          onChange={onChange}
          // disabled={window?.electron}
          name={name}
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
        >
          {languageOptions.map(({ flag, lang }) => (
            <MenuItem value={lang} key={lang}>
              <span className={`fi fi-${flag} pe-1`}></span>
              <span className="ps-2">{lang}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CommonModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={languagePopMsg}
      />
    </div>
  )
}

const PreSelectMenu = ({ allLyrics, setValues }) => {
  const newIdeas = sessionStorage.getItem('new_ideas')

  const { ai_generated } = useSelector(state => state.assistantTab)

  const dispatch = useDispatch()
  const handleSelect = ele => {
    if (!ele?.value) {
      setValues(ele)
      dispatch(assistantFooterToggleAction(false, null))
    } else {
      setValues(ele)
      dispatch(
        assistantFooterToggleAction(true, {
          ...ele,
          content: ele?.aiAssistantLyricsContent || ele?.aiContent
        })
      )
    }
  }

  const option = lyric => {
    if (!lyric) return ''
    return lyric?.aiAssistantLyricsQuestion?.includes(
      "You're a professional songwriter who writes creative"
    )
      ? lyric?.theme
      : lyric?.aiAssistantLyricsQuestion
  }

  // const saveList = () => {
  //   if (newIdeas) {
  //     return allLyrics
  //       ?.filter(ele => ele?.typeOfIdeas)
  //       ?.map(lyric => {
  //         return { label: option(lyric), value: option(lyric), ...lyric }
  //       })
  //       ?.sort((a, b) => a?.label?.localeCompare(b?.label))
  //   }
  //   return allLyrics
  //     ?.filter(ele => !ele?.typeOfIdeas)
  //     ?.map(lyric => {
  //       return { label: option(lyric), value: option(lyric), ...lyric }
  //     })
  //     ?.sort((a, b) => a?.label?.localeCompare(b?.label))
  // }

  // console.log(saveList)

  return (
    <div className="For-inspiration-select">
      <ReactSelect
        options={
          newIdeas
            ? allLyrics
                ?.filter(ele => ele?.typeOfIdeas)
                ?.map(lyric => {
                  return {
                    label: option(lyric),
                    value: option(lyric),
                    ...lyric
                  }
                })
                ?.sort((a, b) => a?.label?.localeCompare(b?.label))
            : allLyrics
                ?.filter(ele => !ele?.typeOfIdeas)
                ?.map(lyric => {
                  return {
                    label: option(lyric),
                    value: option(lyric),
                    ...lyric
                  }
                })
                ?.sort((a, b) => a?.label?.localeCompare(b?.label))
        }
        noOptionsMessage={() => 'None'}
        styles={customStyles}
        onChange={newValue => handleSelect(newValue)}
        classNamePrefix="select"
        isSearchable={false}
        isClearable={true}
        value={
          ai_generated?.theme
            ? { label: option(ai_generated), value: option(ai_generated) }
            : null
        }
        MenuPlacement={'auto'}
      />
    </div>
  )
}

const StyleSelectMenu = ({ onChange, value, name }) => {
  return (
    <div>
      <FormControl
        sx={{ minWidth: '100%', height: 30, borderRadius: '8px' }}
        size="small"
        variant="standard"
        className="style-select-menu"
      >
        <Select
          className="language-select select-custom-ai"
          value={value}
          placeholder="None"
          name={name}
          onChange={onChange}
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value="" className="">
            <em>None</em>
          </MenuItem>

          {lyricStyles?.map((lyric, index) => (
            <MenuItem value={lyric || ''} key={index}>
              {lyric}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

const NotebookSelectMenu = ({ notebookList }) => {
  const { singleSong } = useSelector(state => state.singleSong)

  const dispatch = useDispatch()

  const handleClick = item => {
    if (item) {
      item['isNotebook'] = true
      dispatch({
        type: constants.SINGLE_SONG,
        payload: { song: singleSong, singleNotebook: item }
      })
    } else {
      dispatch({
        type: constants.SINGLE_SONG,
        payload: { song: singleSong, singleNotebook: null }
      })
    }
  }

  return (
    <div className="For-inspiration-select ">
      <ReactSelect
        options={notebookList?.map(lyric => {
          return {
            label: lyric?.notebookHeading,
            value: lyric?.notebookHeading,
            ...lyric
          }
        })}
        noOptionsMessage={() => 'None'}
        styles={customStyles}
        onChange={newValue => handleClick(newValue)}
        classNamePrefix="select"
        isSearchable={false}
        isClearable={true}
      />
    </div>
  )
}

export {
  SelectMenu,
  LanguageSelectMenu,
  PreSelectMenu,
  StyleSelectMenu,
  NotebookSelectMenu,
  TypesOfIdeas
}
