import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { Api } from '../helper/ApiList'

const RefedSignUp = ({ setIsRefed, refedId }) => {
  const [refedInfo, setRefedInfo] = useState(null)
  useMemo(async () => {
    const { data } = await axios.post(
      Api.refed_user_info?.replace(`{refedId}`, `${refedId}`)
    )
    return setRefedInfo(data?.refedUser?.userId || null)
  }, [refedId])

  console.log(refedInfo, 'refedInfo~~~')

  return (
    <div className="ref_container">
      <div className="center-logo">
        <img src="assets/images/logo_new.svg" alt="" srcset="" />
      </div>

      <div className="content_ref_wrapper">
        <div>
          <h4>
            {refedInfo?.firstName || refedInfo?.username || 'Dante'} invited you to
            get Songwriter's Pad AI totally Free!
          </h4>
        </div>
        <p className="para">
          Songwriter's Pad is used around the world by Grammy Award winners and
          new songwriter alike.
          <br />
          It's powerful AI Idea generation tools will help{' '}
          <b>
            <i>write better songs, faster!</i>
          </b>
          <br />
          It's like songwriting superpowers on top.
        </p>
      </div>

      <Button
        className="submit_btn"
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => setIsRefed(false)}
      >
        Get Started - It's Free
      </Button>
    </div>
  )
}

export default RefedSignUp
