import * as React from 'react'
import Loader from 'react-js-loader'
export default function CustomLoader() {
  return (
    <div className={'custom_loader'}>
      <div className={'item'}>
        <Loader
          type="spinner-circle"
          bgColor={'#1d8bdf'}
          color={'#1d8bdf'}
          size={100}
        />
      </div>
    </div>
  )
}
