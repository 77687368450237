import { songBlockReOrder } from '../../utils/commonFunctions'
import { cartTabConstants, constants } from '../constants'
import store from '../store/store'

const songAction = (song, data) => async dispatch => {
  const customData = await songBlockReOrder(data)

  return dispatch({
    type: constants.SINGLE_SONG_DATA,
    payload: {
      song: song ? song : {},
      songBlocks: data ? data : [],
      songBlocksReOrder: data.length === 0 ? [] : customData
    }
  })
}

const singleSongAction = song => async dispatch => {
  const { songBlocks, songBlocksReOrder } = store.getState().singleSong

  return dispatch({
    type: constants.SINGLE_SONG,
    payload: {
      song: song ? song : {},
      songBlocks: songBlocks,
      songBlocksReOrder: songBlocksReOrder,
      singleNotebook: null
    }
  })
}

const updateSongBlockAction = (songBlocksReOrder,songBlocks) => async dispatch => {
  const { singleSong } = store.getState().singleSong
  return dispatch({
    type: constants.SINGLE_SONG_DATA,
    payload: {
      song: singleSong ,
      songBlocks: songBlocks,
      songBlocksReOrder: songBlocksReOrder,
      
    }
  })
}

const recentSongAction = result => async dispatch => {
  return dispatch({
    type: constants.RECENT_SONGS,
    payload: { song: result }
  })
}

const allSongAction = (data, title, folderId, type) => async dispatch => {
  return dispatch({
    type: constants.ALL_SONGS,
    payload: {
      song: data,
      title: title ? title : 'All Songs',
      folderId: folderId ? folderId : null,
      type: type
    }
  })
}

export { songAction, recentSongAction, allSongAction, singleSongAction,updateSongBlockAction }
