import { constants } from '../constants'

const initialState = {
  selectedFolders: []
}

const folderState = {
  folders: []
}

const selectedFolderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SELECT_FOLDERS_DATA: {
      return {
        selectedFolders: action.payload.folders
      }
    }
    case constants.RESET_SELECT_FOLDERS_DATA: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

const allFoldersReducer = (state = folderState, action) => {
  switch (action.type) {
    case constants.ALL_FOLDERS: {
      return {
        folders: action.payload.folders
      }
    }
    default:
      return state
  }
}

export { selectedFolderReducer, allFoldersReducer }
