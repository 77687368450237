
import { playerConstants } from '../../constants'


const backTrackAction = data => async dispatch => {
  return dispatch({ type: playerConstants.ALL_BACKTRACKS, payload: data })
}
const backTrackPlayPauseAction = (Boolean, activeSong) => async dispatch => {
  return dispatch({
    type: playerConstants.BACK_TRACKS_PLAY_PAUSE,
    payload: { isPlaying: Boolean, activeSong }
  })
}
const backTrackSelectAction = (Boolean, activeSong) => async dispatch => {
  return dispatch({
    type: playerConstants.BACK_TRACK_SELECT,
    payload: { isPlaying: Boolean, activeSong }
  })
}

const backTrackSelectBufferDataAction = buffer => async dispatch => {
  return dispatch({
    type: playerConstants.BACK_TRACK_BUFFER,
    payload: { buffer: buffer }
  })
}
const backTrackFooterAction = (boolean, activeSong) => async dispatch => {
  return dispatch({
    type: playerConstants.FOOTER_BACKTRACK_ACTIVE,
    payload: { isFBackTrackActive:boolean, activeSong }
  })
}

export {
  backTrackAction,
  backTrackPlayPauseAction,
  backTrackSelectAction,
  backTrackSelectBufferDataAction,backTrackFooterAction
}
