import React from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import SongBlockItem from './SongBlockItem'

const TaskList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? '#f9f9f9' : 'white')};
`

export const DragColumn = forwardRef((props, ref) => {
  const blockRef = useRef(null)

  let { column, tasks, isDropDisabled } = props

  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      let data = blockRef?.current?.childFunction1()



      return data
    }
  }))

  return (
    <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <TaskList
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
        >
          {tasks.map((task, index) => (
            <div key={task._id}>
              <SongBlockItem
                key={task._id}
                blocks={task}
                index={index}
                ref={blockRef}
              />
            </div>
          ))}
          {provided.placeholder}
        </TaskList>
      )}
    </Droppable>
  )
})

export default DragColumn
