import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { putMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import moment from 'moment/moment'
import { toast } from '../../Toast/Toast'

const RecordingList = ({
  index,
  recordingList,
  setRecordingList,
  selectSongs,
  songItem,
  handlePlayPause,
  handleSongSelect,
  loaderId,
  setIsEdit,
  isEdit
}) => {
  const { isPlaying, activeSong } = useSelector(state => state.playPause)
  const { token } = useSelector(state => state.auth)
  const [isEditItemId, setIsEditItemId] = useState(null)

  const handleTitleChange = e => {
    let allRecordings = [...recordingList]
    let findEle = recordingList[index]
    findEle.songName = e.target.value
    allRecordings[index] = findEle
    setRecordingList(allRecordings)
  }

  const handleUpdateTitle = async e => {
    const body = {
      songName: e.target.value
    }
    if (!isEditItemId) {
      return
    }
    const url = Api.updateRecordingTitle + recordingList[index]?._id
    setIsEditItemId(null)
    try {
      const { data, error } = await putMethod(token, url, body)

      if (!error) {
        toast.success(data?.message)
      }
    } catch (error) {
      console.log(error, 'recordings song deleted error')
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleUpdateTitle(e)
    }
  }

  return (
    <div
      className={`track-list cursor  ${
        selectSongs.includes(songItem?._id) && 'active'
      }`}
      onClick={() => !isEdit && handleSongSelect(songItem)}
    >
      <div>
        <div className="d-flex">
          <img
            className="recording_play_icon"
            src={
              songItem?._id === activeSong?._id && isPlaying
                ? 'assets/images/pause.svg'
                : 'assets/images/play-border-new.svg'
            }
            alt=""
            width={22}
            onClick={e => {
              e.stopPropagation()
              handlePlayPause(songItem)
            }}
          />

          <div className="song-item-wrapper">
            <div className="song-track">
              {isEdit ? (
                <input
                  type="text"
                  autoComplete="off"
                  value={songItem?.songName}
                  onClick={e => {
                    e.stopPropagation()
                    setIsEditItemId(songItem?._id)
                  }}
                  onChange={e => handleTitleChange(e)}
                  onKeyPress={handleKeyPress}
                  onBlur={handleUpdateTitle}
                />
              ) : (
                songItem?.songName
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {songItem?.created_at && moment(songItem?.created_at).format('ll')}{' '}
        {loaderId.includes(songItem?.songName) && (
          <span className="download-msg">Downloading...</span>
        )}
      </div>
    </div>
  )
}

export default RecordingList
