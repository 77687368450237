import { constants } from '../../constants'

const ideaAction = (data, ideaType) => async dispatch => {
  dispatch({
    type: constants.IDEA_LISTS,
    payload: { data: data, ideaType: ideaType }
  })
}
const lookUpAction = boolean => async dispatch => {
  dispatch({
    type: constants.LOOK_UP_TOGGLE,
    payload: { boolean }
  })
}

const ideaSearchDataAction = (name, data) => async dispatch => {
  dispatch({
    type: constants.IDEA_SEARCH_LISTS,
    payload: { keyword: name, data: data }
  })
}

export { ideaAction, ideaSearchDataAction, lookUpAction }
