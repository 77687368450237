import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'

import {
  addSongToggleAction,
  assistantFooterToggleAction
} from '../../services/actions/toggle.action'
import { Form } from 'react-bootstrap'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import { getMethod, postMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import {
  allSongAction,
  recentSongAction,
  songAction
} from '../../services/actions/song.action'
import { useAlert } from 'react-alert'
import { getRecordingAction } from '../../services/actions/mediaPlayerActions/mediaPlayer.action'
import { constants } from '../../services/constants'
import { toast } from '../Toast/Toast'

export default function SongModal() {
  const { songToggle } = useSelector(state => state.songToggle)
  const { type } = useSelector(state => state.songBlockToggle)
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { songs } = useSelector(state => state.songs)
  const { recentSongs } = useSelector(state => state.recentSongs)
  const { singleSong, songBlocks } = useSelector(state => state.singleSong)

  const { token } = useSelector(state => state.auth)
  const [error, setError] = React.useState(false)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [body, setBody] = React.useState({
    songHeading: '',
    songDescription: ''
  })

  const handleInput = e => {
    const { name, value } = e.target
    const data = { ...body }
    data[name] = value
    setBody(data)
  }

  const handleSubmit = async () => {
    if (type) {
      if (!body.songHeading) {
        toast.error('Please enter heading')
        return
      }
    } else {
      if (!body.songHeading) {
        toast.error('Please enter name')
        return
      }
    }

    postDispatch({ type: ACTION_TYPES.FETCH_START })
    if (type === 'Add_SONG_BLOCK') {
      let newBody = {
        songBlockHeading: body?.songHeading,
        songBlockDescription: body?.songDescription,
        songId: singleSong?._id
      }

      const { data, error } = await postMethod(
        token,
        Api.createSongBlock,
        newBody
      )
      toastMsg(data, error)
      return
    }
    const { data, error } = await postMethod(token, Api.createSong, body)
    dispatch(assistantFooterToggleAction(false, null))
    toastMsg(data, error)
  }

  const toastMsg = async (data, error) => {
    if (!error) {
      toast.success(data?.message)

      if (type === 'Add_SONG_BLOCK') {
        let temp = [...songBlocks]

        temp.push(data?.data)
        dispatch(songAction(singleSong, temp))
      } else {
        let temp = [...songs]

        temp.unshift(data?.data)
        dispatch(allSongAction(temp))
        let recentArr = [...recentSongs]
        recentArr.length > 2 && recentArr.pop()

        recentArr.unshift(data?.data)
        dispatch(recentSongAction(recentArr))

        let url = Api.getSongBlock + data?.data?._id
        const { data: songBlocks } = await getMethod(token, url)
        dispatch(songAction(data?.data, songBlocks))
        dispatch({
          type: constants.SINGLE_SONG,
          payload: { singleNotebook: null }
        })
        dispatch(getRecordingAction())
      }

      setError(false)
      dispatch(addSongToggleAction())
      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data?.data }
      })
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  return (
    <div>
      <Dialog open={songToggle}>
        <DialogTitle className="custom_modal">
          Create Song {type && 'Block'}
        </DialogTitle>
        {/* <CloseIcon
          className="icon_close_btn"
          onClick={() => {
            setError(false)
            dispatch(addSongToggleAction())
          }}
        /> */}
        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => {
            setError(false)
            dispatch(addSongToggleAction())
          }}
        />
        <DialogContent className="dialog_content">
          {/* <Stack sx={{ width: '100%' }} spacing={2}> */}
          {error && (
            <Alert severity="error" className="alert_modal_error">
              {error}
            </Alert>
          )}
          {/* </Stack> */}

          <TextField
            margin="dense"
            name="songHeading"
            required
            id="name"
            label={`Enter song ${type && 'block'} name`}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleInput}
            InputLabelProps={{ className: 'text_label' }}
          />

          {type && (
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                {`Enter song ${type && 'block'} description`}
                {/* <span className="text-danger">*</span> */}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="songDescription"
                onChange={handleInput}
              />
            </Form.Group>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setError(false)
              dispatch(addSongToggleAction())
            }}
            className="cancelBtn"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={state?.loading}
            className={
              state?.loading
                ? 'submitBtn text-color'
                : 'submitBtn text-color submitBtn'
            }
            onClick={() => handleSubmit()}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
