import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import { postMethod } from '../../../helper/ApiMethods'
import { loaderAction } from '../../../services/actions/loader.action'

import { useAlert } from 'react-alert'
import { getFavoriteTagsAction } from '../../../services/actions/cartTab/cartTabAction'
import { singleSongAction } from '../../../services/actions/song.action'
import { AddBlueIcon } from '../../../helper/icons'

const LookupContent = () => {
  const { keyword, dictionaryData, thesaurusData } = useSelector(
    state => state.ideaSearchData
  )
  const { singleSong } = useSelector(state => state.singleSong)

  const { boolean } = useSelector(state => state.lookUpToggle)
  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const alert = useAlert()

  function testFunc(e) {
    e.target.select()
  }

  const pixelNumber = item => {
    let num = item?.trim()
    let number = num?.length

    return number > 26
      ? 27 + 'ch'
      : number > 22
      ? 22 + 'ch'
      : number <= 5
      ? 6 + 'ch'
      : number + 'ch'
  }

  const handleAddFavoriteTags = async item => {
    const body = {
      title: 'words',
      label: item,
      songId: singleSong?._id
    }

    dispatch(loaderAction(true))

    const { data, error } = await postMethod(token, Api.addFavorites, body)

    if (!error) {
      dispatch(singleSongAction(data))
    }
    dispatch(loaderAction(false))
  }

  const ThesaurusList = () => {
    return (
      <>
        {thesaurusData &&
          thesaurusData?.map((item, index) => (
            <div className="drag-btn-right-bar" key={index}>
              <p className="mb-2">
                <b>{item?.relationshipType}</b>
              </p>

              <ul>
                {item?.words?.map((item, index) => (
                  <li className="drag-btn-inner" key={index}>
                    <input
                      type="text"
                      value={' ' + item + ' '}
                      onFocus={testFunc}
                      id="select-input"
                      style={{
                        width: `${pixelNumber(item)}`
                      }}
                    />

                    <AddBlueIcon
                      className="add-blue-btn-iiner"
                      handleAddFavoriteTags={() => handleAddFavoriteTags(item)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </>
    )
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-lg-12">
            <div className="drag-btn-right-bar">
              <h4>{keyword}</h4>
              <ol className="lookupcont">
                {!boolean &&
                  dictionaryData &&
                  dictionaryData
                    ?.filter(item => item?.text)
                    ?.map(item => <li key={item?.id}>{item?.text}</li>)}
              </ol>

              {boolean ? (
                <ThesaurusList />
              ) : (
                <ul>
                  {/* {arr.map((item, index) => (
                    <li className="drag-btn-inner" key={index}>
                      <input
                        type="text"
                        value={' ' + item?.name + ' '}
                        onFocus={testFunc}
                        id="select-input"
                        style={{
                          maxWidth: `${
                            item?.name.length < 7
                              ? item?.name.length * 10
                              : item?.name.length * 9
                          }px`
                        }}
                      />

                      <img
                        className="add-blue-btn-iiner"
                        src="assets/images/add-blue-btn.svg"
                        alt=""
                      />
                    </li>
                  ))} */}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LookupContent
