import React from 'react'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import { postMethod } from '../../../helper/ApiMethods'
import { useAlert } from 'react-alert'
import { loaderAction } from '../../../services/actions/loader.action'
import { useDispatch } from 'react-redux'

import { singleSongAction } from '../../../services/actions/song.action'
import { AddBlueIcon } from '../../../helper/icons'
import { toast } from '../../Toast/Toast'

const RhymesTags = () => {
  const { keyword, rhymesData } = useSelector(state => state.ideaSearchData)
  const { token } = useSelector(state => state.auth)
  const { singleSong } = useSelector(state => state.singleSong)

  const dispatch = useDispatch()
  const alert = useAlert()
  function testFunc(e) {
    e.target.select()
  }

  const handleAddFavoriteTags = async item => {
    const body = {
      title: 'rhymes',
      label: item,
      songId: singleSong?._id
    }
    if (!item) {
      toast.error('Please select rhymes tag')
      return
    }
    dispatch(loaderAction(true))

    const { data, error } = await postMethod(token, Api.addFavorites, body)

    if (!error) {
      dispatch(singleSongAction(data))
    }
    dispatch(loaderAction(false))
  }

  const pixelNumber = item => {
    let num = item?.word?.trim()
    let number = num?.length

    return number > 26
      ? 27 + 'ch'
      : number > 22
      ? 22 + 'ch'
      : number <= 5
      ? 6 + 'ch'
      : number + 'ch'
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          {/* <p className="font-weight-normal">
            Words that rhyme with <i>amazing</i>
          </p> */}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="drag-btn-right-bar">
              <p className="mb-2">
                <b style={{ textTransform: 'auto' }}>
                  {keyword && ` Words that rhyme with ${keyword}`}
                </b>
              </p>
              <ul>
                <ul>
                  {rhymesData?.map((item, index) => (
                    <li className="drag-btn-inner" key={index}>
                      <input
                        type="text"
                        value={' ' + item?.word + ' '}
                        onFocus={testFunc}
                        id="select-input"
                        style={{
                          maxWidth: `${pixelNumber(item)}`
                        }}
                      />
                      <AddBlueIcon
                        className="add-blue-btn-iiner"
                        handleAddFavoriteTags={() =>
                          handleAddFavoriteTags(item?.word)
                        }
                      />
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RhymesTags
