import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { a11yProps, TabPanel } from '../../../utils/Tabs'
import BackingTab from './BackingTabs'
import RecordingTab from './RecordingTab'

const Recording = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <div>
        <div className="gray-section-right-bar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="right-side-tab mt-2">
                  <Box sx={{ width: '100%' }}>
                    <Box className="tabs-outer-bg">
                      <Tabs
                        className="round-blue"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          className="right-tab-custom chordnotion-tab"
                          label="Recordings"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="right-tab-custom chordnotion-tab"
                          label="Backing Tracks"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <div className="container">
                        <div className="row mt-2">
                          <div className="col-lg-12 text-center">
                            <p className="m-0">Audio</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <div className="search-bar-custom"></div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="container">
                        <div className="row mt-2">
                          <div className="col-lg-12 text-center">
                            <p className="m-0">Audio</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 text-center"></div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabPanel className="" value={value} index={0}>
          <RecordingTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BackingTab />
        </TabPanel>
      </div>
    </>
  )
}

export default Recording
