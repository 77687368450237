import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Recaptcha from 'react-google-invisible-recaptcha'

import TextField from '@mui/material/TextField'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAlert } from 'react-alert'
import { initialState, postReducer } from '../hooks/useApiReducer'
import { useDispatch } from 'react-redux'
import { ACTION_TYPES } from '../hooks/customReducerActionTypes'
import axios from 'axios'
import { Api } from '../helper/ApiList'
import { authAction } from '../services/actions/auth.action'
import { Link } from 'react-router-dom'
import JsonAnimation from '../Loader/JsonAnimation'
import { toast } from '../components/Toast/Toast'
import {
  detectSystemOs,
  getMobileOperatingSystem,
  isMobile
} from '../utils/commonFunctions'
import RefedSignUp from '../components/RefedSignUp'

const theme = createTheme()

export default function SignUp() {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const refRecaptcha = React.useRef()
  const searchParams = new URLSearchParams(window.location.search)
  const refedId = searchParams.get('refedId')
  const dispatch = useDispatch()
  const handleSubmit = async event => {
    event.preventDefault()
    const form = new FormData(event.currentTarget)
    let body = {
      email: form.get('email'),
      password: form.get('password'),
      firstName: form.get('firstName'),
      username: form.get('username')
    }

    if (refedId) {
      body['refedId'] = refedId
    }
    if (!body.email || !body.password || !body.firstName || !body.username) {
      toast.error('All fields are required')
      refRecaptcha?.current?.reset()

      return false
    }
    refRecaptcha?.current?.execute()

    postDispatch({ type: ACTION_TYPES.FETCH_START })

    try {
      const cancelToken = axios.CancelToken.source()
      let tag = 'Web'
      if (window?.electron) {
        tag = detectSystemOs()
      }

      const { data } = await axios.post(
        Api.createAccount,
        { ...body, tag: tag },
        {
          cancelToken: cancelToken.token
        }
      )
      if (data.statusCode === 201) {
        toast.success(data.message)
        getMobileOperatingSystem()
        setTimeout(() => {
          dispatch(authAction(data?.data))
        }, 100)
      }
      postDispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: { data } })
    } catch (error) {
      const message = error?.response?.data.message
      toast.error(message || error.message)
      postDispatch({ type: ACTION_TYPES.FETCH_ERROR })
    }
  }

  const [isRefed, setIsRefed] = React.useState(false)

  React.useMemo(() => {
    if (refedId) {
      // const mobile = isMobile()
      // if (mobile) {
      //   setIsRefed(true)
      // }
      setIsRefed(true)
    }
  }, [refedId])

  return (
    <ThemeProvider theme={theme}>
      <section>
        {isRefed ? (
          <RefedSignUp setIsRefed={setIsRefed} refedId={refedId} />
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className=" d-flex align-items-center justify-content-center p-0 d-none d-md-block d-lg-block col-md-6">
                <div className="login_left_side">
                  <div className="logo text-center">
                    <JsonAnimation />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right_side sign_up">
                  <div className="login_form ">
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign Up
                      </Typography>
                      <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                      >
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="firstName"
                          label="Name"
                          name="firstName"
                          autoComplete="firstName"
                          autoFocus
                        />

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="username"
                          label="User Name"
                          name="username"
                          autoComplete="username"
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                        {/* <FormControlLabel
                              control={<Checkbox value="remember" color="primary" />}
                              label="Remember me"
                            /> */}
                        {state.loading ? (
                          <Button
                            className="submit_btn"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Please wait...
                          </Button>
                        ) : (
                          <Button
                            className="submit_btn"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Sign Up
                          </Button>
                        )}
                        <Grid container>
                          <Grid item xs>
                            <Link to="/forget_password" variant="body2">
                              Forgot password?
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link to="/login" variant="body2">
                              {'Already have an account? Sign In'}
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Recaptcha
          ref={refRecaptcha}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_API}
          onResolved={value => console.log(value)}
        />
      </section>
    </ThemeProvider>
  )
}
