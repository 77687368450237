import React, { useMemo, useState } from 'react'
import {
  addFolderToggleAction,
  assistantFooterToggleAction
} from '../../../services/actions/toggle.action'
import { useDispatch, useSelector } from 'react-redux'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'

import {
  allSongAction,
  recentSongAction,
  songAction
} from '../../../services/actions/song.action'
import {
  allFolderAction,
} from '../../../services/actions/folder.action'
import { deleteMethod, getMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { loaderAction } from '../../../services/actions/loader.action'
import { useAlert } from 'react-alert'
import { constants } from '../../../services/constants'
import { UseAlert } from '../../commonComponent/commonComponent'
import {
  getRecordingAction,
  playPauseAction
} from '../../../services/actions/mediaPlayerActions/mediaPlayer.action'
import moment from 'moment/moment'
import { LeftRightPaneFolderTypes } from '../../../utils/commonOptions'
import { RecentIcon } from '../../../icons'
import { toast } from '../../Toast/Toast'

const FolderComponents = ({ img }) => {
  const { token } = useSelector(state => state.auth)
  const { folders } = useSelector(state => state.folders)
  const [rightData, setRightData] = useState(null)
  const { folderId } = useSelector(state => state.songs)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  function WhichButton(event, data) {
    setRightData(data)
  }

  const deleteFolder = async () => {
    let url = Api.deleteFolder + '/' + rightData?._id
    dispatch(loaderAction(true))

    const { data, error } = await deleteMethod(token, url)
    if (!error) {
      let arr = folders.filter(item => item._id !== rightData?._id)
      dispatch(allFolderAction(arr))
    }

    !error && toast.success(data?.message)
    error && toast.error(error)
    dispatch(loaderAction(false))
    setIsOpen(false)
  }

  const handleFolderSongs = async id => {
    dispatch(loaderAction(true))
    const { data, error } = await getMethod(token, Api.getFolderSongs + id)
    dispatch(allSongAction(data?.songList, data?.folderName, id))
    dispatch({ type: constants.RESET_SINGLE_SONG_DATA })
    error && toast.error(error)
    dispatch(loaderAction(false))
  }

  return (
    <>
      {isOpen && (
        <UseAlert
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          confirmDelete={deleteFolder}
        />
      )}

      {/* // className={`main_inner_folder ${
            //   folderId === folder?._id && 'folder_active'
            // }`} */}
      {folders?.map((folder, index) => (
        <div
          className="song"
          key={index}
          onMouseDown={e => WhichButton(e, folder)}
        >
          <ContextMenuTrigger id="contextmenu">
            <div
              className={
                folderId === folder?._id
                  ? 'allsong-new all_folder_active'
                  : 'recent-song-inactive'
              }
              onClick={() => handleFolderSongs(folder?._id)}
            >
              <img
                className="pr-2 "
                src={
                  folderId === folder?._id
                    ? 'assets/images/all-song-icon-new.svg'
                    : 'assets/images/folder_close.svg'
                }
                alt=""
              />
              <span className="">{folder?.folderName}</span>
            </div>
            {/* {selectBtnToggle && !type && (
              <Checkbox
                {...label}
                defaultChecked={false}
                className="folder_checkbox"
                onClick={() => dispatch(selectFolderAction(folder?._id))}
              />
            )} */}
          </ContextMenuTrigger>
        </div>
      ))}

      <ContextMenu id="contextmenu" className="folder_contextMenu">
        <MenuItem
          className="contextMenu--option"
          onClick={() => setIsOpen(true)}
        >
          <span data-rename="delete_right " className="c_span">
            Delete
          </span>
        </MenuItem>
        <div className="contextMenu--separator" />
        <MenuItem
          className="contextMenu--option"
          onClick={() => dispatch(addFolderToggleAction(rightData))}
        >
          <span data-rename="rename_right" className="c_span">
            Rename
          </span>
        </MenuItem>
      </ContextMenu>
    </>
  )
}

const NoteBookFolderComponents = ({ img }) => {
  const { selectBtnToggle } = useSelector(state => state.selectBtnToggle)
  const { token } = useSelector(state => state.auth)
  const { folders } = useSelector(state => state.folders)
  const [rightData, setRightData] = useState(null)
  const { folderId } = useSelector(state => state.songs)
  const [isOpen, setIsOpen] = useState(false)

  const alert = useAlert()
  const dispatch = useDispatch()
  function WhichButton(event, data) {
    setRightData(data)
  }

  const deleteFolder = async () => {
    let url = Api.deleteFolder + '/' + rightData?._id
    dispatch(loaderAction(true))

    const { data, error } = await deleteMethod(token, url)
    if (!error) {
      let arr = folders.filter(item => item._id !== rightData?._id)
      dispatch(allFolderAction(arr))
    }

    !error && toast.success(data?.message)
    error && toast.error(error)
    dispatch(loaderAction(false))
    setIsOpen(false)
  }

  const handleFolderSongs = async id => {
    dispatch(loaderAction(true))
    const { data, error } = await getMethod(token, Api.getFolderSongs + id)
    dispatch(allSongAction(data?.songList, data?.folderName, id))
    dispatch({ type: constants.RESET_SINGLE_SONG_DATA })
    error && toast.error(error)
    dispatch(loaderAction(false))
  }

  return (
    <>
      <UseAlert
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmDelete={deleteFolder}
      />
      {folders?.map((folder, index) => (
        <div
          key={index}
          className="song"
          onMouseDown={e => WhichButton(e, folder)}
        >
          <ContextMenuTrigger id="contextmenu">
            <div
              className={`main_inner_folder ${
                folderId === folder?._id && 'folder_active'
              }`}
            >
              <div
                className="folder_names"
                onClick={() => handleFolderSongs(folder?._id)}
              >
                <img
                  className="songImg"
                  src={
                    folderId === folder?._id
                      ? 'assets/images/folder_open_line.svg'
                      : img
                  }
                  alt=""
                />
                <span className="songName">{folder?.folderName}</span>
              </div>
              {/* {selectBtnToggle && (
                <Checkbox
                  {...label}
                  defaultChecked={false}
                  className="folder_checkbox"
                  onClick={() => dispatch(selectFolderAction(folder?._id))}
                />
              )} */}
            </div>
          </ContextMenuTrigger>
        </div>
      ))}

      <ContextMenu id="contextmenu" className="folder_contextMenu">
        <MenuItem
          className="contextMenu--option"
          onClick={() => setIsOpen(true)}
        >
          <span data-rename="delete_right " className="c_span">
            Delete
          </span>
        </MenuItem>
        <div className="contextMenu--separator" />
        <MenuItem
          className="contextMenu--option"
          onClick={() => dispatch(addFolderToggleAction(rightData))}
        >
          <span data-rename="rename_right" className="c_span">
            Rename
          </span>
        </MenuItem>
      </ContextMenu>
    </>
  )
}

const RecentSongComponents = ({ song, img }) => {
  const { token } = useSelector(state => state.auth)
  const { songs } = useSelector(state => state.songs)
  const { type } = useSelector(state => state.songs)
  const { singleSong } = useSelector(state => state.singleSong)

  const dispatch = useDispatch()
  const handleRefresh = async () => {
    dispatch(loaderAction(true))

    const recentSongs = await getMethod(token, Api.recentSongs)
    const allSongs = await getMethod(token, Api.getSongs)

    const allFolders = await getMethod(token, Api.getFolders)

    dispatch(allSongAction(allSongs?.data))
    dispatch(allFolderAction(allFolders?.data))
    dispatch(recentSongAction(recentSongs?.data))
    dispatch(loaderAction(false))
  }

  const getSongBlocks = async () => {
    dispatch(loaderAction(true))

    let url = Api.getSongBlock + song?._id
    const { data, rest } = await getMethod(token, url)
    dispatch(songAction(rest.songDetails, data))
    const allSongs = await getMethod(token, Api.getSongs)
    if (type) {
      dispatch(allSongAction(allSongs?.data))
    }
    await handleRecordings()

    if (songs.length === 0) {
      handleRefresh()
    }

    dispatch(loaderAction(false))
  }

  const handleRecordings = async () => {
    dispatch(loaderAction(true))

    let url = Api.getRecordings + song?._id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))
    if (songs.length === 0) {
      handleRefresh()
    }
    dispatch(loaderAction(false))
  }

  return (
    <div
      className={`cursor ${
        singleSong?._id === song?._id
          ? 'recent-song-inactive  allsong-new all_folder_active'
          : 'recent-song-inactive '
      }`}
      onClick={() => getSongBlocks()}
    >
      <RecentIcon color={singleSong?._id === song?._id ? '#1976d2' : '#fff'} />

      <span className="">{song?.songHeading || ''}</span>
    </div>
  )
}

const SongItemComponents = ({ song, handleSelect, checked }) => {
  const { selectBtnToggle } = useSelector(state => state.selectBtnToggle)
  const { recentSongs } = useSelector(state => state.recentSongs)
  const { type } = useSelector(state => state.songs)

  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)

  const [songId, setSongId] = useState(null)

  useMemo(() => {
    if (!recentSongs) return
    let isExist = recentSongs?.find(item => item?._id === songId?._id)

    if (isExist) {
      return
    }

    let recentArr = [...recentSongs]
    recentArr?.length > 2 && recentArr.pop()
    recentArr.unshift(songId)

    if (recentArr.includes(null)) {
      return
    }

    dispatch(recentSongAction(recentArr))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songId])

  const getSongBlocks = async () => {
    dispatch(assistantFooterToggleAction(false, null))
    if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
      dispatch(
        assistantFooterToggleAction(true, {
          ...song,
          content: song?.aiAssistantLyricsContent
        })
      )
      return
    }

    if (type === LeftRightPaneFolderTypes.NOTES) {
      dispatch({
        type: constants.SINGLE_SONG,
        payload: { song: null, singleNotebook: song }
      })
      return
    }

    dispatch(loaderAction(true))
    let url = Api.getSongBlock + song?._id
    const { data, rest } = await getMethod(token, url)

    dispatch(songAction(rest.songDetails, data))
    setSongId(rest.songDetails)
    await handleRecordings()
    dispatch(loaderAction(false))
  }

  const handleRecordings = async () => {
    dispatch(loaderAction(true))

    let url = Api.getRecordings + song?._id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))
    dispatch(playPauseAction(false))
    dispatch(loaderAction(false))
  }

  let description =
    song?.songDescription?.replace(/<[^>]+>/g, '') ||
    song?.aiAssistantLyricsContent ||
    song?.notebookContent ||
    '';

  const isActive = useMemo(() => {
    if (singleSong?._id === song?._id || singleNotebook?._id === song?._id) {
      return ' active'
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleNotebook, singleSong])
  return (
    <>
      <div
        className={`song-list-new ${isActive}`}
        onClick={() => getSongBlocks()}
      >
        <div className="row">
          <div className="col-8">
            <div className="">
              <h5 className="m-0">
                {song?.songHeading ||
                  song?.aiAssistantLyricsQuestion ||
                  song?.notebookHeading ||
                  ''}
              </h5>
              <p className="m-0 one-line-clamp">
                {description?.replace(/<[^>]+>/g, '') || ''}
              </p>
            </div>
          </div>
          <div className="col-4 p-0">
            <div className="date-size-new">
              <p className="m-0">
                {moment(song?.created_at || song?.createdAt)?.format('ll')}
              </p>
              <p className="m-0 text-end pe-3">
                {selectBtnToggle && (
                  <span className="checkbox-song">
                    <input
                      type="checkbox"
                      checked={checked}
                      onClick={e => {
                        e.stopPropagation()
                        handleSelect(song)
                      }}
                    />
                  </span>
                )}
              </p>

              {/* {selectBtnToggle && (
                <div className="col-lg-1 col-1">
                  <div className="select-custom-list">
                    <Checkbox
                      {...label}
                      checked={checked}
                      onClick={e => {
                        e.stopPropagation()
                        handleSelect(song)
                      }}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { FolderComponents, RecentSongComponents, SongItemComponents }
