import React from 'react'
import LeftASide from './LeftASide'
import RightASide from './RightASide'

const Sidebar = () => {
  return (
    <>
      <div className="leftASideWrapper">
        <LeftASide />
      </div>
      <div className="rightASideWrapper">
        <RightASide />
      </div>
    </>
  )
}

export default Sidebar
