import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'

import {
  assistantFooterToggleAction
} from '../../services/actions/toggle.action'
import LoadingButton from '@mui/lab/LoadingButton'
import { getMethod, putMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { initialState, postReducer } from '../../hooks/useApiReducer'
import { ACTION_TYPES } from '../../hooks/customReducerActionTypes'
import { loaderAction } from '../../services/actions/loader.action'
import { allSongAction } from '../../services/actions/song.action'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { toast } from '../Toast/Toast'
export default function RenameModal({
  openModal,
  setOpenModal,
  setAllLyrics,
  allLyrics
}) {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { token } = useSelector(state => state.auth)
  const { ai_generated } = useSelector(state => state.assistantTab)
  const { type } = useSelector(state => state.songs)
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    setValue(ai_generated?.aiAssistantLyricsQuestion || '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])


  const dispatch = useDispatch()

  const handleSubmit = async () => {
    if (!value) return toast.error('Please enter a name')
    let body = {
      aiAssistantLyricsQuestion: value
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })

    const { data, error } = await putMethod(
      token,
      `${Api.updateLyricsTitle}/${ai_generated?._id}`,
      body
    )

    if (!error) {
      toast.success(data?.message)
      let temp = [...allLyrics]
      let index = temp?.findIndex(item => item?._id === ai_generated?._id)

      temp[index] = data?.data
      setAllLyrics(temp)

      dispatch(
        assistantFooterToggleAction(true, {
          ...data?.data,
          content: data?.data?.aiAssistantLyricsContent
        })
      )
      if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
        await handleFolderLyrics()
      }
      closeModal()
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })

    error && toast.error(error)
  }

  const handleFolderLyrics = async () => {
    dispatch(loaderAction(true))
    const { data } = await getMethod(token, Api.getAllAILyricsWithOutFilter)

    dispatch(
      allSongAction(
        data?.lyrics,
        'AI Generated',
        '',
        LeftRightPaneFolderTypes.AI_ASSISTANT
      )
    )

    dispatch(loaderAction(false))
  }

  const closeModal = () => {
    setValue('')
    setOpenModal(false)
  }

  return (
    <div className="folder_modal">
      <Dialog open={openModal}>
        <DialogTitle className="custom_modal">Update Title</DialogTitle>
        <img
          src="assets/images/Close-model.svg"
          alt=""
          srcSet=""
          className="close-icon-m"
          onClick={() => closeModal()}
        />
        <DialogContent className="dialog_content">
          <TextField
            className="folder_input"
            autoFocus
            margin="dense"
            id="name"
            required
            label={`Title`}
            type="name"
            fullWidth
            value={value}
            variant="standard"
            onChange={e => setValue(e.target.value)}
            InputLabelProps={{ className: 'text_label' }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => closeModal()}
            className={state?.loading ? '' : 'cancelBtn'}
            disabled={state?.loading ? true : false}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={state?.loading}
            className={state?.loading ? 'submitBtn text-color' : 'submitBtn'}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
