import React, { useState, useEffect, useMemo } from 'react'

import { EditorContent } from '@tiptap/react'
import { useDispatch, useSelector } from 'react-redux'
import { Api } from '../../helper/ApiList'
import { putMethod } from '../../helper/ApiMethods'
import { allSongAction } from '../../services/actions/song.action'
import { shiftUpdateSongElement } from '../../utils/commonFunctions'
import { useAlert } from 'react-alert'
import { toast } from '../Toast/Toast'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'

const SongEditor = ({ editor }) => {
  const { singleSong } = useSelector(state => state.singleSong)
  const [songHeading, setSongHeading] = useState('')
  const { token } = useSelector(state => state.auth)
  const { songs, title, folderId } = useSelector(state => state.songs)
  const { type } = useSelector(state => state.songs)

  const { leftToggle } = useSelector(state => state.leftToggle)
  const [isFocused, setIsFocused] = useState(false)

  const dispatch = useDispatch()

  const memo = useMemo(() => {
    setSongHeading(singleSong?.songHeading)
    setIsFocused(false)
  }, [singleSong])

  const handleUpdate = async () => {
    let body = {
      songHeading
    }

    const { error } = await putMethod(
      token,
      Api.updateSong + '/' + singleSong?._id,
      body
    )

    if (!error) {
      const arr = await shiftUpdateSongElement(
        songs,
        songHeading,
        singleSong,
        'heading'
      )
      dispatch(allSongAction(arr, title, folderId))
    }
    singleSong && isFocused && error && toast.error(error)
  }

  React.useEffect(() => {
    if (!isFocused) return
    const delayDebounceFn = setTimeout(() => {
      handleUpdate()
      // Send Axios request here
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, songHeading])

  return (
    <>
      <div
        className={`song-blocks-content ${
          type === LeftRightPaneFolderTypes.NOTES ? 'notes' : ''
        }`}
      >
        <EditorContent
          // height={200}
          disabled={true}
          editor={editor}
          className={
            leftToggle ? '' : 'editor_content full_width_editor droptarget'
          }
        />
      </div>
    </>
  )
}

export default SongEditor
